import router from './router'
import store from './store'
import { setToken, getToken } from '@/utils/auth'
import cookie from 'js-cookie'
import { getOpenid } from '@/utils/index'
const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
// no redirect whitelist 没有重定向白名单
const whiteList = ['/auth-redirect', '/scanCode']
// 获取物管跳转过来的access_token
const access_token = decodeURIComponent(getQueryVariable('access_token'))
if (access_token && access_token !== 'false') {
  setToken(access_token)
}
router.beforeEach(async(to, from, next) => {
  const token = getToken()
  if (token) {
    next()
    return
  }
  /* has no token*/
  if (whiteList.indexOf(to.path) !== -1) {
    const openid = getOpenid('openid') || cookie.get('openid')
    if (!openid || openid == null) {
      let redirect_uri = process.env.VUE_APP_REDIRECT_URI
      let backUrl = window.getQueryVariable('backUrl')
      if (backUrl) {
        backUrl = decodeURIComponent(backUrl)
        redirect_uri += '?backUrl=' + encodeURIComponent(backUrl)

        const backName = window.getQueryVariable('backName')
        if (backName) {
          redirect_uri += '&backName=' + backName
        }

        cookie.set('abc_back_url', backUrl + '#/' + backName)
      }

      redirect_uri = encodeURIComponent(redirect_uri)
      // window.alert('跳转：[' + redirect_uri + ']')
      window.location.href = process.env.VUE_APP_BASE_API + '/customer_h5/auth/authorize?redirect_uri=' + redirect_uri
      return
    }
    // in the free login whitelist, go directly
    // 在免费登录白名单，直接去
    next(`/scancode?redirect=${to.path}`)
    return
  }
  // determine whether the user has logged in
  // 确定用户是否已登录
  const hasToken = getToken()
  if (hasToken) {
    try {
      // next()
      if (to.meta.title) {
        document.title = to.meta.title
      }
      next()
    } catch (error) {
      // remove token and go to home page to re-home
      // 删除令牌，进入登录页面重新登录
      await store.dispatch('user/resetToken')
      next(`/home?redirect=${to.path}`)
    }
  } else if (to.path == '/phone') {
    const openid = getOpenid('openid') || cookie.get('openid')
    if (!openid || openid == null) {
      let redirect_uri = process.env.VUE_APP_REDIRECT_URI
      let backUrl = window.getQueryVariable('backUrl')
      if (backUrl) {
        backUrl = decodeURIComponent(backUrl)
        redirect_uri += '?backUrl=' + encodeURIComponent(backUrl)

        const backName = window.getQueryVariable('backName')
        if (backName) {
          redirect_uri += '&backName=' + backName
        }

        cookie.set('abc_back_url', backUrl + '#/' + backName)
      }

      // 对接G平台 start
      let auth_code = window.getQueryVariable('authcode')
      if (!auth_code) {
        auth_code = ''
      } else {
        const guide_abc = window.getQueryVariable('guide-abc') || ''
        redirect_uri += (redirect_uri.indexOf('?') > 0 ? '&' : '?') + 'auth_code=' + auth_code + '&guide_abc=' + guide_abc
      }
      // 对接G平台 end

      redirect_uri = encodeURIComponent(redirect_uri)
      // window.alert('跳转：[' + redirect_uri + ']')

      window.location.href = process.env.VUE_APP_BASE_API + '/customer_h5/auth/authorize?redirect_uri=' + redirect_uri + '&auth_code=' + auth_code
      return
    }
    next()
  } else {
    const res = false
    // try {
    //   res = await store.dispatch('user/login')
    // } catch (err) {
    //   res = err
    // }
    if (res && res.code == 10105) {
      next('/phone')
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  // 完成进度条
})
