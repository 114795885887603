import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import 'babel-polyfill'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters,
  state: {
    isLoading: false, // loading全局开关
    token: false, // 全局接收token
    saveDishItems: false, // 菜品
    total: false,
    sum: false,
    amount: false,
    canteen_name: false,
    customer_user_id: false,
    user_id: false,
    payobj: false,
    showRound: false,
    showCheck: false,
    timeLabel: false,
    applyData: {}
  },
  mutations: {
    changeisLoading(state, data) {
      state.isLoading = data
    },
    getopenid(state, data) {
      state.token = data
    }
  }
})

export default store
