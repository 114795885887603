export default [
  {
    path: '/abcIndex',
    name: 'AbcIndex',
    component: () => import('../../views/abc/index'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcAccount',
    name: 'AbcAccount',
    component: () => import('../../views/abc/account'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcAccountIn',
    name: 'AbcAccountIn',
    component: () => import('../../views/abc/account_in'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcAccountOut',
    name: 'AbcAccountOut',
    component: () => import('../../views/abc/account_out'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcAccountInout',
    name: 'AbcAccountInout',
    component: () => import('../../views/abc/account_inout_success'),
    meta: {
      title: '电子账户'
    }
  },
  // {
  //   path: '/abcSignup',
  //   name: 'AbcSignup',
  //   component: () => import('../../views/abc/signup'),
  //   meta: {
  //     title: '电子账户'
  //   }
  // },
  {
    path: '/abcSignupCard',
    name: 'AbcSignupCard',
    component: () => import('../../views/abc/signup_card'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcSignupIdcard',
    name: 'AbcSignupIdcard',
    component: () => import('../../views/abc/signup_idcard'),
    meta: {
      title: '身份验证'
    }
  },
  {
    path: '/abcSignupConfirm',
    name: 'AbcSignupConfirm',
    component: () => import('../../views/abc/signup_confirm'),
    meta: {
      title: '确认信息'
    }
  },
  {
    path: '/abcAreaProvince',
    name: 'AbcAreaProvince',
    component: () => import('../../views/abc/area_province'),
    meta: {
      title: '选择省'
    }
  },
  {
    path: '/abcAreaCity',
    name: 'AbcAreaCity',
    component: () => import('../../views/abc/area_city'),
    meta: {
      title: '选择城市'
    }
  },
  {
    path: '/abcAreaDistrict',
    name: 'AbcAreaDistrict',
    component: () => import('../../views/abc/area_district'),
    meta: {
      title: '选择区'
    }
  },
  {
    path: '/abcSignupAuthorizeCode',
    name: 'AbcSignupAuthorizeCode',
    component: () => import('../../views/abc/signup_authorize_code'),
    meta: {
      title: '输入授权验证码'
    }
  },
  {
    path: '/abcSignupSuccess',
    name: 'AbcSignupSuccess',
    component: () => import('../../views/abc/signup_success'),
    meta: {
      title: '开户成功'
    }
  },
  {
    path: '/abcSignupAuthorize',
    name: 'AbcSignupAuthorize',
    component: () => import('../../views/abc/signup_authorize'),
    meta: {
      title: '授权页面'
    }
  },
  {
    path: '/abcSignupConfirmCode',
    name: 'AbcSignupConfirmCode',
    component: () => import('../../views/abc/signup_confirm_code'),
    meta: {
      title: '输入确认信息验证码'
    }
  },
  {
    path: '/abcCardChange',
    name: 'AbcCardChange',
    component: () => import('../../views/abc/card_change'),
    meta: {
      title: '更换银行卡'
    }
  },
  {
    path: '/abcCardChangeConfirmCode',
    name: 'AbcCardChangeConfirmCode',
    component: () => import('../../views/abc/card_change_confirm_code'),
    meta: {
      title: '输入更换银行卡验证码'
    }
  },
  {
    path: '/abcSignupAccount',
    name: 'AbcSignupAccount',
    component: () => import('../../views/abc/signup_account'),
    meta: {
      title: '电子账户'
    }
  },
  {
    path: '/abcSignupReleaseCode',
    name: 'AbcSignupReleaseCode',
    component: () => import('../../views/abc/signup_release_code'),
    meta: {
      title: '输入确认信息验证码'
    }
  }
]

