<template>
  <div class="page">
    <div v-if="info" class="container">
      <div class="header">
        <div class="avatar">
          <img class="img" :src="info.headimg.middle">
          <van-uploader :after-read="afterRead">
            <div v-if="user_id != 3206" class="tip" style="color:#111111">更换</div>
          </van-uploader>
        </div>
      </div>
      <div class="content">
        <div class="item" @click="onShowDialog('nickname')">
          <div class="title">昵称</div>
          <div class="msg">{{ info.nickname }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="onShowDialog('gender')">
          <div class="title">性别</div>
          <div class="msg">{{ info.gender == 1 ? '男' : '女' }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="onMobile()">
          <div class="title">手机号码</div>
          <div class="msg">{{ info.mobile }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
      </div>
    </div>

    <div v-if="showDialog" class="cover" @click="onCatch()">
      <div v-if="dialogType == 'input' || dialogType == 'textarea'" class="dialog">
        <div class="dialog-header">
          <div class="dialog-header-left" @click="onDialogClose()">取消</div>
          <div class="dialog-header-center">{{ dialogTitle }}</div>
          <div class="dialog-header-right" @click="onDialogSure()">确定</div>
        </div>
        <div class="dialog-content">
          <div v-if="dialogType == 'input'" class="dialog-content-input">
            <input v-model="dialogValue" class="dialog-content-input-field" type="text" cursor-spacing="80px" @click="onDialogInput()">
          </div>
          <div v-if="dialogType == 'textarea'" class="dialog-content-textarea">
            <textarea v-model="dialogValue" class="dialog-content-textarea-field" type="text" @click="onDialogInput()" />
          </div>
        </div>
      </div>
      <van-picker
        v-else-if="dialogType=='gender'"
        :columns="dialogAge "
        :show-toolbar="true"
        :default-index="dialogValue"
        :title="dialogTitle"
        @cancel="onDialogClose()"
        @confirm="onDialogSure"
        @change="onDialogAgeChange()"
      />
    </div>
  </div>
</template>

<script>
import { getInfo, editUser } from '@/api/user'
import { upload } from '@/api/common'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      user_id: 0,
      info: false,
      // 头像
      headimg: '',
      name: '',
      nickname: '',
      gender: '',
      mobile: '',
      // 是否显示弹窗
      showDialog: false,
      // 弹窗类型：input输入
      dialogType: 'input',
      dialogTitle: '请输入',
      dialogPlaceholder: '请输入',
      dialogValue: '',
      dialogKey: '',
      dialogAge: ['男', '女'],
      save: false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      try {
        getInfo().then(res => {
          this.$store.state.isLoading = false
          this.info = res.data
        }).catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
          })
        })
      } catch (res) {
        console.log(res)
      }
    },
    // 头像
    afterRead(file) {
      const param = {}
      this.$store.state.isLoading = true
      upload(file.file, 'file.png', 10).then(res => {
        this.$store.state.isLoading = false
        const file_name = res.data.file_name
        param.headimg = file_name
        editUser(param).then(res => {
          this.loadData()
        }).catch((res) => {
          Dialog.alert({ message: res.msg })
        })
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    },
    // 显示弹窗
    onShowDialog(event) {
      const key = event
      this.showDialog = true
      this.dialogKey = key
      this.dialogValue = this[key]
      if (key == 'gender') { // 性别
        this.dialogTitle = '请选择性别'
        this.dialogType = 'gender'
      } else if (key == 'nickname') { // 昵称
        this.dialogTitle = '请输入昵称'
        this.dialogType = 'input'
      }
    },
    // 关闭弹窗
    onDialogClose() {
      this.showDialog = false
    },
    // 弹窗输入
    onDialogInput(event) {
      this.dialogValue = event
    // this.setData({dialogValue: event.detail.value});
    },
    onDialogAgeChange(event) {
    // this.data.dialogValue = event.detail.value;
    // this.setData({dialogValue: event.detail.value});
    },
    // 确定
    onDialogSure(e) {
      const key = this.dialogKey
      const param = {}
      if (this.dialogType == 'gender') {
        if (e == '男') {
          param[key] = '1'
        }
        if (e == '女') {
          param[key] = '2'
        }
      } else {
        param[key] = this.dialogValue
      }
      this.$store.state.isLoading = true
      editUser(param).then(res => {
        this.$store.state.isLoading = false
        this.loadData()
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })

      const save = {}
      if (this.dialogType == 'gender') {
        save[key] = event
      } else {
        save[key] = this.dialogValue
      }

      this.showDialog = false
      this.save = save
    },
    onCatch() {

    },
    onMobile() {
      this.$router.push({ name: 'Mobile' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./userinfo.less";
</style>
