<template>
  <div class="container">
    <van-field
      v-model="content"
      class="textarea"
      rows="2"
      type="textarea"
      maxlength="150"
      :autosize="{ minHeight: 260 }"
      placeholder="请输入留言..."
      show-word-limit
    />
    <!-- 附件 -->
    <div class="attach">
      <van-uploader v-model="fileList" multiple>
        <img class="icon" src="../../assets/feedback_upload_add.png">
      </van-uploader>
    </div>
    <div class="footer">
      <div class="btn" @click="submitForm()">提交反馈</div>
    </div>
  </div>
</template>

<script>
import { upload, handle } from '@/api/common'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      content: '',
      // 待上传文件
      fileList: [],
      uploadFinshList: {},
      files: []
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #ffffff;height:100%')
  },
  mounted() {
    // this.init()
  },
  methods: {
    // 提交
    submitForm() {
      if (this.content.length == 0) {
        this.$dialog.alert({ message: '请输入反馈内容' })
        return
      }
      this.$store.state.isLoading = true
      this.submitData()
    },
    // 提交数据
    async submitData() {
      const fileList = this.fileList
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          const filepath = fileList[i]
          if (!this.uploadFinshList[filepath]) {
            this.uploadFileData(filepath)
          }
        }
      } else {
        const param = {}
        param.content = this.content
        param.files = []
        try {
          const res = await handle(param)
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
            this.$router.push({ name: 'Home' })
          })
        } catch (res) {
          this.$store.state.isLoading = true
          return
        }
      }
    },
    async uploadFileData(filepath) {
      try {
        const res = await upload(filepath.file, filepath.name)
        this.uploadFinshList[filepath] = res.data.file_name
        this.submitData()
        const files = this.uploadFinshList[filepath]
        this.files.push(files)
        if (this.files && this.files.length == this.fileList.length) {
          const param = {}
          param.content = this.content
          param.files = this.files
          try {
            const res = await handle(param)
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg }).then(() => {
              this.$router.replace({ name: 'FeedbackList' })
            })
          } catch (res) {
            this.$store.state.isLoading = false
            return
          }
          this.$store.state.isLoading = false
        }
      } catch (res) {
        console.log(res)
        Dialog.alert({ message: '文件上传失败：' + res.msg })
        this.$store.state.isLoading = false
        return
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./feedback.less";
</style>
