export default {
/**
   * 获取跳转链接
   */
  jump(type = '', value = '') {
    let path = ''
    if (type == 'card_recharge') {
      path = '/topup'
    } else if (type == 'card_auto_recharge') {
      path = '/auto'
    } else if (type == 'card_bill') {
      path = '/bill'
    } else if (type == 'help_list') {
      path = '/guide'
    } else if (type == 'help_info') {
      path = '/guideDetail?id=' + value
    } else if (type == 'feedback') {
      path = '/feedback'
    } else if (type == 'news_info') {
      path = '/newsDetail?id=' + value
    } else if (type == 'reserve_dishes') {
      path = '/reserve'
    }
    return path
  },

  setStorage(key, value) {
    sessionStorage.setItem(key, value)
  },

  getStorage(key) {
    return sessionStorage.getItem(key)
  },
  // 农行掌银跳转缴费中心兼容ios
  JSBridgeReady(callback) {
    if (window.AlipayJSBridge) {
      callback	&& callback()
    } else {
      document.addEventListener('AlipayJSBridgeReady',	callback, false)
    }
  },
  goUrl(url) {
    const ua = window.navigator.userAgent.toLowerCase()
    const abcScene = ua.match(/Bankabc/i) == 'bankabc'
    const os = navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/)
    if (abcScene) {
      if (os) {
        const operatingSystem = os[0]
        if (operatingSystem === 'Android') {
          window.location.href = url
        } else {
          this.JSBridgeReady(function() {
            window.AlipayJSBridge.call('pushWindow', {
              url: url,
              param: {
                closeCurrentWindow: false
              }
            })
          })
        }
      } else {
        window.location.href = url
      }
    } else {
      window.location.href = url
    }
  }
}
