var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.info)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"content-header"},[(_vm.info.status == 10)?_c('img',{staticClass:"top-right",attrs:{"src":require("../../assets/header_top_wait.png")}}):_vm._e(),(_vm.info.status == 30)?_c('img',{staticClass:"top-right",attrs:{"src":require("../../assets/header_top_error.png")}}):_vm._e(),(_vm.info.status == 40)?_c('img',{staticClass:"top-right",attrs:{"src":require("../../assets/header_top.png")}}):_vm._e(),(_vm.info.status == 60)?_c('img',{staticClass:"top-right",attrs:{"src":require("../../assets/header_top_cancel.png")}}):_vm._e(),_c('div',{staticClass:"title"},[(_vm.info.status == 10)?_c('img',{staticClass:"title-img",attrs:{"src":require("../../assets/status_lid_wait.png")}}):_vm._e(),(_vm.info.status == 30)?_c('img',{staticClass:"title-img",attrs:{"src":require("../../assets/status_lid_error.png")}}):_vm._e(),(_vm.info.status == 40)?_c('img',{staticClass:"title-img",attrs:{"src":require("../../assets/status_lid.png")}}):_vm._e(),(_vm.info.status == 60)?_c('img',{staticClass:"title-img",attrs:{"src":require("../../assets/status_lid_cancel.png")}}):_vm._e(),_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.info.status_format))])]),_c('div',{class:[
          'header-card',
          _vm.info.status == 10
            ? 'header-card_wait'
            : _vm.info.status == 30
              ? 'header-card_error'
              : _vm.info.status == 60
                ? 'header-card_cancel'
                : ''
        ]},[(_vm.info.status == 10)?_c('img',{staticClass:"meal-top-img",attrs:{"src":require("../../assets/meal_title_top_wait.png")}}):_vm._e(),(_vm.info.status == 30)?_c('img',{staticClass:"meal-top-img",attrs:{"src":require("../../assets/meal_title_top_error.png")}}):_vm._e(),(_vm.info.status == 40)?_c('img',{staticClass:"meal-top-img",attrs:{"src":require("../../assets/meal_title_top.png")}}):_vm._e(),(_vm.info.status == 60)?_c('img',{staticClass:"meal-top-img",attrs:{"src":require("../../assets/meal_title_top_cancel.png")}}):_vm._e(),_c('div',{staticClass:"card-left"},[(_vm.info.status == 10)?_c('img',{staticClass:"meal-intake-img",attrs:{"src":require("../../assets/meal_intake_wait.png")}}):_vm._e(),(_vm.info.status == 30)?_c('img',{staticClass:"meal-intake-img",attrs:{"src":require("../../assets/meal_intake_error.png")}}):_vm._e(),(_vm.info.status == 40)?_c('img',{staticClass:"meal-intake-img",attrs:{"src":require("../../assets/meal_intake.png")}}):_vm._e(),(_vm.info.status == 60)?_c('img',{staticClass:"meal-intake-img",attrs:{"src":require("../../assets/meal_intake_cancel.png")}}):_vm._e(),_vm._m(0)]),_c('div',{staticClass:"card-line"}),_vm._m(1)])]),_c('div',{staticClass:"content-mid"},[_c('div',{staticClass:"title"},[(_vm.info.label == 10)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/breakfast_bg.png")}}):_vm._e(),(_vm.info.label == 20)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/launch_bg.png")}}):_vm._e(),(_vm.info.label == 30)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/dinner_bg.png")}}):_vm._e(),(_vm.info.label == 40)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/snack_bg.png")}}):_vm._e(),_c('div',{class:[
            'label',
            _vm.info.label == 10
              ? 'breakfast'
              : _vm.info.label == 20
                ? 'launch'
                : _vm.info.label == 30
                  ? 'dinner'
                  : _vm.info.label == 40
                    ? 'snack'
                    : ''
          ]},[_vm._v(" "+_vm._s(_vm.info.label_format)+" ")]),_c('div',{staticClass:"canteen"},[_vm._v(_vm._s(_vm.info.region && _vm.info.region.name))])]),_c('div',{staticClass:"content-detail"},[_c('div',{staticClass:"content-detail-item"},_vm._l((_vm.info.detail),function(item,index){return _c('div',{key:index,staticClass:"detail-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"img",attrs:{"src":item.dishes &&
                    item.dishes.cover_img &&
                    item.dishes.cover_img.file_url &&
                    item.dishes.cover_img.file_url.middle}}),_c('div',{staticClass:"item-left-title"},[_c('div',[_vm._v(_vm._s(item.dishes && item.dishes.name))]),_c('div',{staticClass:"unit"},[_vm._v(_vm._s(item.subtotal_weigh)+"克")])])]),_c('div',{staticClass:"item-right"},[_vm._v("¥"+_vm._s(item.subtotal_price_format))])])}),0),_c('div',{staticClass:"content-account"},[_c('div',{staticClass:"content-account-product"},[_c('div',[_vm._v("商品小计")]),_c('div',[_vm._v("¥"+_vm._s(_vm.info.total_price_format))])])]),_c('div',{staticClass:"content-detail-count"},[_c('span',[_vm._v("共 "+_vm._s(_vm.info.detail.length)+" 件商品，实付：")]),_c('div',{staticClass:"count-price"},[_c('span',[_vm._v("¥")]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.info.total_price_format))])])])]),_vm._m(2)]),_c('div',{staticClass:"content-bottom"},[_c('div',{staticClass:"content-bottom-detail"},[_c('div',{staticClass:"title"},[_vm._v("订单编号：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.info.order_no))])]),_vm._m(3),_c('div',{staticClass:"content-bottom-detail"},[_c('div',{staticClass:"title"},[_vm._v("下单时间：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.info.created_at_format))])]),_c('div',{staticClass:"content-bottom-detail"},[_c('div',{staticClass:"title"},[_vm._v("支付时间：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.info.pay_at_format))])]),(_vm.info.status == 60)?_c('div',{staticClass:"content-bottom-detail"},[_c('div',{staticClass:"title"},[_vm._v("取消时间：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.info.cancel_at_format))])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"meal-intake-num"},[_c('div',{staticClass:"num"},[_vm._v("- -")]),_c('div',[_vm._v("大卡")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-right"},[_c('div',{staticClass:"card-right-top"},[_c('div',{staticClass:"card-right-left"},[_c('div',[_vm._v("蛋白质")]),_c('div',{staticClass:"num-text dbz"},[_c('span',{staticClass:"num"},[_vm._v("- -")]),_c('span',{staticClass:"num-unit"},[_vm._v("克")])])]),_c('div',{staticClass:"card-line top-line"}),_c('div',{staticClass:"card-right-left zf"},[_c('div',[_vm._v("脂肪")]),_c('div',{staticClass:"num-text zf"},[_c('span',{staticClass:"num"},[_vm._v("- -")]),_c('span',{staticClass:"num-unit"},[_vm._v("克")])])])]),_c('div',{staticClass:"card-right-top bottom"},[_c('div',{staticClass:"card-right-left"},[_c('div',[_vm._v("碳水化合物")]),_c('div',{staticClass:"num-text ts"},[_c('span',{staticClass:"num"},[_vm._v("- -")]),_c('span',{staticClass:"num-unit"},[_vm._v("克")])])]),_c('div',{staticClass:"card-line"}),_c('div',{staticClass:"card-right-left"},[_c('div',[_vm._v("膳食纤维")]),_c('div',{staticClass:"num-text ss"},[_c('span',{staticClass:"num"},[_vm._v("- -")]),_c('span',{staticClass:"num-unit"},[_vm._v("克")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-mid-bg"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/mid_bottom.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-bottom-detail"},[_c('div',{staticClass:"title"},[_vm._v("支付方式：")]),_c('div',{staticClass:"content"},[_vm._v("饭卡支付")])])
}]

export { render, staticRenderFns }