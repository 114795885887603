import { request } from '@/api/request'

// 订餐--金额订单--列表
export function getAmount(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_amount/get_list',
    method: 'get',
    params: param
  })
}

// 订餐--金额订单--详情
export function amountInfo(data) {
  return request({
    url: '/customer_h5/dishes_order_amount/get_info',
    method: 'post',
    data: data
  })
}
// 订餐--金额订单--评价
export function amountEvaluate(data) {
  return request({
    url: '/customer_h5/dishes_order_amount/order_evaluation',
    method: 'post',
    data: data
  })
}
export function amountEvaluateInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_amount/get_order_evaluation',
    method: 'get',
    params: param
  })
}
export function amountGetOrderDishes(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_amount/get_order_dishes',
    method: 'get',
    params: param
  })
}
// 订餐--点餐订单--列表
export function getScene(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_purchase/get_list',
    method: 'get',
    params: param
  })
}

// 订餐--点餐订单--详情
export function sceneInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_purchase/get_info',
    method: 'get',
    params: param
  })
}
// 订餐--点餐订单--评价
export function sceneEvaluate(data) {
  return request({
    url: '/customer_h5/dishes_order_purchase/order_evaluation',
    method: 'post',
    data: data
  })
}
export function sceneEvaluateInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_purchase/get_order_evaluation',
    method: 'get',
    params: param
  })
}

// 订餐--食堂列表
export function getCanteenList(param = {}) {
  return request({
    url: '/customer_h5/canteen/get_list',
    method: 'get',
    params: param
  })
}

// 订餐--预定订单--列表
export function getHistory(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_reserve/get_list',
    method: 'get',
    params: param
  })
}

// 订餐--预定订单--详情
export function getHistoryInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_reserve/get_info',
    method: 'get',
    params: param
  })
}

// 订餐--预定取消
export function cancel(data) {
  return request({
    url: '/customer_h5/dishes_order_reserve/cancel',
    method: 'post',
    data: data
  })
}
// 订餐--预定订单--评价
export function historyEvaluate(data) {
  return request({
    url: '/customer_h5/dishes_order_reserve/order_evaluation',
    method: 'post',
    data: data
  })
}
export function historyEvaluateInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_reserve/get_order_evaluation',
    method: 'get',
    params: param
  })
}
// 菜单--列表
export function getMenuList(param = {}) {
  return request({
    url: '/customer_h5/dishes_menu/get_list',
    method: 'get',
    params: param
  })
}

// 菜品--列表
export function getDishesList(param = {}) {
  return request({
    url: '/customer_h5/dishes/get_list',
    method: 'get',
    params: param
  })
}

// 菜品分类--列表
export function getCategoryList(param = {}) {
  return request({
    url: '/customer_h5/dishes_category/get_list',
    method: 'get',
    params: param
  })
}

// 餐段--列表
export function getTimeList(param = {}) {
  return request({
    url: '/customer_h5/dishes_time_label/get_list',
    method: 'get',
    params: param
  })
}

// 预定订单--添加
export function addOrder(data) {
  return request({
    url: 'customer_h5/dishes_order_reserve/add',
    method: 'post',
    data: data
  })
}

// 获取围餐订单列表
export function getRound(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_round/get_list',
    method: 'get',
    params: param
  })
}

// 订餐--围餐订单取消
export function cancelRound(data) {
  return request({
    url: '/customer_h5/dishes_order_round/cancel',
    method: 'post',
    data: data
  })
}

// 围餐订单--添加
export function addRound(data) {
  return request({
    url: '/customer_h5/dishes_order_round/add',
    method: 'post',
    data: data
  })
}

// 获取围餐审批列表
export function getRoundCheck(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_round/check_list',
    method: 'get',
    params: param
  })
}

// 围餐审批
export function orderCheck(data) {
  return request({
    url: 'customer_h5/dishes_order_round/check',
    method: 'post',
    data: data
  })
}
// 订餐--围餐订单--详情
export function roundInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_round/get_info',
    method: 'get',
    params: param
  })
}
// 订餐--围餐订单--评价
export function roundEvaluate(data) {
  return request({
    url: '/customer_h5/dishes_order_round/order_evaluation',
    method: 'post',
    data: data
  })
}
// 订餐--围餐订单--评价详情
export function roundEvaluateInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_round/get_order_evaluation',
    method: 'get',
    params: param
  })
}
