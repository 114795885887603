// 数据中心相关接口
import { request } from './request'

// ================ 通用 START =================
// 文件上传
export function upload(file, filename = 'file.png') {
  const form = new FormData()
  // 通过append向form对象添加数据
  form.append('file', file, filename)
  return request({
    url: '/pay_wxapp/file/upload/v1',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: form
  })
}
// 行政区域 - 获取下级区域
export function getChildList(params) {
  return request({
    url: '/pay_wxapp/area/get_child_list/v1',
    method: 'get',
    params
  })
}
// 身份证识别
export function idCardOcr(data) {
  return request({
    url: '/pay_wxapp/tools/id_card_ocr/v1',
    method: 'post',
    data
  })
}
// 银行卡识别
export function bankCardOcr(data) {
  return request({
    url: '/pay_wxapp/tools/bank_card_ocr/v1',
    method: 'post',
    data
  })
}
// ================ 通用 END =================

// ================ 用户 START =================
// 登录
export function login(data) {
  return request({
    url: '/pay_wxapp/passport/login/v1',
    method: 'post',
    data
  })
}
// ================ 用户 END =================

// ================ 电子账户 START =================
// 申请变更银行卡
export function changeCardApply(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/change_card_apply/v1',
    method: 'post',
    data
  })
}
// 申请开通电子账户
export function apply(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/apply/v1',
    method: 'post',
    data
  })
}
// 确认变更银行卡
export function changeCardConfirm(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/change_card_confirm/v1',
    method: 'post',
    data
  })
}
// 确认开通电子账户
export function confirm(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/confirm/v1',
    method: 'post',
    data
  })
}
// 获取电子账户信息
export function getInfo(params) {
  return request({
    url: '/pay_wxapp/abc_e_account/get_info/v1',
    method: 'get',
    params
  })
}
export function getInfoAllow(params) {
  return request({
    url: '/pay_wxapp/abc_e_account/get_info_allow/v1',
    method: 'get',
    params
  })
}
// 转入
export function transferIn(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/transfer_in/v1',
    method: 'post',
    data
  })
}
// 转出
export function transferOut(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/transfer_out/v1',
    method: 'post',
    data
  })
}
// 重发验证码
export function verifyCode(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/verify_code/v1',
    method: 'post',
    data
  })
}
// 取消授权
export function cancelContract(data) {
  return request({
    url: '/pay_wxapp/abc_trust_recharge/cancel_contract/v1',
    // url: '/pay_wxapp/abc_trust_pay/cancel_contract/v1',
    method: 'post',
    data
  })
}
// 授权确认
export function signContractConfirm(data) {
  return request({
    url: '/pay_wxapp/abc_trust_pay/sign_contract_confirm/v1',
    method: 'post',
    data
  })
}
// 授权申请
export function signContractApply(data) {
  return request({
    url: '/pay_wxapp/abc_trust_pay/sign_contract_apply/v1',
    method: 'post',
    data
  })
}
// 授权重发验证码
export function verifyCodeTrust(data) {
  return request({
    url: '/pay_wxapp/abc_trust_pay/verify_code/v1',
    method: 'post',
    data
  })
}
// 申请注销
export function releaseApply(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/release_apply/v1',
    method: 'post',
    data
  })
}
// 注销验证码
export function releaseConfirm(data) {
  return request({
    url: '/pay_wxapp/abc_e_account/release_confirm/v1',
    method: 'post',
    data
  })
}
// ================ 电子账户 END =================

// ================ 收银台绑定支付 START =================
// 提现-转出
export function trustBindingWithdraw(data) {
  return request({
    url: '/pay_wxapp/abc_trust_binding/withdraw/v1',
    method: 'post',
    data
  })
}
// 确认支付-转入
export function trustBindingPayment(params) {
  return request({
    url: '/pay_wxapp/abc_trust_binding/payment/v1',
    method: 'get',
    params
  })
}
// 获取充值信息
export function trustBindingGetPayInfo(params) {
  return request({
    url: '/pay_wxapp/abc_trust_binding/get_pay_info/v1',
    method: 'get',
    params
  })
}
// ================ 收银台绑定支付 END =================

// ================ 收银台充值支付 START =================
// 确认支付
export function trustRechargePayment(params) {
  return request({
    url: '/pay_wxapp/abc_trust_recharge/payment/v1',
    method: 'get',
    params
  })
}
// 获取支付信息
export function trustRechargeGetPayInfo(params) {
  return request({
    url: '/pay_wxapp/abc_trust_recharge/get_pay_info/v1',
    method: 'get',
    params
  })
}
// 转入
export function trustRechargeTransferIn(data) {
  return request({
    url: '/pay_wxapp/abc_trust_recharge/transfer_in/v1',
    method: 'post',
    data
  })
}
// 转出
export function trustRechargeTransferOut(data) {
  return request({
    url: '/pay_wxapp/abc_trust_recharge/transfer_out/v1',
    method: 'post',
    data
  })
}
// ================ 收银台充值支付 END =================

// ================ 缴费中心绑定支付 START =================
// 获取充值信息
export function bridgeBindingGetPayInfo(params) {
  return request({
    url: '/pay_wxapp/abc_bridge_binding/get_pay_info/v1',
    method: 'get',
    params
  })
}
// 确认支付-转入
export function bridgeBindingPayment(params) {
  return request({
    url: '/pay_wxapp/abc_bridge_binding/payment/v1',
    method: 'get',
    params
  })
}
// 提现-转出
export function bridgeBindingWithdraw(data) {
  return request({
    url: '/pay_wxapp/abc_bridge_binding/withdraw/v1',
    method: 'post',
    data
  })
}
// ================ 缴费中心绑定支付 END =================

// ================ 缴费中心充值支付 START =================
// 获取充值信息
export function bridgeRechargeGetPayInfo(params) {
  return request({
    url: '/pay_wxapp/abc_bridge_recharge/get_pay_info/v1',
    method: 'get',
    params
  })
}
// 确认支付
export function bridgeRechargePayment(params) {
  return request({
    url: '/pay_wxapp/abc_bridge_recharge/payment/v1',
    method: 'get',
    params
  })
}
// ================ 缴费中心充值支付 END =================

// ================ 收银台快E付 START =================
// 获取充值信息
export function abcTrustMobileLuaPayGetPayInfo(params) {
  return request({
    url: '/pay_wxapp/abc_trust_mobile_lua_pay/get_pay_info/v1',
    method: 'get',
    params
  })
}
// 确认支付
export function abcTrustMobileLuaPayPayment(data) {
  return request({
    url: '/pay_wxapp/abc_trust_mobile_lua_pay/payment/v1',
    method: 'post',
    data
  })
}
// ================ 收银台快E付 END =================
