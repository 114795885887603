<template>
  <div class="container">
    <div class="content">
      <img src="./images/success.png" class="icon">
      <div class="title">{{ isOut ? "转出" : "转入" }}：{{ amount }}元！</div>
      <div class="title">
        {{ isOut ? "转出" : "转入" }}提交成功，具体到账时间以银行为准
      </div>
    </div>
    <div class="footer">
      <div class="btn" @click="onFinsh">完成</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbcAccountInout',
  data() {
    return {
      amount: '',
      isOut: true
    }
  },
  created() {
    this.amount = this.$route.query.amount
    if (this.$route.query.in) {
      this.isOut = false
    }
  },
  methods: {
    onFinsh() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
@import "./account_inout_success.less";
</style>
