<template>
  <div class="container">
    <div v-if="error_code == 20000" class="signup">
      <div class="header">
        <img src="./images/card.png" class="logo">
      </div>
      <div class="content">
        <div class="msg">·开通电子账户</div>
        <div class="msg">·一键快捷充值，秒到账</div>
      </div>
      <div class="footer">
        <div
          v-if="error_code == 20000"
          class="btn"
          @click="handleToOpen"
        >
          前往开通
        </div>
        <div
          v-if="error_code == 20001"
          class="btn"
          @click="
            () => {
              this.$router.push({ name: 'AbcSignupAuthorize' });
            }
          "
        >
          前往开通
        </div>
      </div>
    </div>
    <div v-if="error_code == 20001 && einfo" class="signup_account">
      <div class="header">
        <img class="icon" src="./images/success.png">
        <div class="title" style="font-size: 20px">开户成功</div>
        <div class="title" style="margin-top: 20px;color: #999">电子账户</div>
        <div class="val e_account" style="font-size: 15px; color: #666;">
          {{ einfo.e_account }}
        </div>
      </div>
      <div class="content">
        <div class="label">绑定银行卡：</div>
        <div class="item">
          <div style="flex: 1;display: flex;flex-direction: column;">
            <div class="title">
              <span class="bank_name">{{ einfo.card_info && einfo.card_info.bank_name }}</span>
              <span v-if="einfo.is_changing" class="changing">变更中</span>
            </div>
            <div class="val bank_card_no">{{ einfo.card_no_format }}</div>
          </div>
          <div
            v-if="!einfo.is_changing"
            class="btn card_change"
            @click="onChange"
          >
            更换
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn onAuthorize" @click="onAuthorize">授权扣费</div>
        <div class="btn onCancel" @click="onRelease">注销</div>
      </div>
    </div>
    <div v-else-if="info" class="main">
      <div class="header">
        <div class="top">
          <div class="name">{{ info.bank_name }}</div>
          <div class="account">{{ info.e_account }}</div>
        </div>
        <div class="balance">￥{{ info.balance }}</div>
      </div>
      <div class="bank">
        <div class="bank-label">绑定银行卡</div>
        <div class="bank-content">
          <div class="bank-info">
            <div class="name">
              {{ info.card_info && info.card_info.bank_name }}
              <div v-if="info.is_changing == 1" class="changing">变更中</div>
            </div>
            <div class="balance">{{ info.card_no_format }}</div>
          </div>
          <div
            v-if="info.is_changing == 0"
            class="btn"
            @click="
              () => {
                this.$router.push({ name: 'AbcCardChange' });
              }
            "
          >
            更换
          </div>
        </div>
      </div>
      <div class="content">
        <div class="btn" @click="onAccountIn">
          {{ info.is_changing == 1 ? "银行卡变更中不允许操作" : "转入" }}
        </div>
        <div class="btn out" @click="onAccountOut">
          {{ info.is_changing == 1 ? "银行卡变更中不允许操作" : "转出" }}
        </div>
        <div class="btn out" style="color:red;" @click="onRelease">注销</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getInfoAllow, getInfo, releaseApply } from './api/api'
export default {
  name: 'AbcAccount',
  data() {
    return {
      loading: false, // 是否加载中
      error_code: false, // 错误码
      info: false, // 缴款信息
      einfo: false, // 电子账户信息
      pay_way: '' // 选择的支付方式
    }
  },
  created() {
    const config = this.$route.query
    if (config.appid) {
      const param = {}
      param.appid = config.appid
      param.merchant_id = config.merchant_id
      param.setting_number = config.setting_number
      param.prepay_id = config.prepay_id
      this.$store.dispatch('abc/setParam', param)
    }
    this.$store.state.isLoading = false

    this.login()
  },
  methods: {
    async login() {
      const param = this.$store.getters.abc_param
      this.$store.state.isLoading = true
      try {
        const res = await login(param)
        this.$store.dispatch('abc/setPayWay', res.data.pay_way)
        this.$store.dispatch('abc/setToken', res.data.access_token)
        this.pay_way = res.data.pay_way
        this.loadInfo()
      } catch (error) {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          this.$router.go(-1)
        })
      }
    },

    async loadInfo() {
      try {
        const res = await getInfoAllow()
        this.$store.state.isLoading = false
        this.error_code = 0
        this.info = res.data
      } catch (error) {
        this.$store.state.isLoading = false
        if (error.code == 20000) { // 未开通农行电子账户
          this.error_code = error.code
          this.info = false
          this.einfo = false
          return
        } else if (error.code == 20001) {
        // 加载电子账户信息
          this.$store.state.isLoading = true
          getInfo().then((res) => {
            this.$store.state.isLoading = false
            this.error_code = 20001
            this.einfo = res.data
            this.info = false
            this.verifyBank()
          }).catch((error) => {
            this.$store.state.isLoading = false
            this.$dialog.alert({
              message: error.msg
            }).then(() => {
              this.$router.go(-1)
            })
          })
          return
        }
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          this.$router.go(-1)
        })
      }
      this.verifyBank()
    },

    verifyBank() {
      if (!this.info && !this.einfo) return
      const bank = this.info || this.einfo
      const bank_name = bank.card_info && bank.card_info.bank_name || ''
      const isCMB = bank_name.indexOf('招商银行') > -1
      if (isCMB) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '目前招商银行卡暂不支持电子账户充值业务，为保障您的正常使用请您进行注销后更换其他I类卡重新进行开户和签约，造成不便请见谅。'
        })
      }
    },

    handleToOpen() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '目前电子账户暂不支持招商银行卡开通，请知悉，谢谢'
      }).then(() => {
        this.$router.push({ name: 'AbcSignupCard' })
      }).catch(() => {})
    },

    onChange() {
      this.$router.push({ name: 'AbcCardChange' })
    },

    onAuthorize() {
      this.$router.push({ name: 'AbcSignupAuthorize' })
    },

    onRelease() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定注销电子账户？'
      }).then(() => {
        this.$store.state.isLoading = true
        releaseApply().then((res) => {
          this.$store.state.isLoading = false
          this.$router.push({ name: 'AbcSignupReleaseCode', query: { token: res.data.token }})
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      })
    },

    onAccountIn() {
      if (this.info.is_changing == 1) {
        return
      }
      this.$router.push({ name: 'AbcAccountIn' })
    },

    onAccountOut() {
      if (this.info.is_changing == 1) {
        return
      }
      this.$router.push({ name: 'AbcAccountOut', query: { pay_way: this.pay_way }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./account.less";
</style>
