<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <div
          v-for="(sub_item, sub_index) in listData"
          :key="sub_index"
          class="content-mid"
        >
          <!-- <div class="content-mid-line" /> -->
          <div class="content-mid-top">
            <div class="content-mid-left">
              <img class="content-mid-img" :src="sub_item.dishes.cover_img?sub_item.dishes.cover_img.file_url?sub_item.dishes.cover_img.file_url.middle:'':''">
              <div class="dishes-detail">
                <div class="dishes-detail-name">{{ sub_item.dishes.name }}</div>
                <div class="dishes-detail-ad ellipsis">
                  {{ sub_item.dishes.ad_words }}
                </div>
                <!-- <div class="dishes-detail-quantity">
                  x{{ sub_item.quantity }}
                </div> -->
              </div>
            </div>
            <!-- <div class="dishes-money">
              ¥{{ sub_item.subtotal_price_format }}
            </div> -->
          </div>
          <div class="content-mid-bottom">
            <div class="star">
              <div class="star-left-text">我的评价</div>
              <div v-for="(item, index) in starsBox" :key="'a' + index">
                <img
                  v-if="sub_item.starNum[0] >= item"
                  class="img"
                  src="../../assets/star_full.png"
                >
                <img v-else class="img" src="../../assets/star.png">
              </div>
              <!-- <div class="star-right-text">{{ sub_item.starText[0] }}</div> -->
            </div>
            <div v-if="sub_item.comment!==''" class="evaluate-text">
              <div class="left-img">
                <img class="img" src="../../assets/evaluate_left_dot.png">
              </div>
              <div class="right-img">
                <img class="img" src="../../assets/evaluate_right_dot.png">
              </div>
              <div class="text">
                {{ sub_item.comment }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { historyEvaluateInfo, sceneEvaluateInfo, amountEvaluateInfo, roundEvaluateInfo } from '@/api/meal'
import { Dialog } from 'vant'
export default {
  name: 'EvaluateDetail',
  components: {},
  data() {
    return {
      listData: [],
      // 加载中
      loading: false,
      orderType: 1, // 1预定订单，2点单订单，3金额订单，4团餐订单
      id: 0,
      starsBox: [1, 2, 3, 4, 5]
    }
  },
  created() {
    this.orderType = this.$route.query.orderType
    if (this.orderType == 1) {
      this.loadHistoryData()
    } else if (this.orderType == 2) {
      this.loadSceneData()
    } else if (this.orderType == 3) {
      this.loadAmountData()
    } else if (this.orderType == 4) {
      this.loadRoundData()
    }
  },
  methods: {
    loadHistoryData() {
      const param = {}
      param.order_id = this.$route.query.id
      this.$store.state.isLoading = true
      historyEvaluateInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              v.starNum = [v.score]
            })
          }
          this.listData = res.data
        }).catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    loadSceneData() {
      const param = {}
      param.order_id = this.$route.query.id
      this.$store.state.isLoading = true
      sceneEvaluateInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              v.starNum = [v.score]
            })
          }
          this.listData = res.data
        }).catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    loadAmountData() {
      const param = {}
      param.order_id = this.$route.query.id
      param.query_month = this.$route.query.query_month
      this.$store.state.isLoading = true
      amountEvaluateInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              v.starNum = [v.score]
            })
          }
          this.listData = res.data
        }).catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    loadRoundData() {
      const param = {}
      param.order_id = this.$route.query.id
      this.$store.state.isLoading = true
      roundEvaluateInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              v.starNum = [v.score]
            })
          }
          this.listData = res.data
        }).catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    changeDishes() {
      this.$router.push({
        name: 'SelectDishes',
        query: { query_month: this.$route.query.query_month }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./evaluate-detail.less";
</style>
