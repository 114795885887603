<template>
  <div class="qrcode">
    <div class="card">
      <div class="qrcode-title">
        <div class="header">二维码付款</div>
      </div>
      <img class="baseImage" :src="qrcode" width="230" height="230">
      <div class="tip">请将二维码对准扫码机</div>
    </div>
  </div>
</template>

<script>
import { getqrcode, getqrStatus } from '@/api/user'
import { Dialog } from 'vant'
// import { Dialog } from 'vant'

export default {
  data() {
    return {
      info: false,
      qrcode: '',
      unique_id: ''
    }
  },
  mounted() {
    this.loadQrcode()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    // 获取绑定用户列表
    loadQrcode() {
      // this.$store.state.isLoading = true
      getqrcode().then(res => {
        this.qrcode = res.data.base64_jpg
        this.unique_id = res.data.unique_id
        const param = {}
        param.unique_id = this.unique_id
        this.timer = setInterval(() => {
          getqrStatus(param).then(res => {
            this.$store.state.isLoading = false
            if (res.data.status != 0 && res.data.status != 90) {
              clearInterval(this.timer)
              this.loadQrcode()
            } else if (res.data.status == 90) {
              clearInterval(this.timer)
              // 跳成功
              this.$router.push({ name: 'Qrcode_success', query: { result: res.data }})
            }
          }).catch((res) => {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg })
            // this.$tool.toast(error.msg);
            clearInterval(this.timer)
            this.loadQrcode()
          })
        }, 3000)
      }).catch(res => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
          this.$router.go(-1)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./qrcode.less";
</style>
