import { render, staticRenderFns } from "./signup_authorize_code.vue?vue&type=template&id=2b88f5fb&scoped=true&"
import script from "./signup_authorize_code.vue?vue&type=script&lang=js&"
export * from "./signup_authorize_code.vue?vue&type=script&lang=js&"
import style0 from "./signup_authorize_code.vue?vue&type=style&index=0&id=2b88f5fb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b88f5fb",
  null
  
)

export default component.exports