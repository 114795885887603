import { request } from '@/api/request'
/**
 * 获取帮助分类
 */
export function getCategory() {
  return request({
    url: '/customer_h5/help_category/get_all',
    method: 'get'
  })
}

// 帮助列表
export function getList(param = {}) {
  return request({
    url: '/customer_h5/help/get_list',
    method: 'get',
    params: param
  })
}

// 帮助详情
export function getInfo(param = {}) {
  return request({
    url: '/customer_h5/help/get_info',
    method: 'get',
    params: param
  })
}
