<template>
  <div>
    <div @click="showPicker = true">
      {{ select_name?select_name:'请选择' }}
    </div>
    <van-popup v-model="showPicker" class="popup-van" position="right" :style="{ width:'100%', height: '100%' }" @close="onClose">
      <div class="select-container">
        <div class="content-container">
          <div class="content">
            <div v-if="multiple" class="select-title">
              <div>
                已选择
                <span
                  class="select-people"
                >{{ selectItems.length }}/{{ pickerList.length }}</span>
              </div>
              <div class="all-select" @click="selectAll">
                <span>全选</span>
                <van-icon v-if="allChecked" class="img" name="checked" size="14" color="#ffcd17" />
                <div v-else class="all-check-btn" />
              </div>
            </div>

            <div v-if="multiple" class="select-teacher">
              <div v-for="(item, i) in selectItems" :key="i" class="select-item">
                <div>{{ item.name }}</div>
                <van-icon class="img" name="clear" size="17" @click="deleteSelector(item, i)" />
              </div>
            </div>
            <div class="select-list">
              <div
                v-for="(item, ind) in pickerList"
                :key="ind"
                class="item"
                @click="selectTeacher(item, ind)"
              >
                <div class="label-check">
                  <!-- <img class="img" :src="item.headimg && item.headimg.middle"> -->
                  <div class="name-list">
                    <div class="name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div>
                  <van-icon
                    v-if="item.checked"
                    class="checkbox-img"
                    name="checked"
                    size="20"
                    color="#ffcd17"
                  />
                  <div v-if="!item.checked" class="check-btn" />
                </div>
              </div>
            </div>
          </div>
        <!-- list底部 -->
        </div>
        <div class="bottom-btn">
          <div class="btn-cancle" @click="onClose">取消</div>
          <div class="btn-confirm" @click="onClose">确定</div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
export default {
  name: 'SelectTeacher',
  model: {
    prop: 'select',
    event: 'change'
  },
  props: {
    // 选择项
    select: {
      type: [String, Boolean, Object, Array],
      default: ''
    },
    // 请求列表
    httpRequest: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  data() {
    return {
      showPicker: false,
      name: '',
      pickerList: [],
      selectItems: [],
      allChecked: false,
      multiple: true,
      index: 0,
      select_name: ''
    }
  },

  watch: {
    select(val) {
      this.selectItems = val
    },
    selectItems() {
      this.$emit('change', this.selectItems)
      if (this.selectItems.length > 0) {
        this.select_name = (this.selectItems.map((v) => v.name)).join('、')
      } else {
        this.select_name = ''
      }
      if (this.selectItems.length == this.pickerList.length && this.selectItems.length != 0) {
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    }
  },
  mounted() {
    this.selectItems = this.select
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      this.httpRequest()
        .then(res => {
          this.$store.state.isLoading = false
          if (!res.data) return
          this.pickerList = res.data
          this.select.forEach(item => {
            this.pickerList.forEach(val => {
              // item.checked = true
              if (val.id == item.id) {
                val.checked = true
              }
            })
          })
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    },
    // 删除已经选择的教师
    deleteSelector(item) {
      const index = this.selectItems.findIndex((obj) => obj.id == item.id)
      this.selectItems.splice(index, 1)
      this.pickerList.forEach(val => {
        if (val.id == item.id) {
          val.checked = false
        }
      })
    },
    selectTeacher(item, index) {
      item.checked = !item.checked
      if (item.checked) {
        if (!this.selectItems.find((obj) => obj.id == item.id)) {
          this.selectItems.push(item)
        }
      } else {
        const index = this.selectItems.findIndex((obj) => obj.id == item.id)
        this.selectItems.splice(index, 1)
      }
      console.log(this.pickerList.map((v) => v.checked))
      // this.$forceUpdate()
    },
    // 判断全选
    isAllChecked(arr) {
      const selFlag = arr.some((v) => !v.checked)
      if (selFlag) {
        this.allChecked = false
      } else {
        this.allChecked = true
      }
    },
    // 全选
    selectAll() {
      this.allChecked = !this.allChecked
      this.selectItems = []
      if (this.allChecked) {
        this.pickerList.forEach(val => {
          val.checked = true
          this.selectItems.push(val)
        })
      } else {
        this.pickerList.forEach(val => {
          val.checked = false
        })
      }
    },
    onClose() {
      this.showPicker = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "./select-canteen.less";
</style>
