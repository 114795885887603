import { request } from '@/api/request'

// 广告-列表
export function getAd(param = {}) {
  return request({
    url: '/customer_h5/ad/get_all',
    method: 'get',
    params: param
  })
}
