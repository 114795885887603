<template>
  <div>
    <div class="title">
      <div>尊敬的用户</div>
      <div>欢迎使用智慧食堂</div>
    </div>

    <img class="nav" src="../../assets/guide_abc_nav.png" alt="">

    <div class="tip">请点击下方按钮跳转农行掌银APP</div>

    <img class="bg" src="../../assets/guide_abc_bg.png" alt="">

    <div class="btn" @click="onGoExternal">
      <img src="../../assets/guide_abc_btn.png" alt="">
      <span>点击跳转</span>
    </div>
  </div>
</template>

<script>
import { getSettings } from '@/api/common'

export default {
  name: 'GuideABC',
  data() {
    return {
      jump_url: ''
    }
  },
  created() {
    this.loadSettings()
  },
  methods: {
    async loadSettings() {
      try {
        const res = await getSettings()
        this.jump_url = res.data?.gree_jump_abcapp
      } catch (error) {
        this.$dialog.alert({ message: error.msg || error })
      }
    },

    onGoExternal() {
      const that = this
      window.qing.call('localFunction', {
        'name': 'openExtBrowser',
        'param': {
          'url': that.jump_url
        },
        'success': function(result) {
          // alert('结果：' + JSON.stringify(result))
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  width: 240px;
  height: 82px;
  font-size: 30px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #333;
  line-height: 40px;
  margin: 50px 0 6px 28px;
}

.nav {
  width: 193px;
  height: 7px;
  margin-left: 28px;
}

.tip {
  width: 209px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #999;
  line-height: 21px;
  margin: 10px 0 0 28px;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.btn {
  position: absolute;
  top: 527px;
  left: 50%;
  transform: translateX(-50%);
  width: 252px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFF;
  box-shadow: 0 5px 10px 0 #BCFFE7;
  border-radius: 24px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
</style>
