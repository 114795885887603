/* eslint-disable */
/**
 * 封装request请求，在调用api接口时使用
 */

import axios from 'axios'
import { Dialog, Toast } from 'vant'
import router from '@/router'
import store from '@/store'
import { getToken } from './auth'
import { generateSign, generalBody } from './sign'
import cookie from 'js-cookie'

const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_CENTRE_BASE_API,
  // 超时
  timeout: 60000,
  withCredentials: false,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})
// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      // 让每个请求携带自定义token
      config.headers['Authorization'] = getToken()
    }
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.sign = generateSign(config.params)
    } else if (config.method == 'post') {
      //排除上传接口
      if (config.url != '/pay_wxapp/file/upload/v1') {
        if (!config.data) {
          config.data = {}
        }
        config.data.sign = generateSign(config.data)
        const data = config.data
        const params = generalBody(data, false, true)
        let form = new FormData()
        if (params) {
          for (const item of params) {
            form.append(item.key, item.val)
          }
        }
        config.data = form
      }
    }
    return config
  },
  error => {
    let data = {};
    data.code = -1;
    data.msg = '请求错误'
    Promise.reject(data)
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
  const code = res.data.code
  if (code == 10107) {
    Dialog.alert({message:res.data.msg}).then(() =>{
      if (window.navigator.userAgent.indexOf('Bankabc/Portal') > -1) {
        let backUrl = getQueryVariable('backUrl')
        if (backUrl) {
          backUrl = decodeURIComponent(backUrl)
          let backName = getQueryVariable('backName')
          let url = backUrl
          if (backName) {
            backName = decodeURIComponent(backName)
            url += '#/' + backName
          }

          window.location.href = url
        } else {
          const backUrl = cookie.get('abc_back_url')
          // console.log(backUrl)
          if (backUrl) {
            window.location.href = backUrl
          } else {
            window.history.go(-1)
          }
        }
      } else {
        window.history.go(-1);
      }
    })
  } else if (code == 10100 || code == 10101 || code == 10102 || code == 10103) {
    Toast.clear();
    Dialog.alert({message:res.data.msg}).then(() =>{
      if (code == 10100) {
        window.location.href = process.env.VUE_APP_BASE_API + '/h5_tenant/Auth/authorize'
        return;
      } else {
        store.dispatch('abc/resetToken').then(() => {
          router.push({ name: 'AbcIndex'})
        })
      }
    })
    // return res
  } else {
    //loading隐藏
    return res
  }
},
error => {
  let msg = '';
  if (error.message) {
    msg = error.message
  } else {
    msg = error.toString()
  }
  this.$dialog.alert({message:msg})
  throw error;
}
)

export default service
