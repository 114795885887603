<template>
  <div class="page">
    <!--称重菜品-->
    <!-- 顶部搜索栏 -->
    <div class="header">
      <div class="tab-bar">
        <div
          v-for="(item, index) in labelList"
          :key="index"
          :class="['item', item.id == listParam.label_id ? 'active' : '']"
          @click="onLabelClick(item)"
        >
          <div>{{ item.name }}</div>
          <div :class="[item.id == listParam.label_id ? 'active' : '']" />
        </div>
      </div>
    </div>

    <div class="stance" />
    <div class="reserve">
      <!-- 左边餐品分类栏 -->
      <div class="tabs">
        <div
          v-for="(item,index) in categoryList"
          :key="index"
          :class="['tab',{ 'active': item.id == listParam.category_id}]"
          @click="onCategoryClick(item)"
        >
          <span class="week">
            {{ item.name }}
          </span>
        </div>
        <div style="height: 78px;" />
      </div>

      <div class="content">
        <BaseList ref="list" :get-list-request="getListRequest" :immediate-check="false">
          <template v-slot:default="listData">
            <div
              v-for="(item,index) in listData.listData"
              :key="index"
              class="card"
              @click="goDetail(item)"
            >
              <div :class="['card-dishes',{'grey':!item.is_on_sale}]">
                <div v-if="item.cover_img" :class="['dishes-img',{'sold':!item.is_on_sale}]">
                  <img
                    v-if="item.cover_img.file_url"
                    class="img"
                    :src="item.cover_img.file_url.middle"
                    mode=""
                  >
                </div>
                <div class="dishes">
                  <div class="card-title">{{ item.name }}
                    <van-icon v-if="!setting" class="stock" name="arrow" />
                    <div v-else :class="['circle',{radio:selectItme.id == item.id }]" />
                  </div>
                  <div class="card-sales">
                    <div v-for="(tab,i) in item.labels" :key="i" class="tab-style" :style="'color:'+tab.color+';border-color:'+tab.border_color">
                      {{ tab.name }}
                    </div>
                  </div>
                  <div class="card-price">
                    <span class="symbol">￥</span>
                    <span class="price">{{ item.price_yuan }}</span>
                    <span class="unit">/
                      {{ item.weigh }}克
                      {{ item.price_type == 20?'/份':'' }}
                    </span>
                  </div>
                  <div class="price-box">
                    <div class="price" v-html="item.price_type_html_format" />
                  </div>
                </div>
              </div>
              <div v-if="!setting" class="card-footbtn">
                <div v-if="!item.is_on_sale" class="btn btn-up" @click.stop="onMealUp(item)">上架</div>
                <div v-else class="btn btn-dw" @click.stop="onMealDown(item)">下架</div>
              </div>
            </div>
          </template>
        </BaseList>
      </div>

      <div v-if="!setting" class="bottom-bar">
        <div class="total">
          <div class="to-account" @click="onAdd()">+ 新增菜品</div>
        </div>
        <div class="dot hasSelected">
          <div class="to-account" @click="setting = true">删除菜品</div>
        </div>
      </div>
      <div v-else class="bottom-bar">
        <div class="cancel" @click="onCancel()">取消</div>
        <div class="affirm" @click="onDel()">确认删除</div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getDishesList,
  getTimeLabel,
  getClassification,
  cancel,
  dishseOnSale,
  dishesOffSale
} from '@/api/manage'
import { Dialog } from 'vant'
import BaseList from '@/components/BaseList'

export default {
  name: 'DishesManage',
  components: { BaseList },
  data() {
    return {
      listParam: {
        label_id: '',
        category_id: ''
      },
      // 设置状态
      setting: false,
      selectList: [],
      categoryList: [{ name: '全部', id: '' }],
      labelList: [{ name: '全部', id: '' }],
      labelIndex: 0,
      categoryIndex: 0,
      // 选中
      selectItme: {},
      // 菜品分类
      typeList: ['全部']
    }
  },

  created() {
  },

  mounted() {
    this.loadData()
    this.$refs.list.onRefresh()
  },
  methods: {
    getListRequest(param) {
      param.label_id = this.listParam.label_id
      param.category_id = this.listParam.category_id
      return getDishesList(param)
    },
    loadData() {
      getClassification()
        .then((res) => {
          this.categoryList = [{ name: '全部', id: '' }].concat(res.data)
        })
        .catch((res) => {
          Dialog.alert({ message: res.msg ? res.msg : res })
        })
      getTimeLabel()
        .then((res) => {
          this.labelList = [{ name: '全部', id: '' }].concat(res.data)
        })
        .catch((res) => {
          Dialog.alert({ message: res.msg ? res.msg : res })
        })
    },
    // 顶部点击
    onLabelClick(item) {
      this.listParam.label_id = item.id
      this.$refs.list.onRefresh()
    },
    // 侧栏点击
    onCategoryClick(item) {
      this.listParam.category_id = item.id
      this.$refs.list.onRefresh()
    },
    // 上架
    onMealUp(item) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定上架【' + item.name + '】菜品？'
      }).then(() => {
        this.$store.state.isLoading = true
        dishseOnSale({ id: item.id }).then((res) => {
          this.$store.state.isLoading = false
          this.$toast({ message: '上架成功' })
          this.$refs.list.onRefresh()
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      }).catch(() => {})
    },
    // 下架
    onMealDown(item) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定下架【' + item.name + '】菜品？'
      }).then(() => {
        this.$store.state.isLoading = true
        dishesOffSale({ id: item.id }).then((res) => {
          this.$store.state.isLoading = false
          this.$toast({ message: '下架成功' })
          this.$refs.list.onRefresh()
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      }).catch(() => {})
    },
    // 删除
    onDel() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定删除【' + this.selectItme.name + '】菜品？'
      }).then(() => {
        this.$store.state.isLoading = true
        cancel({ ids: [this.selectItme.id] }).then((res) => {
          this.$store.state.isLoading = false
          this.$toast({ message: '删除成功' })
          this.$refs.list.onRefresh()
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      }).catch(() => {})
    },
    goDetail(item) {
      if (!this.setting) {
        this.$router.push({ name: 'DishesDetail', query: { id: item.id }})
      } else {
      // 删除选中
        this.selectItme = item
      }
    },
    onAdd() {
      this.$router.push({ name: 'DishesCreate' })
    },
    onCancel() {
      this.setting = false
      this.selectItme = {}
    }
  }

}
</script>

<style lang="less" scoped>
@import "./dishes-manage.less";
</style>
