<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <div class="item" @click="onShowDialog('name')">
          <div class="title">联系人</div>
          <div class="msg">{{ info.name }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="onShowDialog('mobile')">
          <div class="title">联系电话</div>
          <div class="msg">{{ info.mobile }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="SelectPlace()">
          <div class="title">省/市/区</div>
          <div class="msg">{{ info.place }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="onShowDialog('address')">
          <div class="title">联系地址</div>
          <div class="msg">{{ info.address }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item" @click="onShowDialog('remark')">
          <div class="title">备注</div>
          <div class="msg">{{ info.remark }}</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
      </div>
    </div>

    <div v-if="showDialog" class="cover" @click="onCatch()">
      <div v-if="dialogType == 'input' || dialogType == 'textarea'" class="dialog">
        <div class="dialog-header">
          <div class="dialog-header-left" @click="onDialogClose()">取消</div>
          <div class="dialog-header-center">{{ dialogTitle }}</div>
          <div class="dialog-header-right" @click="onDialogSure()">确定</div>
        </div>
        <div class="dialog-content">
          <div v-if="dialogType == 'input'" class="dialog-content-input">
            <input v-model="dialogValue" class="dialog-content-input-field" type="text" cursor-spacing="80px" @click="onDialogInput()">
          </div>
          <div v-if="dialogType == 'textarea'" class="dialog-content-textarea">
            <textarea v-model="dialogValue" class="dialog-content-textarea-field" type="text" @click="onDialogInput()" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn" @click="submitForm()">提交申请</div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { Dialog } from 'vant'
import { cardApply } from '@/api/card'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      info: {},
      // 是否显示弹窗
      showDialog: false,
      // 弹窗类型：input输入
      dialogType: 'input',
      dialogTitle: '请输入',
      dialogPlaceholder: '请输入',
      dialogValue: '',
      dialogKey: ''
    }
  },
  computed: {
    ...mapGetters(['province', 'city', 'area'])
  },
  created() {
    this.info = this.$store.state.applyData
    this.init()
  },
  mounted() {
  },
  methods: {
    init() {
      if (this.province && this.city && this.area && this.province != '' && this.city != '' && this.area != '') {
        this.info.place = this.province.name + this.city.name + this.area.name
      } else {
        this.info.place = ''
      }
    },
    // 显示弹窗
    onShowDialog(event) {
      const key = event
      this.showDialog = true
      this.dialogKey = key
      this.dialogValue = this[key]
      // 联系人
      this.dialogTitle = '请输入' + (key == 'name' ? '联系人' : (key == 'mobile' ? '联系电话' : (key == 'address' ? '联系地址' : '备注')))
      this.dialogType = 'input'
    },
    // 关闭弹窗
    onDialogClose() {
      this.showDialog = false
    },
    // 弹窗输入
    onDialogInput(event) {
      this.dialogValue = event
    },
    // 确定
    onDialogSure(e) {
      const key = this.dialogKey
      const info = {}
      info[key] = this.dialogValue
      this.showDialog = false
      this.info[key] = info[key]
      this.info = this.$store.state.applyData
    },
    onCatch() {
    },
    // 选择省市区
    SelectPlace() {
      this.$router.push({ name: 'Province' })
    },
    // 提交饭卡挂失
    submitForm() {
      const param = {}
      param.province_id = this.province.code
      param.city_id = this.city.code
      param.district_id = this.area.code
      param.name = this.info.name
      param.mobile = this.info.mobile
      param.address = this.info.address
      param.remark = this.info.remark
      this.$store.state.isLoading = true
      cardApply(param).then(res => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: '提交申请成功' })
        this.$store.state.applyData = {}
        store.dispatch('save/resetProvince')
        store.dispatch('save/resetCity')
        store.dispatch('save/resetArea')
      }).catch(res => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./apply.less";
</style>
