<template>
  <div v-if="info" class="container">
    <div class="tip">*请确认以下信息无误再点击提交按钮</div>
    <div class="item">
      <div class="title">商户名称</div>
      <div class="val">{{ info.merchant && info.merchant.merchant_name }}</div>
    </div>
    <div class="item">
      <div class="title">商户号</div>
      <div class="val">{{ info.merchant && info.merchant.merchant_number }}</div>
    </div>
    <div class="item">
      <div class="title">电子账户号</div>
      <div class="val">{{ info.e_account }}</div>
    </div>
    <div class="content">
      <div class="item">
        <div class="title">姓名</div>
        <div class="val">
          <input v-model="name" type="text" placeholder="请输入姓名" class="input">
        </div>
      </div>
      <div class="item">
        <div class="title">身份证号</div>
        <div class="val">
          <input v-model="id_no" type="text" placeholder="请输入身份证号" class="input">
        </div>
      </div>
      <div class="item">
        <div class="title">签约手机号</div>
        <div class="val">
          <input v-model="mobile" type="text" placeholder="请输入签约手机号" class="input">
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="btn" @click="onNext">确认信息无误，下一步</div>
    </div>
  </div>
</template>

<script>
import { getInfo, signContractApply } from './api/api'
export default {
  name: 'AbcSignupAuthorize',
  data() {
    return {
      info: false,
      id_no: '',
      name: '',
      mobile: ''
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      getInfo().then((res) => {
        this.$store.state.isLoading = false
        this.info = res.data
        this.name = res.data.name
        this.id_no = res.data.id_no
        this.mobile = res.data.mobile
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        })
      })
    },

    onNext() {
      const param = {}
      param.name = this.name
      param.contract_mobile = this.mobile
      param.id_no = this.id_no
      this.$store.state.isLoading = true
      signContractApply(param).then((res) => {
        this.$store.state.isLoading = false
        const order_no = res && res.data && res.data.order_no || ''
        this.$router.replace({ name: 'AbcSignupAuthorizeCode', query: { order_no }})
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./signup_authorize.less";
</style>
