import { request } from '@/api/request'

/**
 * 资讯分类-所有
 */
export function getCategory() {
  return request({
    url: '/customer_h5/news_category/get_all',
    method: 'get'
  })
}

// 资讯-列表
export function getList(param = {}) {
  return request({
    url: '/customer_h5/news/get_list',
    method: 'get',
    params: param
  })
}

// 资讯-详情
export function getInfo(param = {}) {
  return request({
    url: '/customer_h5/news/get_info',
    method: 'get',
    params: param
  })
}
