<template>
  <div class="page">
    <div class="bg" />
    <div class="result">
      <img class="img" src="../../assets/pay_success.png">
      <div class="status">{{ data.use_content }}</div>
      <div class="tip">{{ data.use_at_format }}</div>
      <div class="btn" @click="onCheckReserve()">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    this.data = this.$route.query.result
  },
  methods: {
    onCheckReserve() {
      this.$router.replace({ name: 'Home' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./qrcode_success.less";
</style>
