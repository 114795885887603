<template>
  <div class="container">
    <div class="header">
      <img class="icon" src="./images/success.png">
      <div class="title">您已成功开通电子账户</div>
    </div>
    <div class="content">
      <div class="label">账户信息：</div>
      <div class="item">
        <div class="title">电子账户号</div>
        <div class="val">{{ info.e_account }}</div>
      </div>
      <div class="item">
        <div class="title">签约授权号</div>
        <div class="val">{{ info.contract_no }}</div>
      </div>
    </div>
    <div class="footer">
      <div class="btn" @click="onBack">跳转至电子账户页面</div>
    </div>
  </div>
</template>

<script>
import { getInfo } from './api/api'
import { resetRouter } from '@/router'
export default {
  name: 'AbcSignupSuccess',
  data() {
    return {
      info: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      getInfo().then((res) => {
        this.$store.state.isLoading = false
        this.info = res.data
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        })
      })
    },

    onBack() {
      resetRouter()
      this.$router.replace({ name: 'AbcIndex' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./signup_success.less";
</style>
