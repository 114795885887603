import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import ABCRouter from './modules/abc'

Vue.use(VueRouter)
import Layout from '@/views/cardapply/layout'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '智慧食堂'
    }
  },
  {
    path: '/topup',
    name: 'Topup',
    component: () => import('../views/topup'),
    meta: {
      title: '金额充值'
    }
  },
  {
    path: '/recharge_success',
    name: 'Recharge_success',
    component: () => import('../views/topup/recharge_success'),
    meta: {
      title: '充值成功'
    }
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: () => import('../views/reserve/reserve'),
    meta: {
      title: '预定订餐'
    }
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import('../views/news/detail')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/reserve/pay'),
    meta: {
      title: '订餐支付'
    }
  },
  {
    path: '/pay_success',
    name: 'Pay_success',
    component: () => import('../views/reserve/pay_success'),
    meta: {
      title: '支付结果'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/history/history'),
    meta: {
      title: '预定订单'
    }
  },
  {
    path: '/weigh_order',
    name: 'WeighOrder',
    component: () => import('../views/weigh-order'),
    meta: {
      title: '称重订单'
    }
  },
  {
    path: '/weigh_order_detail',
    name: 'WeighOrderDetail',
    component: () => import('../views/weigh-order/detail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/round',
    name: 'Round',
    component: () => import('../views/round/round'),
    meta: {
      title: '围餐'
    }
  },
  {
    path: '/roundpay',
    name: 'RoundPay',
    component: () => import('../views/round/pay'),
    meta: {
      title: '添加围餐'
    }
  },
  {
    path: '/roundpay_success',
    name: 'RoundPay_success',
    component: () => import('../views/round/pay_success'),
    meta: {
      title: '支付结果'
    }
  },
  {
    path: '/roundlist',
    name: 'RoundList',
    component: () => import('../views/round/roundlist'),
    meta: {
      title: '围餐订单'
    }
  },
  {
    path: '/roundcheck',
    name: 'RoundCheck',
    component: () => import('../views/round/roundcheck'),
    meta: {
      title: '围餐审批'
    }
  },
  {
    path: '/checksend',
    name: 'CheckSend',
    component: () => import('../views/round/checksend'),
    meta: {
      title: '提交审批'
    }
  },
  {
    path: '/cash',
    name: 'Cash',
    component: () => import('../views/history/cash'),
    meta: {
      title: '金额订单'
    }
  },
  {
    path: '/scene',
    name: 'Scene',
    component: () => import('../views/history/scene'),
    meta: {
      title: '点餐订单'
    }
  },
  {
    path: '/auto',
    name: 'Auto',
    component: () => import('../views/auto'),
    meta: {
      title: '自动充值'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/feedback'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/feedbackList',
    name: 'FeedbackList',
    component: () => import('../views/feedback/list'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/feedbackDetail',
    name: 'FeedbackDetail',
    component: () => import('../views/feedback/detail'),
    meta: {
      title: '反馈详情'
    }
  },
  {
    path: '/guide',
    name: 'GuideList',
    component: () => import('../views/guide'),
    meta: {
      title: '使用指南'
    }
  },
  {
    path: '/guideDetail',
    name: 'GuideDetail',
    component: () => import('../views/guide/detail'),
    meta: {
      title: '使用详情'
    }
  },
  {
    path: '/bill',
    name: 'Bill',
    component: () => import('../views/bill'),
    meta: {
      title: '消费记录'
    }
  },
  {
    path: '/billDetail',
    name: 'BillDetail',
    component: () => import('../views/bill/detail'),
    meta: {
      title: '消费详情'
    }
  },
  {
    path: '/phone',
    name: 'Phone',
    component: () => import('@/views/phone/phone'),
    meta: {
      title: '绑定手机'
    }
  },
  {
    path: '/userinfo',
    name: 'UserInfo',
    component: () => import('@/views/userinfo/userinfo'),
    meta: {
      title: '个人资料'
    }
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('@/views/userinfo/mobile'),
    meta: {
      title: '编辑手机'
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('@/views/userinfo/accounts'),
    meta: {
      title: '用户管理'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/userinfo/profile'),
    meta: {
      title: '头像设置'
    }
  },
  {
    path: '/qrcode',
    name: 'Qrcode',
    component: () => import('@/views/userinfo/qrcode'),
    meta: {
      title: '付款二维码'
    }
  },
  {
    path: '/qrcode_success',
    name: 'Qrcode_success',
    component: () => import('@/views/userinfo/qrcode_success'),
    meta: {
      title: '支付结果'
    }
  },
  {
    path: '/face',
    name: 'Face',
    component: () => import('@/views/face/index'),
    meta: {
      title: '人脸识别'
    }
  },
  {
    path: '/camera',
    name: 'Camera',
    component: () => import('@/views/face/camera'),
    meta: {
      title: '人脸识别'
    }
  },
  {
    path: '/cardapply',
    name: 'CardApply',
    component: Layout,
    children: [
      {
        path: 'apply',
        name: 'Apply',
        component: () => import('@/views/cardapply/apply'),
        meta: {
          title: '饭卡挂失'
        }
      },
      {
        path: 'province',
        name: 'Province',
        component: () => import('@/views/cardapply/province'),
        meta: {
          title: '选择省份'
        }
      },
      {
        path: 'city',
        name: 'City',
        component: () => import('@/views/cardapply/city'),
        meta: {
          title: '选择直辖市'
        }
      },
      {
        path: 'area',
        name: 'Area',
        component: () => import('@/views/cardapply/area'),
        meta: {
          title: '选择区域'
        }
      }
    ]
  },
  {
    path: '/evaluate',
    name: 'Evaluate',
    component: () => import('@/views/evaluate/evaluate'),
    meta: {
      title: '评价'
    }
  },
  {
    path: '/cash-evaluate',
    name: 'CashEvaluate',
    component: () => import('@/views/evaluate/cash-evaluate'),
    meta: {
      title: '评价'
    }
  },
  {
    path: '/evaluate-detail',
    name: 'EvaluateDetail',
    component: () => import('@/views/evaluate/evaluate-detail'),
    meta: {
      title: '评价详情'
    }
  },
  {
    path: '/select-dishes',
    name: 'SelectDishes',
    component: () => import('@/views/evaluate/select-dishes'),
    meta: {
      title: '选择菜品'
    }
  },
  ...ABCRouter,
  {
    path: '/guide-abc',
    name: 'GuideABC',
    component: () => import('../views/guide-abc'),
    meta: {
      title: ''
    }
  },
  {
    path: '/subsidy-receive',
    name: 'SubsidyReceive',
    component: () => import('@/views/subsidy/receive'),
    meta: {
      title: '消息'
    }
  },
  {
    path: '/dishes-warn',
    name: 'DishesWarn',
    component: () => import('@/views/manage/dishes-warn'),
    meta: {
      title: '菜量预警'
    }
  },
  {
    path: '/dishes-manage',
    name: 'DishesManage',
    component: () => import('@/views/manage/dishes-manage'),
    meta: {
      title: '菜品管理'
    }
  },
  {
    path: '/dishes-create',
    name: 'DishesCreate',
    component: () => import('@/views/manage/manage-create'),
    meta: {
      title: '新增菜品'
    }
  },
  {
    path: '/dishes-edit',
    name: 'DishesEdit',
    component: () => import('@/views/manage/manage-edit'),
    meta: {
      title: '编辑菜品'
    }
  },
  {
    path: '/dishes-detail',
    name: 'DishesDetail',
    component: () => import('@/views/manage/manage-detail'),
    meta: {
      title: '菜品详情'
    }
  },
  {
    path: '/sale-statistics',
    name: 'SaleStatistics',
    component: () => import('@/views/manage/sale-statistics'),
    meta: {
      title: '销售统计'
    }
  }
]

const createRouter = () => new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// 检测排除冗余导航
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
