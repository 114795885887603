<template>
  <div class="loading">
    <div class="loadbg">
      <van-loading size="40px" vertical color="#ffffff">
        <span class="wait">加载中</span>
      </van-loading>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {}
}
</script>

<style scoped>
.loadbg{
  width: 100px;
  height: 80px;
  margin: 0 auto;
  padding-top: 20px;
  background: rgb(69, 67, 67);
  border: 1px solid rgb(69, 67, 67);
  border-radius: 6px;
}
.van-loading__text{
  margin-top: 12px !important;
}
.wait{
  margin-top: 10px;
  color: #ffffff;
}
</style>
