import { getToken, setToken, removeToken } from '@/views/abc/utils/auth'

const state = {
  abc_access_token: getToken(),
  abc_param: false,
  abc_pay_way: false,
  abc_signup_card_no: '',
  abc_signup_mobile: '',
  abc_area_province: false,
  abc_area_city: false,
  abc_area_district: false,
  abc_signup_card_address: '',
  abc_idcard_front_filename: '',
  abc_idcard_front_result: false,
  abc_idcard_back_filename: '',
  abc_idcard_back_result: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.abc_access_token = token
  },
  SET_PARAM: (state, param) => {
    state.abc_param = param
  },
  SET_PAY_WAY: (state, abc_pay_way) => {
    state.abc_pay_way = abc_pay_way
  },
  SET_SIGNUP_CARD_NO: (state, abc_signup_card_no) => {
    state.abc_signup_card_no = abc_signup_card_no
  },
  SET_SIGNUP_MOBILE: (state, abc_signup_mobile) => {
    state.abc_signup_mobile = abc_signup_mobile
  },
  SET_AREA_PROVINCE: (state, abc_area_province) => {
    state.abc_area_province = abc_area_province
  },
  SET_AREA_CITY: (state, abc_area_city) => {
    state.abc_area_city = abc_area_city
  },
  SET_SIGNUP_CARD_ADDRESS: (state, abc_signup_card_address) => {
    state.abc_signup_card_address = abc_signup_card_address
  },
  // 缓存数据通用
  CACHE_DATA: (state, obj = {}) => {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key]
        state[key] = element
      }
    }
  }
}

const actions = {
  setToken({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  setParam({ commit }, param) {
    return new Promise(resolve => {
      commit('SET_PARAM', param)
      resolve()
    })
  },
  setPayWay({ commit }, abc_pay_way) {
    return new Promise(resolve => {
      commit('SET_PAY_WAY', abc_pay_way)
      resolve()
    })
  },
  setSignupCardNo({ commit }, abc_signup_card_no) {
    return new Promise(resolve => {
      commit('SET_SIGNUP_CARD_NO', abc_signup_card_no)
      resolve()
    })
  },
  setSignupMobile({ commit }, abc_signup_mobile) {
    return new Promise(resolve => {
      commit('SET_SIGNUP_MOBILE', abc_signup_mobile)
      resolve()
    })
  },
  setAreaProvince({ commit }, abc_area_province) {
    return new Promise(resolve => {
      commit('SET_AREA_PROVINCE', abc_area_province)
      resolve()
    })
  },
  setAreaCity({ commit }, abc_area_city) {
    return new Promise(resolve => {
      commit('SET_AREA_CITY', abc_area_city)
      resolve()
    })
  },
  setSignupCardAddress({ commit }, abc_signup_card_address) {
    return new Promise(resolve => {
      commit('SET_SIGNUP_CARD_ADDRESS', abc_signup_card_address)
      resolve()
    })
  },
  // 缓存数据通用
  CacheData({ commit }, obj) {
    return new Promise(resolve => {
      commit('CACHE_DATA', obj)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
