<template>
  <div class="container">
    <div v-if="error_code == 20000" class="signup">
      <div class="header">
        <img src="./images/card.png" class="logo">
      </div>
      <div class="content">
        <div class="msg">·开通电子账户</div>
        <div class="msg">·一键快捷充值，秒到账</div>
      </div>
      <div class="footer">
        <div
          v-if="error_code == 20000"
          class="btn"
          @click="handleToOpen"
        >
          前往开通
        </div>
        <div
          v-if="error_code == 20001"
          class="btn"
          @click="
            () => {
              this.$router.push({ name: 'AbcSignupAuthorize' });
            }
          "
        >
          前往开通
        </div>
      </div>
    </div>
    <div v-if="error_code == 20001 && einfo" class="signup_account">
      <div class="header">
        <img class="icon" src="./images/success.png">
        <div class="title" style="font-size: 20px">开户成功</div>
        <div class="title" style="margin-top: 20px;color: #999">电子账户</div>
        <div class="val e_account" style="font-size: 15px;color: #666;">
          {{ einfo.e_account }}
        </div>
      </div>
      <div class="content">
        <div class="label">绑定银行卡：</div>
        <div class="item">
          <div style="flex: 1;display: flex;flex-direction: column;">
            <div class="title">
              <span class="bank_name">{{ einfo.card_info.bank_name }}</span>
              <span v-if="einfo.is_changing" class="changing">变更中</span>
            </div>
            <div class="val bank_card_no">{{ einfo.card_no_format }}</div>
          </div>
          <div
            v-if="!einfo.is_changing"
            class="btn card_change"
            @click="onChange"
          >
            更换
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn onAuthorize" @click="onAuthorize">授权扣费</div>
        <div class="btn onCancel" @click="onRelease">注销</div>
      </div>
    </div>
    <div v-else-if="info" class="info">
      <div class="header">
        <div class="title">{{ info.body }}</div>
        <div class="amount">￥{{ info.amount }}</div>
        <div class="time">支付剩余时间：{{ expire_time_text }}</div>
      </div>
      <div
        v-if="
          pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ||
            pay_way == 'wxapp_abc_eaccount_bridge_binding_pay'
        "
        class="label"
      >
        转入方式
      </div>
      <div
        v-if="
          pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' ||
            pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay'
        "
        class="label"
      >
        支付方式
      </div>
      <div class="content">
        <div class="info">
          <div class="name">
            {{ info.bank_name }}
            <div v-if="info.is_changing == 1" class="changing">变更中</div>
          </div>
          <div
            v-if="
              pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ||
                pay_way == 'wxapp_abc_eaccount_bridge_binding_pay'
            "
            class="balance"
          >
            {{ info.card_no }}
          </div>
          <div
            v-if="
              pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' ||
                pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay'
            "
            class="balance"
          >
            余额{{ info.balance }}元
          </div>
        </div>
        <div
          v-if="
            (pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ||
              pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') &&
              info.is_changing == 0
          "
          class="btn normal"
          @click="
            () => {
              this.$router.push({ name: 'AbcCardChange' });
            }
          "
        >
          更换
        </div>
        <div
          v-if="
            (pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ||
              pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') &&
              info.is_reset <= 0 &&
              info.is_changing == 0
          "
          class="btn"
          @click="onOut"
        >
          转出
        </div>
        <div
          v-if="
            pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' ||
              pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay'
          "
          class="btn"
          @click="
            () => {
              this.$router.push({ name: 'AbcAccount' });
            }
          "
        >
          管理
        </div>
      </div>

      <div class="footer">
        <div
          v-if="
            pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ||
              pay_way == 'wxapp_abc_eaccount_bridge_binding_pay'
          "
          :class="['btn', info.is_changing == 1 ? 'disable' : '']"
          @click="handleSubmit"
        >
          {{ info.is_changing == 1 ? "银行卡变更中不允许操作" : "确认转入" }}
        </div>
        <div
          v-if="
            pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' ||
              pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay'
          "
          :class="[
            'btn',
            info.is_changing == 1 || info.balance * 100 < info.amount * 100
              ? 'disable'
              : ''
          ]"
          @click="handleSubmit"
        >
          {{ info.is_changing == 1 ? "银行卡变更中不允许操作" : "确认支付" }}
        </div>
        <!-- 收银台快E付 -->
        <div v-if="pay_way == 'abc_trust_mobile_lua_pay'" :class="['btn', info.amount * 100 <= 0 ? 'disable' : '']" @click="handleSubmitLuaPay">
          确认支付
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, trustRechargeGetPayInfo, trustBindingGetPayInfo, bridgeBindingGetPayInfo, bridgeRechargeGetPayInfo, getInfo, releaseApply, trustBindingPayment, bridgeBindingPayment, trustRechargePayment, bridgeRechargePayment, abcTrustMobileLuaPayGetPayInfo, abcTrustMobileLuaPayPayment } from './api/api'
export default {
  name: 'AbcIndex',
  data() {
    return {
      error_code: false, // 错误码
      info: false, // 支付详情
      einfo: false, // 电子账户详情
      pay_way: '',
      time: false,
      expire_time: 0, // 支付过期时间
      expire_time_text: '',
      success_path: '' // 支付成功后的跳转页面
    }
  },
  created() {
    const config = this.$route.query
    const param = {}
    param.appid = config.appid
    param.merchant_id = config.merchant_id
    param.setting_number = config.setting_number
    param.prepay_id = config.prepay_id
    this.$store.dispatch('abc/setParam', param)
    this.success_path = config.success_path

    this.login()
  },
  mounted() {

  },
  methods: {
    async login() {
      const param = this.$store.getters.abc_param
      this.$store.state.isLoading = true
      try {
        const res = await login(param)
        this.$store.dispatch('abc/setPayWay', res.data.pay_way)
        this.$store.dispatch('abc/setToken', res.data.access_token)
        this.loadInfo()
      } catch (error) {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          // this.$router.go(-1)
          this.$router.replace({ name: 'Recharge' })
        })
      }
    },

    async loadInfo() {
      const pay_way = this.$store.getters.abc_pay_way
      this.pay_way = pay_way
      this.error_code = 0
      let res
      try {
        if (pay_way == 'wxapp_abc_eaccount_trust_recharge_pay') {
          res = await trustRechargeGetPayInfo()
        } else if (pay_way == 'wxapp_abc_eaccount_trust_binding_pay') {
          res = await trustBindingGetPayInfo()
        } else if (pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') {
          res = await bridgeBindingGetPayInfo()
        } else if (pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay') {
          res = await bridgeRechargeGetPayInfo()
        } else if (pay_way == 'abc_trust_mobile_lua_pay') {
          res = await abcTrustMobileLuaPayGetPayInfo()
        } else {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: '支付失败：支付类型暂不支持'
          }).then(() => {
            this.$router.go(-1)
          })
          return
        }
        this.$store.state.isLoading = false
        this.expire_time = res.data.valid_time
        this.info = res.data
        this.error_code = 0
        this.einfo = false
        this.calculate()
      } catch (error) {
        this.$store.state.isLoading = false
        if (error.code == 20000) { // 未开通农行电子账户
          this.error_code = error.code
          this.info = false
          this.einfo = false
          return
        } else if (error.code == 20001) {
        // 加载电子账户信息
          this.$store.state.isLoading = true
          getInfo().then((res) => {
            this.$store.state.isLoading = false
            this.error_code = 20001
            this.einfo = res.data
            this.info = false
            this.verifyBank()
          }).catch((error) => {
            this.$store.state.isLoading = false
            this.$dialog.alert({
              message: error.msg
            }).then(() => {
              this.$router.go(-1)
            })
          })
          return
        }
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          this.$router.go(-1)
        })
      }
      this.verifyBank()
    },

    verifyBank() {
      if (!this.info && !this.einfo) return
      const bank = this.info || this.einfo
      let bank_name = ''
      if (this.info) {
        bank_name = bank.bank_name
      } else if (this.einfo) {
        bank_name = bank.card_info && bank.card_info.bank_name
      }
      const isCMB = bank_name.indexOf('招商银行') > -1
      if (isCMB) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '目前招商银行卡暂不支持电子账户充值业务，为保障您的正常使用请您进行注销后更换其他I类卡重新进行开户和签约，造成不便请见谅。'
        })
      }
    },

    handleToOpen() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '目前电子账户暂不支持招商银行卡开通，请知悉，谢谢'
      }).then(() => {
        this.$router.push({ name: 'AbcSignupCard' })
      }).catch(() => {})
    },

    // 计算发送时间
    calculate() {
      let time = this.expire_time
      time--
      if (this.expire_time <= 0) {
        this.expire_time = 0
        this.expire_time_text = '已过期'
      } else {
        this.expire_time = time
        let timestamp = time
        let hour = 0
        if (timestamp > 3600) {
          hour = Math.floor(timestamp / 3600)
          timestamp = timestamp % 3600
        }
        const minute = Math.floor(timestamp / 60)
        const second = timestamp % 60
        this.expire_time_text = hour + ':' + minute + ':' + second
      }
      if (this.time) {
        clearTimeout(this.time)
      }
      this.time = setTimeout(() => {
        this.time = false
        this.calculate()
      }, 1000)
    },

    // 转出
    onOut() {
      this.$router.push({ name: 'AbcAccountOut', query: { pay_way: this.pay_way }})
    },

    handleSubmit() {
      if (this.info.is_changing == 1) {
        return
      }
      if (this.pay_way == 'wxapp_abc_eaccount_trust_binding_pay' || this.pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') { // 绑定支付
        this.$dialog.confirm({ title: '温馨提示', message: '确认转入？' }).then(async() => {
          this.$store.state.isLoading = true
          try {
            if (this.pay_way == 'wxapp_abc_eaccount_trust_binding_pay') {
              await trustBindingPayment()
            } else if (this.pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') {
              await bridgeBindingPayment()
            }
            this.$router.replace(this.success_path)
          } catch (error) {
            this.$dialog.alert({
              message: error.msg
            })
          }
          this.$store.state.isLoading = false
        })
      } else if (this.pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' || this.pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay') { // 充值
        if ((this.info.balance * 100) < (this.info.amount * 100)) {
          return
        }
        this.$dialog.confirm({ title: '温馨提示', message: '确认支付？' }).then(async() => {
          this.$store.state.isLoading = true
          try {
            if (this.pay_way == 'wxapp_abc_eaccount_trust_recharge_pay') {
              await trustRechargePayment()
            } else if (this.pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay') {
              await bridgeRechargePayment()
            }
            this.$router.replace(this.success_path)
          } catch (error) {
            this.$dialog.alert({
              message: error.msg
            })
          }
          this.$store.state.isLoading = false
        })
      }
    },

    handleSubmitLuaPay() {
      if (this.info.amount * 100 <= 0) {
        return
      }
      this.$dialog.confirm({ title: '温馨提示', message: '确认支付？' }).then(async() => {
        this.$store.state.isLoading = true
        try {
          const res = await abcTrustMobileLuaPayPayment()
          const url = res.data.payment_url
          window.location.href = url
        } catch (error) {
          this.$dialog.alert({
            message: error.msg
          })
        }
        this.$store.state.isLoading = false
      })
    },

    onChange() {
      this.$router.push({ name: 'AbcCardChange' })
    },

    onAuthorize() {
      this.$router.push({ name: 'AbcSignupAuthorize' })
    },

    onRelease() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定注销电子账户？'
      }).then(() => {
        this.$store.state.isLoading = true
        releaseApply().then((res) => {
          this.$store.state.isLoading = false
          this.$router.push({ name: 'AbcSignupReleaseCode', query: { token: res.data.token }})
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
