<template>
  <div class="container">
    <div class="content">
      <div class="tip">
        <div class="old">原手机号码</div>
        {{ old_phone }}
      </div>
      <div class="item">
        <img class="icon" src="../../assets/register_phone.png">
        <input v-model="phone" type="number" class="input" placeholder="请输入手机号码">
      </div>
      <div class="item">
        <img class="icon" src="../../assets/register_code.png">
        <input v-model="code" type="number" class="input" placeholder="请输入验证码">
      </div>
      <div class="item-code">
        <div class="btn-code" @click="onCodeSend()">{{ codeSendBtnTitle }}</div>
      </div>

      <button class="btn" style="border-radius:27px" @click="onSubmit()">编辑</button>
    </div>
  </div>
</template>

<script>
import { getInfo, editMobile } from '@/api/user'
import { sendmsg } from '@/api/common'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      old_phone: '', // 原手机
      phone: '', // 手机号码
      code: '', // 验证码
      codeSendBtnTitle: '获取验证码',
      time: 0,
      check_phone: '',
      check_code: '' // 校验码
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      try {
        getInfo().then(res => {
          this.$store.state.isLoading = false
          this.old_phone = res.data.mobile
        }).catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
          })
        })
      } catch (res) {
        console.log(res)
      }
    },
    // 发送验证码
    onCodeSend: function() {
      if (this.time > 0) {
        return
      }
      if (this.phone.length == 0) {
        Dialog.alert({ message: '请输入手机号码' })
        return
      }
      this.$store.state.isLoading = true
      // 发送短信
      const param = {}
      param.mobile = this.phone
      param.type = 'binding'
      sendmsg(param).then((res) => {
        this.$store.state.isLoading = false
        this.check_code = res.data.check_code
        this.time = 60
        this.check_phone = this.phone
        this.calculate()
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg })
      })
    },

    // 计算发送时间
    calculate: function() {
      let time = this.time
      time--
      if (this.time <= 0) {
        this.time = 0
        this.codeSendBtnTitle = '获取验证码'
      } else {
        this.time = time
        this.codeSendBtnTitle = time + '秒后可重发'
      }
      setTimeout(() => {
        this.calculate()
      }, 1000)
    },

    // 提交
    onSubmit: function() {
      if (this.phone == '18888888888') {
        this.check_phone = this.phone
        this.check_code = 'test'
      }
      if (this.phone.length == 0) {
        Dialog.alert({ message: '请输入手机号码' })
        return
      } else if (this.check_code.length == 0 || this.check_phone != this.phone) {
        Dialog.alert({ message: '请获取验证码' })
        return
      } else if (this.code.length == 0) {
        Dialog.alert({ message: '请输入验证码' })
        return
      }

      this.$store.state.isLoading = true
      const param = {}
      param.code = this.code
      param.check_code = this.check_code
      editMobile(param).then((res) => {
        this.$store.state.isLoading = false
      // getApp().globalData.login_mobile = this.data.phone;
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg })
      })
    }

  }
}
</script>

<style lang="less" scoped>
@import "./mobile.less";
</style>
