import Md5 from './md5'

// 生成url参数
export function generalBody(data, key, checkArray = false) {
  if (data == null || data === undefined) {
    return [{ key: key, val: '' }]
  } else if (typeof data === 'object') {
    return generalBodyObject(data, key)
  } else if (typeof data === 'string') {
    return [{ key: key, val: data }]
  } else if (typeof data === 'number') {
    return [{ key: key, val: data + '' }]
  } else if (typeof data === 'boolean') {
    return [{ key: key, val: (data ? 1 : 0) + '' }]
  }
  return false
}

export function generalBodyArray(data, key) {
  const list = []

  for (var i = 0; i < data.length; i++) {
    const val = data[i]
    const paramkey = key + '[]'
    const itemlist = generalBody(val, paramkey)
    if (itemlist) {
      list.push.apply(list, itemlist)
    }
  }
  return list
}

export function generalBodyObject(data, key) {
  const list = []
  if (typeof data !== 'object') {
    return list
  }
  const objkeys = Object.keys(data)
  if (objkeys.length > 0) {
    for (var i = 0; i < objkeys.length; i++) {
      const objkey = objkeys[i]
      const val = data[objkey]
      const paramkey = key ? key + '[' + objkey + ']' : objkey
      const itemlist = generalBody(val, paramkey)
      if (itemlist) {
        list.push.apply(list, itemlist)
      }
    }
  }
  return list
}

/**
 * 生成sign
 * 每个接口都会用到sign加密验证
 */
export function generateSign(param) {
  // console.log('生成加密字段sign++++++++++++++++==')
  // console.log('请求参数：',param)
  if (!param) {
    param = {}
  }
  if (param.sign) {
    delete param.sign
  }
  const generalParam = {}
  if (Object.keys(param).length > 0) {
    for (const key in param) {
      if (param[key] == null || param[key] == undefined || param[key] == 0 || Array.isArray(param[key]) || Object.prototype.toString.call(param[key]) == '[object Object]') {
        continue
      }
      generalParam[key] = param[key]
    }
  }
  // 参数排序
  const allKeys = Object.keys(generalParam)
  allKeys.sort()
  // 参数重新组合
  const paramList = []
  for (const key of allKeys) {
    paramList.push(key + '=' + generalParam[key])
  }
  let urlParamStr = paramList.join('&')
  // 参数末尾加上加密字段
  if (urlParamStr.length >= 0) {
    urlParamStr += '&'
  }
  urlParamStr += 'key=' + '471323C1ADC851920E73E5CD86D5CA8D'
  // console.log('字符串组合：',urlParamStr)
  // md5加密生成sign
  const sign = Md5(urlParamStr)
  // console.log('加密字段生成:', sign)
  // console.log('生成加密字段sign++++++++++++++++==')
  // 返回大写
  return sign.toLocaleUpperCase()
}
