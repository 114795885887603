<template>
  <div class="container">
    <div class="content">
      <div class="item">
        <div class="subtitle">
          菜品信息
        </div>
      </div>
      <div class="item custom">
        <div class="title">
          <div class="required">*</div>
          菜品图
        </div>
        <van-uploader :after-read="afterRead">
          <div class="camera">
            <div v-if="!cover_img" class="placers">
              <van-icon name="plus" color="#aaa" size="40" />
            </div>
            <div v-else class="img">
              <img style="width: 100%; height: 100%;" :src="cover_img">
            </div>
            <div class="placers-text">
              注: 图片建议比例为：1:1
            </div>
          </div>
        </van-uploader>
      </div>

      <div class="item">
        <div class="title">
          <div class="required">*</div>
          菜品名称
        </div>
        <input
          v-model="addForm.name"
          type="text"
          maxlength="20"
          class="input"
          placeholder="请填写"
          placeholder-class="address"
        >
        <van-icon
          v-if="addForm.name"
          color="#ddd"
          name="clear"
          class="icon"
          size="18"
          @click="clear('name')"
        />
      </div>
      <div class="item">
        <div class="title">
          <div class="required">*</div>
          分类
        </div>
        <div class="item-select" style="flex:1" @click="pickerChang(10)">
          <div>
            {{ category_name ? category_name : "请填写" }}
          </div>
        </div>
        <van-icon class="icon" color="#999" size="10" name="arrow" />
      </div>

      <div class="item">
        <div class="title">
          <div class="required">*</div>
          计价方式
        </div>
        <div class="item-select" style="flex:1" @click="pickerChang(20)">
          <div>
            {{ pricetype_name ? pricetype_name : "请填写" }}
          </div>
        </div>
        <van-icon class="icon" color="#999" size="10" name="arrow" />
      </div>

      <div v-if="addForm.price_type == 20" class="item">
        <div class="title">
          <div class="required">*</div>
          重量
        </div>
        <input
          v-model="addForm.weigh"
          type="number"
          maxlength="10"
          class="input"
          placeholder="请填写"
          placeholder-class="address"
        >
        <div class="icon" color="#999" name="play-right-fill" size="10">
          克/份
        </div>
      </div>
      <div class="item">
        <div class="title">
          <div class="required">*</div>
          单价
        </div>
        <input
          v-model="addForm.price"
          type="digit"
          maxlength="10"
          class="input"
          placeholder="请填写"
          placeholder-class="address"
        >
        <div class="icon" color="#999" name="play-right-fill" size="10">
          {{ addForm.price_type == 10 ? "¥/50克" : "¥/份" }}
        </div>
      </div>

      <div class="item custom">
        <div class="title">广告语</div>
        <div style="flex:1">
          <textarea
            v-model="addForm.ad_words"
            class="textarea"
            placeholder-class="address"
            count
            placeholder="请填写"
            maxlength="100"
            height="200"
          />
          <div class="textarea-num">{{ addForm.ad_words.length }}/100</div>
        </div>
      </div>
    </div>

    <div v-if="info" class="content">
      <div class="item">
        <div class="subtitle">
          上架信息
        </div>
      </div>
      <div class="item">
        <div class="title">
          <div class="required">*</div>
          是否上架
        </div>
        <van-switch
          v-model="addForm.is_on_sale"
          size="22px"
          active-color="#ffce0f"
          inactive-color="#DDDDDD"
          :active-value="1"
          :inactive-value="0"
        />
      </div>

      <div class="item" style="height:auto">
        <div class="title">
          <div class="required">*</div>
          供应食堂
        </div>
        <div class="item-select" style="flex:1">
          <div class="ellipsis">
            <SelectCanteen
              v-model="canteenIds"
              :http-request="canteenRequest"
            />
          </div>
        </div>
        <van-icon class="icon" color="#999" size="10" name="arrow" />
      </div>

      <div class="item" style="height:auto">
        <div class="title">
          <div class="required">*</div>
          供应餐段
        </div>
        <div class="item-select" style="flex:1">
          <div class="ellipsis">
            <!-- 多选 -->
            <SelectCanteen v-model="labelIds" :http-request="labelRequest" />
          </div>
        </div>
        <van-icon class="icon" color="#999" size="10" name="arrow" />
      </div>

      <div class="item">
        <div class="title">
          菜量阈值
        </div>
        <input
          v-model="addForm.warn_threshold"
          type="number"
          maxlength="10"
          class="input"
          placeholder="不填默认低于100发出预警"
          placeholder-class="address"
        >
        <div class="icon" color="#999" name="play-right-fill" size="10">克</div>
      </div>
    </div>
    <div style="height: 75px;" />
    <div class="bottom-bar">
      <div class="affirm" @click="submitaddForm()">保存</div>
    </div>

    <!-- 类别选择 -->
    <van-popup
      v-model="showTypePicker"
      position="bottom"
      custom-style="height:200px;"
      @close="onClose"
    >
      <van-picker
        show-toolbar
        :title="picker_title"
        :columns="pickerList"
        @cancel="onClose"
        @confirm="onTypeConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getTimeLabel,
  getClassification,
  getCanteenList,
  dishesEdit,
  getInfo
} from '@/api/manage'
import { upload } from '@/api/common'
import SelectCanteen from './components/select-canteen'
export default {
  components: { SelectCanteen },
  data() {
    return {
      canteenRequest: getCanteenList,
      labelRequest: getTimeLabel,
      addForm: {
        cover_img: '',
        name: '',
        price: '',
        weigh: '',
        is_on_sale: 1,
        ad_words: '',
        price_type: 10,
        warn_threshold: '',
        category_id: '',
        label_ids: [],
        canteen_ids: []
      },
      labelIds: [], // 餐段选择
      canteenIds: [], // 食堂选择
      priceTypeList: [
        { id: 10, name: '按重量' },
        { id: 20, name: '按份数' }
      ], // 计价类型列表
      categoryList: [], // 分类列表
      category_name: '',
      pricetype_name: '',
      showTypePicker: false,
      showMorePicker: false,
      pickerType: 10,
      picker_title: '',
      pickerList: [],
      canteen_name: '',
      cover_img: '',
      uploadFile: false,
      info: false
    }
  },
  watch: {
    labelIds(val) {
      if (val.length > 0) {
        this.addForm.label_ids = val.map(item => item.id)
      } else {
        this.addForm.label_ids = []
      }
    },
    canteenIds(val) {
      if (val.length > 0) {
        this.addForm.canteen_ids = val.map(item => item.id)
      } else {
        this.addForm.canteen_ids = []
      }
    }
  },
  mounted() {
    this.getCategory()
    this.loadData()
  },
  methods: {
    loadData() {
      const param = {}
      param.id = this.$route.query.id
      this.$store.state.isLoading = true
      getInfo(param)
        .then(res => {
          if (!res.data) return
          const { id, name, price_yuan, weigh, is_on_sale, ad_words, price_type, warn_threshold, price_type_format, cover_img, canteen, category_id, category, labels } = res.data
          this.addForm.id = id
          this.addForm.name = name
          this.addForm.price = price_yuan
          this.addForm.weigh = weigh
          this.addForm.is_on_sale = is_on_sale
          this.addForm.ad_words = ad_words
          this.addForm.price_type = price_type
          this.addForm.warn_threshold = warn_threshold
          this.addForm.category_id = category_id
          this.category_name = category.name
          this.pricetype_name = price_type_format
          this.labelIds = labels
          this.canteenIds = canteen
          this.cover_img = cover_img.file_url && cover_img.file_url.middle ? cover_img.file_url.middle : ''
          this.addForm.cover_img = cover_img.file_name
          this.$store.state.isLoading = false
          this.info = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    },
    getCategory(listParam) {
      getClassification()
        .then(res => {
          this.categoryList = res.data
        })
        .catch(res => {
          this.$dialog.alert({ message: res.msg })
        })
    },
    submitaddForm() {
      const addForm = this.addForm
      if (addForm.name == '') {
        this.$dialog.alert({ message: '请输入菜品名称' })
        return
      } else if (addForm.category_id == '') {
        this.$dialog.alert({ message: '请选择菜品分类' })
        return
      } else if (addForm.price_type == '') {
        this.$dialog.alert({ message: '请选择计价方式' })
        return
      } else if (addForm.price == '') {
        this.$dialog.alert({ message: '请输入单价' })
        return
      } else if (addForm.weigh == '' && addForm.price_type == 20) {
        this.$dialog.alert({ message: '请输入重量' })
        return
      } else if (addForm.canteen_ids.length == 0) {
        this.$dialog.alert({ message: '请选择供应食堂' })
        return
      } else if (addForm.label_ids.length == 0) {
        this.$dialog.alert({ message: '请选择供应餐段' })
        return
      } else if (addForm.cover_img == '') {
        this.$dialog.alert({ message: '请上传图片' })
        return
      }
      if (addForm.price_type == 10) {
        addForm.weigh = 50
      }
      if (addForm.warn_threshold == '') {
        addForm.warn_threshold = 100
      }
      if (this.$store.state.isLoading) {
        return
      }
      this.$store.state.isLoading = true
      dishesEdit(addForm)
        .then(res => {
          this.$store.state.isLoading = false
          this.$toast({ message: '编辑成功' })
          this.$router.go(-1)
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    },
    // 单选选择打开
    pickerChang(type) {
      this.pickerList = []
      this.pickerType = type
      if (type == 10) {
        this.picker_title = '选择分类'
        this.pickerList = this.categoryList.map(v => v.name)
      } else if (type == 20) {
        this.picker_title = '选择计价方式'
        this.pickerList = this.priceTypeList.map(v => v.name)
      }
      this.showTypePicker = true
    },
    // 选项选择
    onTypeConfirm(event, index) {
      if (this.pickerType == 10) {
        this.category_name = this.categoryList[index].name
        this.addForm.category_id = this.categoryList[index].id
      } else if (this.pickerType == 20) {
        this.pricetype_name = this.priceTypeList[index].name
        this.addForm.price_type = this.priceTypeList[index].id
      }
      this.showTypePicker = false
    },
    // 关闭
    onClose() {
      this.showTypePicker = false
    },
    clear(name) {
      this.addForm[name] = ''
    },

    // 图片
    afterRead(file) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
        message: '上传中…'
      })
      upload(file.file)
        .then(res => {
          this.$toast.clear()
          const file_name = res.data.file_name
          this.addForm.cover_img = file_name
          this.cover_img = res.data.file_url
        })
        .catch(res => {
          this.$toast.clear()
          this.$dialog.alert({ message: res.msg })
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./manage-edit.less";
</style>
