<template>
  <div class="page">
    <!--预定餐品-->
    <!-- 切换顾客 -->
    <div class="city" @click="onShowTypePicker()">
      <span class="title">{{ typeList[typeIndex] }}</span>
      <div class="arrow" />
      <!-- <image class="arrow" src="../../img/record_header_arrow.png"></image> -->
    </div>
    <!-- 幻灯片 -->
    <van-swipe v-if="adList && adList.length > 0" class="swiper slide" :autoplay="3000">
      <van-swipe-item v-for="(item, index) in adList" :key="index">
        <div class="swiper-content" @click="handleAd(index)">
          <van-image lazy-load fit="contain" class="cover" :src="item.img.file_url.middle" />
          <div class="title">
            <div class="tip">{{ item.name }}</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- 餐类 -->
    <!-- <van-swipe class="tab-bar">
      <van-swipe-item v-for="(item,index) in tabList" :key="index" class="item" :class="index == tabIndex ? 'active' : ''" @click="onTabsClick(item.dishes_label,index)">
        <div>{{ item.title }}</div>
        <div :class="index == tabIndex ? 'active' : ''" />
      </van-swipe-item>
    </van-swipe> -->
    <div class="reserve">
      <div class="tabs" :class="adList && adList.length > 0 ? 'swiper' : ''">
        <div
          v-for="(item,index) in meunList"
          :key="index"
          class="tab"
          :class="menuIndex == index ? 'active': ''"
          @click="onMenuClick(index)"
        >
          <span class="week">{{ item.date }}</span>
          <span class="day">{{ item.week }}</span>
          <div v-if="menuIndex == index" class="active" />
        </div>
        <div style="height: 50px;" />
      </div>

      <div class="content" :class="adList && adList.length > 0 ? 'swiper' : ''">
        <div class="tab-bar">
          <div v-for="(item,index) in tabList" :key="index" class="item" :class="index == tabIndex ? 'active' : ''" @click="onTabsClick(item.dishes_label,index)">
            <div>{{ item.title }}</div>
            <div :class="index == tabIndex ? 'active' : ''" />
          </div>
        </div>
        <!-- 菜品分类 -->
        <div
          class="category"
        >
          <div id="header-first" />
          <div
            v-for="(item,index) in categoryList"
            :key="index"
            class="item"
            :class="categoryIndex == index ? 'active': ''"
            @click="onCategoryClick(index)"
          >{{ item.name }}</div>
          <div id="header-last" class="right" />
        </div>
        <!-- 菜品列表 -->
        <van-list
          v-model="loading"
          :finished="finished"
          :offset="1"
          :immediate-check="false"
          :finished-text="listData.length > 0 ? '没有更多了' : '没有数据'"
          @load="loadData(listParam)"
        >
          <div v-for="(item, index) in listData" :key="index" :class="['card', tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 1 && item.surplus_num == 0 ? 'card-mark' : '']">
            <div class="card-dishes-img">
              <img class="dishes-img" :src="item.cover_img.file_url.middle" @click="showDialog(item)">
              <div v-if="tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 1 && item.surplus_num != 0" class="surplus-num">剩余{{ item.surplus_num }}份</div>
            </div>
            <div class="desc">
              <span class="desc-name" @click="showDialog(item)">{{ item.name }}</span>
              <div class="ad ellipsis" @click="showDialog(item)">{{ item.ad_words }}</div>
              <span class="price" @click="showDialog(item)">￥{{ item.price_yuan }}</span>
              <div v-if="tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 0 || item.surplus_num != 0" class="reserve">
                <!-- 预定 -->
                <div
                  class="symbol"
                  :class="
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ] &&
                      amount[
                        tabList[tabIndex]['menu_id'] +
                        ' ' +
                        tabList[tabIndex]['dishes_label'] +
                        ' ' +
                        item.id
                      ]['item'] &&
                      amount[
                        tabList[tabIndex]['menu_id'] +
                        ' ' +
                        tabList[tabIndex]['dishes_label'] +
                        ' ' +
                        item.id
                      ]['item']['quantity']
                      ? ''
                      : 'forbid'
                  "
                  @click="
                    onReduction(
                      tabList[tabIndex]['menu_id'] +
                        ' ' +
                        tabList[tabIndex]['dishes_label'] +
                        ' ' +
                        item.id,
                      item.price
                    )
                  "
                >
                  -
                </div>
                <div
                  class="amount"
                  :class="
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ] &&
                      amount[
                        tabList[tabIndex]['menu_id'] +
                        ' ' +
                        tabList[tabIndex]['dishes_label'] +
                        ' ' +
                        item.id
                      ]['item'] &&
                      amount[
                        tabList[tabIndex]['menu_id'] +
                        ' ' +
                        tabList[tabIndex]['dishes_label'] +
                        ' ' +
                        item.id
                      ]['item']['quantity']
                      ? ''
                      : 'forbid'
                  "
                >
                  <!-- {{ amount[meunList[menuIndex]['id'] + ' ' + tabList[tabIndex]['dishes_label'] + ' ' + item.id]['item']['quantity'] ? amount[meunList[menuIndex]['id'] + ' ' + tabList[tabIndex]['dishes_label'] + ' ' + item.id]['item']['quantity'] : 0 }} -->
                  {{
                    amount[
                      tabList[tabIndex]["menu_id"] +
                      " " +
                      tabList[tabIndex]["dishes_label"] +
                      " " +
                      item.id
                    ] &&
                      amount[
                        tabList[tabIndex]["menu_id"] +
                        " " +
                        tabList[tabIndex]["dishes_label"] +
                        " " +
                        item.id
                      ]["item"] &&
                      amount[
                        tabList[tabIndex]["menu_id"] +
                        " " +
                        tabList[tabIndex]["dishes_label"] +
                        " " +
                        item.id
                      ]["item"]["quantity"]
                      ? amount[
                        tabList[tabIndex]["menu_id"] +
                        " " +
                        tabList[tabIndex]["dishes_label"] +
                        " " +
                        item.id
                      ]["item"]["quantity"]
                      : 0
                  }}
                </div>
                <div
                  v-if="dishesSettings || (!dishesSettings && !(amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    item.id
                  ] &&
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ]['item'] &&
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ]['item']['quantity']) && (saveDishItems[tabList[tabIndex]['menu_id']] && saveDishItems[tabList[tabIndex]['menu_id']][tabList[tabIndex]['dishes_label']] && saveDishItems[tabList[tabIndex]['menu_id']][tabList[tabIndex]['dishes_label']].length || 0) < 1)"
                  :class="['symbol', (amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    item.id
                  ] &&
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ]['item'] &&
                    amount[
                      tabList[tabIndex]['menu_id'] +
                      ' ' +
                      tabList[tabIndex]['dishes_label'] +
                      ' ' +
                      item.id
                    ]['item']['quantity']) != item.surplus_num || tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 0 ? '' : 'forbid' ]"
                  @click="onReserve(item)"
                >+</div>
              </div>
              <div v-else class="reserve text-red">已售罄</div>
            </div>
          </div>
        </van-list>
      </div>

      <div class="bottom-bar" @click="onToAccount()">
        <div class="total">
          <div class="total-price">已选({{ sum }})</div>
          <div class="to-account">合计：{{ total }}</div>
        </div>
        <div class="dot" :class="sum > 0 ? 'hasSelected' : ''">
          <!-- <image class="dot-img" src="../../img/breakfast.png"></image> -->
          <div style="font-size:12px">选好了</div>
        </div>
      </div>

      <van-popup
        v-model="show"
        round
        position="bottom"
        custom-style="min-height: 40%;max-height: 84%;"
        @close="onClose"
      >
        <div class="popup-tip">已选餐品</div>
        <div v-for="(value,key) in saveDishItems" :key="key">
          <div class="title">
            {{ value.week }}
            <div class="border" />
          </div>
          <div>
            <div v-for="(subvalue,subkey) in value" :key="subkey">
              <div v-if="subkey != 'week'">
                <div v-for="(item,index) in subvalue" :key="index" class="popup-items">
                  <!-- 菜品ID：{{ item.id }}数量：{{ amount[key + ' ' + subkey + ' ' + item.id] }} -->
                  <!-- <image class="dishes-img" src="{{item.cover_img.file_url.middle}}"></image> -->
                  <div class="label" :style="{background: label[subkey] && label[subkey]['bg_color']}">{{ label[subkey] && label[subkey][subkey] }}</div>
                  <div class="desc">
                    <span>{{ item.name }}</span>
                    <span class="ad ellipsis" />
                    <span class="price">￥{{ item.price_yuan }}</span>
                    <div class="reserve">
                      <div
                        class="symbol"
                        :class="amount[key + ' ' + subkey + ' ' + item.id]['item']['quantity'] ? '' : 'forbid' "
                        @click="onReduction(key + ' ' + subkey + ' ' + item.id,item.price,subkey)"
                      >-</div>
                      <div
                        class="amount"
                      >{{ amount[key + ' ' + subkey + ' ' + item.id]['item']['quantity'] }}</div>
                      <div
                        v-if="dishesSettings"
                        :class="['symbol', (amount[key + ' ' + subkey + ' ' + item.id]['item']['quantity']) != item.surplus_num ? '' : 'forbid']"
                        @click="onAdd(key + ' ' + subkey + ' ' + item.id ,item.price)"
                      >+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 78px;" />
        <div class="bottom-bar" @click="onSubmitBill()">
          <div class="total">
            <div class="total-price">已选({{ sum }})</div>
            <div class="to-account">合计：{{ total }}</div>
          </div>
          <div class="dot" :class="sum > 0 ? 'hasSelected' : ''">
            <!-- <image class="dot-img" src="../../img/breakfast.png"></image> -->
            <div style="font-size:12px">选好了</div>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 食堂选项 -->
    <van-popup v-model="showTypePicker" position="bottom" @close="onTypePickerClose()">
      <van-picker
        show-toolbar
        :columns="typeList"
        :default-index="typeIndex"
        @cancel="onTypePickerCancel()"
        @confirm="onTypePickerConfirm"
      />
    </van-popup>

    <!-- 菜品弹框-->
    <van-dialog v-model="showDetail" use-slot close-on-click-overlay :async-close="true" custom-style="width: 325px; border-radius: 6px;" :show-confirm-button="false" @close="CloseDialog">
      <div class="dialog-container">
        <div class="dialog-img card-dishes-img">
          <img class="dialog-dish-img" :src="currentItem.cover_img && currentItem.cover_img.file_url.middle">
          <div v-if="tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 1 && currentItem.surplus_num != 0" class="surplus-num">剩余{{ currentItem.surplus_num }}份</div>
        </div>
        <span class="dialog-dish-title">{{ currentItem.name }}</span>
        <div class="dialog-dish-ad">{{ currentItem.ad_words }}</div>
        <div class="dialog-bottom">
          <span class="dialog-price">￥{{ currentItem.price_yuan }}</span>
          <div v-if="(tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 0 || currentItem.surplus_num != 0) && tabList[tabIndex] && tabList[tabIndex]['menu_id'] && tabList[tabIndex]['dishes_label']" class="reserve">
            <!-- 预定 -->
            <div
              class="symbol"
              :class="
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ] &&
                  amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    currentItem.id
                  ]['item'] &&
                  amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    currentItem.id
                  ]['item']['quantity']
                  ? ''
                  : 'forbid'
              "
              @click="
                onReduction(
                  tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    currentItem.id,
                  currentItem.price
                )
              "
            >
              -
            </div>
            <div
              class="amount"
              :class="
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ] &&
                  amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    currentItem.id
                  ]['item'] &&
                  amount[
                    tabList[tabIndex]['menu_id'] +
                    ' ' +
                    tabList[tabIndex]['dishes_label'] +
                    ' ' +
                    currentItem.id
                  ]['item']['quantity']
                  ? ''
                  : 'forbid'
              "
            >
              {{
                amount[
                  tabList[tabIndex]["menu_id"] +
                  " " +
                  tabList[tabIndex]["dishes_label"] +
                  " " +
                  currentItem.id
                ] &&
                  amount[
                    tabList[tabIndex]["menu_id"] +
                    " " +
                    tabList[tabIndex]["dishes_label"] +
                    " " +
                    currentItem.id
                  ]["item"] &&
                  amount[
                    tabList[tabIndex]["menu_id"] +
                    " " +
                    tabList[tabIndex]["dishes_label"] +
                    " " +
                    currentItem.id
                  ]["item"]["quantity"]
                  ? amount[
                    tabList[tabIndex]["menu_id"] +
                    " " +
                    tabList[tabIndex]["dishes_label"] +
                    " " +
                    currentItem.id
                  ]["item"]["quantity"]
                  : 0
              }}
            </div>
            <div
              v-if="dishesSettings || (!dishesSettings && !(amount[
                tabList[tabIndex]['menu_id'] +
                ' ' +
                tabList[tabIndex]['dishes_label'] +
                ' ' +
                currentItem.id
              ] &&
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ]['item'] &&
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ]['item']['quantity']) && (saveDishItems[tabList[tabIndex]['menu_id']] && saveDishItems[tabList[tabIndex]['menu_id']][tabList[tabIndex]['dishes_label']] && saveDishItems[tabList[tabIndex]['menu_id']][tabList[tabIndex]['dishes_label']].length || 0) < 1)"
              :class="['symbol', (amount[
                tabList[tabIndex]['menu_id'] +
                ' ' +
                tabList[tabIndex]['dishes_label'] +
                ' ' +
                currentItem.id
              ] &&
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ]['item'] &&
                amount[
                  tabList[tabIndex]['menu_id'] +
                  ' ' +
                  tabList[tabIndex]['dishes_label'] +
                  ' ' +
                  currentItem.id
                ]['item']['quantity']) != currentItem.surplus_num || tabList[tabIndex] && tabList[tabIndex]['is_sales_control'] == 0
                ? ''
                : 'forbid']"
              @click="onReserve(currentItem)"
            >+</div>
          </div>
          <div v-else class="reserve text-red">已售罄</div>
        </div>
      </div>
    </van-dialog>
    <!-- 关闭弹框图标 -->
    <div v-if="hasDialog" class="radius-box">
      <div class="dialog-close" @click="CloseDialog">×</div>
    </div>
  </div>
</template>

<script>
import {
  getCanteenList as getCantList,
  getTimeList as getTime,
  getMenuList as getMenu,
  getCategoryList as getCategory,
  getDishesList as getDishes
} from '@/api/meal'
import { getAd } from '@/api/ad'
import { getSettings } from '@/api/common'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      listParam: {},
      listData: [],
      finished: false,
      // 是否显示切换账号
      showChangeAccount: false,
      isLoadingAccounts: false,
      adList: [],
      tabList: [],
      label: {},
      toTimeView: '',
      // dishes_label: 10,
      tabIndex: 0,
      // 允许加载更多
      allowLoadMore: true,
      // 加载中
      loading: false,
      hasPermi: true,
      hasDetailPermi: true,
      meunList: [],
      menuIndex: 0,
      // 菜品分类
      categoryList: [],
      todiv: '',
      categoryIndex: 0,
      // 合计
      total: 0.00,
      amount: {},
      sum: 0,
      saveDishItems: {},
      // 显示弹出框
      show: false,
      // 食堂选项
      showTypePicker: false,
      typeList: ['全部'],
      typeIndex: 0,
      canteenData: [],
      // 食堂id
      canteen_id: '',
      // 当前选择的菜单
      currentMeunId: '',
      // 当前选择的餐段
      currentTimeId: '',
      // 餐段的菜单id
      menu_id: '',
      // 弹框显示
      showDetail: false,
      currentItem: {},
      currentIndex: '',
      // 显示取消弹框图标
      hasDialog: false,
      // 订餐配置
      dishesSettings: false
    }
  },

  created() {
    if (!this.$store.state.saveDishItems) {
      this.saveDishItems = {}
      this.total = 0.0
      this.sum = 0
      this.amount = {}
      this.show = false
    } else {
      console.log('created', this.$store.state.saveDishItems)
      this.saveDishItems = this.$store.state.saveDishItems
      this.total = this.$store.state.total
      this.sum = this.$store.state.sum
      this.amount = this.$store.state.amount
      this.currentMeunId = this.$store.state.currentMeunId
      this.currentTimeId = this.$store.state.currentTimeId
      this.listParam.menu_id = this.currentMeunId
      if (Object.keys(this.$store.state.saveDishItems).length == 0) {
        this.show = false
      }
    }
    this.loading = false
  },

  mounted() {
    this.loadDishesSettings()
    this.loadAdData()
    this.loadCanteenData()
  },

  methods: {
  // 订餐配置
    async loadDishesSettings() {
      try {
        const res = await getSettings()
        this.dishesSettings = res.data && res.data.order_reserve_is_allow_move_dishes == 1
        console.log(5566, this.dishesSettings)
      } catch (error) {
        Dialog.alert({ message: error.msg || error })
      }
    },

    // 显示食堂选项
    onShowTypePicker() {
      if (!this.sum) {
        if (this.$route.query.canteen_id > 0) {
          return
        }
        this.showTypePicker = true
      } else {
        Dialog.confirm({ message: '切换食堂会清空选择的菜品' }).then(() => {
          this.sum = 0
          this.saveDishItems = {}
          this.amount = {}
          this.total = 0.00
          this.showTypePicker = true
        }).catch(() => {})
      }
    },

    // 关闭食堂选项
    onTypePickerCancel() {
      this.showTypePicker = false
    },

    // 关闭食堂选项
    onTypePickerClose() {
      this.showTypePicker = false
    },

    // 食堂选项选择
    onTypePickerConfirm(event, index) {
      const canteen_id = this.canteenData[index].id
      this.showTypePicker = false
      this.typeIndex = index
      this.listData = []
      this.canteen_id = canteen_id
      this.listParam.canteen_id = canteen_id
      this.$store.state.menuIndex = 0
      this.loadMenuData()
    },
    loadAdData() {
      this.$store.state.isLoading = true
      getAd()
        .then(res => {
          this.$store.state.isLoading = false
          this.adList = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    handleAd(index) {
      const item = this.adList[index]
      const path = this.$tool.jump(item.link_type, item.link_value)
      this.$router.push(path)
    },

    // 获取食堂选项
    loadCanteenData() {
      const that = this
      this.$store.state.isLoading = true

      getCantList()
        .then((res) => {
          this.$store.state.isLoading = false
          this.canteenData = res.data.rows
          this.typeList = this.canteenData.map((v) => v.name)
          const index = this.canteenData.findIndex(v => {
            return v.id == that.$route.query.canteen_id
          })
          this.typeIndex = index == -1 ? 0 : index
          if (this.canteenData && this.canteenData.length > 0) {
            this.canteen_id = this.canteenData[this.typeIndex].id
            this.listParam.canteen_id = this.canteenData[this.typeIndex].id
            this.loadMenuData()
          }
        })
        .catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg ? res.msg : res }).then(() => {})
        })
    },

    // 获取餐段
    loadTime() {
      const currentTimeId = this.$store.state.currentTimeId || ''
      const param = {}
      const date = this.meunList[this.menuIndex].date_format
      param.date = date
      param.canteen_id = this.canteen_id
      this.$store.state.isLoading = true
      getTime(param)
        .then((res) => {
          this.$store.state.isLoading = false
          if (!Array.isArray(res.data) || res.data.length == 0) {
            this.tabList = []
            return
          }
          const flag = res.data.findIndex(
            (v) => v.id == currentTimeId
          )
          if (flag == -1) {
            this.tabIndex = 0
            this.listParam.dishes_label = res.data[0].id
            this.listParam.label_id = res.data[0].id
          } else {
            this.tabIndex = flag
            this.listParam.dishes_label = currentTimeId
            this.listParam.label_id = currentTimeId
          }
          console.log('timeID', this.listParam.label_id)
          const tabList = []
          const label = this.$store.state.timeLabel || {}
          res.data.forEach((item) => {
            const v = {}
            v[item.id] = item.name
            v['bg_color'] = item.bg_color
            label[item.id] = v
            // console.log(label[item.id])
            const data = {}
            data.dishes_label = item.id
            data.title = item.name
            data.menu_id = item.menu_info && item.menu_info.id || item.menu_id
            data.is_sales_control = item.menu_info && item.menu_info.is_sales_control
            tabList.push(data)
          })
          // console.log('111', tabList, label)
          this.tabList = tabList
          this.label = label
          this.$store.state.timeLabel = label
          this.loadCategoryData()
        })
        .catch((res) => {
          this.loading = false
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg ? res.msg : res }).then(() => {})
        })
    },

    loadMenuData() {
      let menuIndex = this.$store.state.menuIndex || 0
      const param = {}
      param.status = '10'
      param.canteen_id = this.canteen_id
      this.$store.state.isLoading = true
      try {
        getMenu(param)
          .then((res) => {
            this.$store.state.isLoading = false
            if (!Array.isArray(res.data) || res.data.length == 0) {
              return
            }
            const meunList = res.data.map((v) => {
              const obj = {}
              obj.id = v.id
              obj.week = '周' + v.date_week_format
              obj.date = v.date_format.slice(5).split('-').join('月') + '日'
              obj.date_format = v.date_format
              return obj
            })
            if (menuIndex > meunList.length - 1) {
              menuIndex = 0
              this.$store.state.menuIndex = menuIndex
            }
            this.meunList = meunList
            this.menuIndex = menuIndex
            if (this.meunList && this.meunList.length > 0) {
              this.loadTime()
            }
          })
          .catch((res) => {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg ? res.msg : res }).then(() => {})
          })
      } catch (res) {
        console.log(res)
      }
    },

    loadCategoryData() {
      const param = {}
      param.label_id = this.listParam.dishes_label
      param.canteen_id = this.canteen_id
      this.$store.state.isLoading = true
      try {
        getCategory(param)
          .then((res) => {
            this.$store.state.isLoading = false
            const all = {
              id: '',
              name: '全部'
            }
            console.log('11111')
            const categoryList = res.data.rows.map((v) => {
              const obj = {}
              obj.id = v.id
              obj.name = v.name
              return obj
            })
            if (categoryList.length > 0) {
              categoryList.unshift(all)
            }
            this.categoryList = categoryList
            this.categoryIndex = 0
            if (categoryList.length > 0) {
              this.listParam.category_ids = []
              this.listParam.category_ids.push(categoryList[0].id)
            }
            if (this.meunList && this.meunList.length > 0) {
              this.listParam.menu_id = this.tabList[this.tabIndex].menu_id
              this.listParam.page = 0
              this.finished = false
              this.loadData(this.listParam)
            } else if (this.meunList && this.meunList.length == 0) {
              this.loading = false
            }
          })
          .catch((res) => {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg }).then(() => {})
          })
      } catch (res) {
        console.log(res)
      }
    },

    loadData(listParam) {
      if (!listParam.canteen_id) {
        Dialog.alert({ message: '请先选择食堂' })
        return
      }
      if (!listParam.page) {
        listParam.page = 1
      } else {
        listParam.page += 1
      }
      this.$store.state.isLoading = true
      getDishes(listParam)
        .then((res) => {
          this.$store.state.isLoading = false
          // this.listData = res.data.rows
          if (res.data.rows.length == 0) {
            this.finished = true
            return
          }
          this.listData = this.listData.concat(res.data.rows)
        })
        .catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg ? res.msg : res }).then(() => {})
        })
      this.loading = false
    },

    onMenuClick(e) {
      const index = e
      this.menuIndex = index
      this.listData = []
      this.loading = true
      this.loadTime()
    },

    onTabsClick(e, v) {
      console.log('下来了')
      const index = v
      const id = e
      var viewid = 'tab-'
      if (index <= 2) {
        viewid = 'tab-first'
      } else if (index > this.tabList.length - 3) {
        viewid = 'tab-last'
      } else {
        viewid += index - 2
      }
      this.tabIndex = index
      this.listData = []
      this.loading = true
      this.toTimeView = viewid
      this.listParam.dishes_label = id
      this.listParam.label_id = id
      this.loadCategoryData()
    },

    // 点击分类栏时触发
    onCategoryClick(event) {
      const index = event
      this.categoryIndex = index
      this.listData = []
      let divid = 'tab-'
      if (this.categoryIndex <= 2) {
        divid = 'header-first'
      } else if (this.categoryIndex > this.categoryList.length - 2) {
        divid += this.categoryList.length - 1
      } else {
        divid += index - 2
      }
      this.todiv = divid

      const item = this.categoryList[index]
      if (item.id === false) {
        delete this.listParam.id
      } else {
        this.listParam.category_ids = []
        this.listParam.category_ids.push(item.id)
      }
      if (this.meunList && this.meunList.length > 0) {
        this.listParam.menu_id = this.tabList[this.tabIndex].menu_id
        this.listParam.page = 0
        this.finished = false
        this.loadData(this.listParam)
      }
    },

    // 预定
    onReserve(e) {
      this.$store.state.menuIndex = this.menuIndex
      console.log(666, e)
      const id = this.tabList[this.tabIndex].menu_id
      this.currentMeunId = id
      this.currentTimeId = this.listParam.dishes_label
      const date = this.meunList[this.menuIndex].date
      const item = e
      // 计算价格
      const total = (this.total * 100 + item.price) / 100
      // 所有选中项
      const saveDishItems = this.saveDishItems
      if (saveDishItems[id]) {
        if (
          saveDishItems[id][this.listParam.dishes_label] &&
          saveDishItems[id][this.listParam.dishes_label].length >= 0
        ) {
          const flag = saveDishItems[id][this.listParam.dishes_label].some(
            (v) => v.id == item.id
          )
          if (!flag) {
            saveDishItems[id][this.listParam.dishes_label].push(item)
          }
        } else {
          saveDishItems[id][this.listParam.dishes_label] = []
          saveDishItems[id][this.listParam.dishes_label].push(item)
        }
      } else {
        saveDishItems[id] = {}
        // saveDishItems[id]['week'] = date + ' ' + week
        // 修改已选菜品日期显示
        saveDishItems[id]['week'] = date
        saveDishItems[id][this.listParam.dishes_label] = []
        saveDishItems[id][this.listParam.dishes_label].push(item)
      }
      // 计算量
      const amount = this.amount
      if (!amount[id + ' ' + this.listParam.dishes_label + ' ' + item.id]) {
        amount[id + ' ' + this.listParam.dishes_label + ' ' + item.id] = {
          menu_id: id,
          item: {
            dishes_id: item.id,
            quantity: 1
          }
        }
      } else {
        amount[id + ' ' + this.listParam.dishes_label + ' ' + item.id]['item'][
          'quantity'
        ] += 1
      }
      let sum = 0
      for (const i of Object.values(amount)) {
        sum += i['item']['quantity']
      }
      this.saveDishItems = saveDishItems
      this.amount = amount
      this.sum = sum
      this.total = total.toFixed(2)
      this.saveDishes()
      console.log('所有合计项', this.saveDishItems, this.amount, sum)
    },

    onToAccount() {
      if (this.sum == 0) {
        Dialog.alert({ message: '请先选择菜品' })
        return
      }
      this.show = true
    },

    onClose() {
      this.show = false
    },

    onSubmitBill() {
      this.saveDishes()
      this.$router.push({ name: 'Pay' })
    },

    onReduction(a, b, c) {
      const _this = this
      const key = a
      const price = b
      const label = c
      const amount = this.amount
      if (
        (amount[key] && amount[key]['item']['quantity'] > 1) ||
        (amount[key] && amount[key]['item']['quantity'] == 1 && !label)
      ) {
        _this.handleSaveParam(key, this.listParam.dishes_label, price)
      } else if (amount[key] && amount[key]['item']['quantity'] == 1 && label) {
        Dialog.alert({ message: '确定移除此菜品吗？' }).then(
          () => {
            _this.handleSaveParam(key, label, price)
            // console.log('res', this.saveDishItems, amount)
            if (Object.keys(this.saveDishItems).length == 0) {
              this.show = false
            }
          },
          () => {}
        )
      }
    },

    onAdd(e, f) {
      const key = e
      const price = f
      const amount = this.amount
      amount[key]['item']['quantity'] += 1
      let sum = 0
      for (const i of Object.values(amount)) {
        sum += i['item']['quantity']
      }
      const total = (this.total * 100 + price) / 100
      this.amount = amount
      this.sum = sum
      this.total = total.toFixed(2)
      this.saveDishes()
    },

    saveDishes() {
      if (!this.sum) {
        this.currentMeunId = ''
        this.currentTimeId = ''
      }
      this.$store.state.canteen_name = this.typeList[this.typeIndex]
      this.$store.state.saveDishItems = this.saveDishItems
      this.$store.state.total = this.total
      this.$store.state.sum = this.sum
      this.$store.state.amount = this.amount
      this.$store.state.currentMeunId = this.currentMeunId
      this.$store.state.currentTimeId = this.currentTimeId
    },

    handleSaveParam(key, label, price) {
      const amount = this.amount
      amount[key]['item']['quantity'] -= 1
      let sum = 0
      for (const i of Object.values(amount)) {
        sum += i['item']['quantity']
      }
      const total = (this.total * 100 - price) / 100
      this.amount = amount
      this.sum = sum
      this.total = total.toFixed(2)
      if (amount[key]['item']['quantity'] == 0) {
        const saveDishItems = this.saveDishItems
        const menu_id = amount[key]['menu_id']
        const dishes_id = amount[key]['item']['dishes_id']
        const dishes_index = saveDishItems[menu_id][label].findIndex(
          (v) => v.id == dishes_id
        )
        saveDishItems[menu_id][label].splice(dishes_index, 1)
        if (saveDishItems[menu_id][label].length == 0) {
          delete saveDishItems[menu_id]
        }
        delete amount[key]
        this.saveDishItems = saveDishItems
        this.amount = amount
      }
      this.saveDishes()
    },

    // 菜品弹框处理 start
    showDialog(item) {
      this.hasDialog = true
      this.showDetail = true
      this.currentItem = item
    },
    CloseDialog() {
      this.hasDialog = false
      this.showDetail = false
    }
    /** 菜品弹框处理 end*/
  }
}
</script>

<style lang="less" scoped>
@import "./reserve.less";
</style>
