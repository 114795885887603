<template>
  <div>
    <div class="container" style="padding: 20px;">
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="item"
        @click="onClick(index)"
      >
        <div class="title">{{ item.name }}</div>
        <img class="arrow" src="./images/arrow.png">
      </div>
    </div>
  </div>
</template>

<script>
import { getChildList } from './api/api'
import { Dialog } from 'vant'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      listData: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    // 加载数据
    loadData() {
      const param = {}
      param.code = '0'
      this.$store.state.isLoading = true
      getChildList(param)
        .then(res => {
          this.$store.state.isLoading = false
          this.listData = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
            this.$router.go(-1)
          })
        })
    },
    onClick(index) {
      const item = this.listData[index]
      this.$store.dispatch('abc/setAreaProvince', item)
      this.$router.push({ name: 'AbcAreaCity', query: { code: item.code }})
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  height: 45px;
  border-bottom: solid 1px #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.item .title {
  font-size: 14px;
  color: #333;
}
.item .arrow {
  margin-left: 10px;
  width: 8px;
  height: 14px;
}
</style>
