<template>
  <div class="container">
    <BaseList ref="list" class="content" :get-list-request="getListRequest">
      <template v-slot:default="listData">
        <van-tabs color="#FFCE0F" @click="handleChange">
          <van-tab v-for="(item,index) in categoryList" :key="index" :title="item.name">
            <div class="border" />
            <div class="content">
              <div v-for="(item2,index2) in listData.listData" :key="index2" class="item" @click="toDetail(item2)">
                <div class="title">{{ item2.title }}</div>
                <img class="arrow" src="../../assets/arrow.png" alt="详情">
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </template>
    </BaseList>
  </div>
</template>

<script>
import { getCategory, getList } from '@/api/guide'
import BaseList from '@/components/BaseList'
export default {
  components: {
    BaseList
  },
  data() {
    return {
      categoryList: [],
      current: '',
      loading: false
    }
  },
  mounted() {
    this.loadCategory()
  },
  methods: {
    getListRequest(param) {
      param.category_id = this.current
      return getList(param)
    },
    loadCategory() {
      this.$store.state.isLoading = true
      getCategory().then(res => {
        this.$store.state.isLoading = false
        if (res.data && res.data.length > 0) {
          const list = [{ id: '', name: '所有' }]
          res.data.forEach((item) => {
            list.push(item)
          })
          this.categoryList = list
        } else {
          const list = [{ id: '', name: '所有' }]
          this.categoryList = list
        }
      }).catch((res) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({ message: res.msg }).then(() => {
        })
      })
    },
    handleChange(e) {
      this.current = this.categoryList[e].id
      this.$refs.list.onRefresh()
    },
    toDetail(item2) {
      this.$router.push({ name: 'GuideDetail', query: { id: item2.id }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./guide.less";
</style>
