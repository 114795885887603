<template>
  <div v-if="info" class="container">
    <div class="info-content">
      <div class="content-header">
        <img
          v-if="info.status == 10"
          class="top-right"
          src="../../assets/header_top_wait.png"
        >
        <img
          v-if="info.status == 30"
          class="top-right"
          src="../../assets/header_top_error.png"
        >
        <img
          v-if="info.status == 40"
          class="top-right"
          src="../../assets/header_top.png"
        >
        <img
          v-if="info.status == 60"
          class="top-right"
          src="../../assets/header_top_cancel.png"
        >
        <div class="title">
          <img
            v-if="info.status == 10"
            class="title-img"
            src="../../assets/status_lid_wait.png"
          >
          <img
            v-if="info.status == 30"
            class="title-img"
            src="../../assets/status_lid_error.png"
          >
          <img
            v-if="info.status == 40"
            class="title-img"
            src="../../assets/status_lid.png"
          >
          <img
            v-if="info.status == 60"
            class="title-img"
            src="../../assets/status_lid_cancel.png"
          >
          <div class="status">{{ info.status_format }}</div>
        </div>
        <div
          :class="[
            'header-card',
            info.status == 10
              ? 'header-card_wait'
              : info.status == 30
                ? 'header-card_error'
                : info.status == 60
                  ? 'header-card_cancel'
                  : ''
          ]"
        >
          <img
            v-if="info.status == 10"
            class="meal-top-img"
            src="../../assets/meal_title_top_wait.png"
          >
          <img
            v-if="info.status == 30"
            class="meal-top-img"
            src="../../assets/meal_title_top_error.png"
          >
          <img
            v-if="info.status == 40"
            class="meal-top-img"
            src="../../assets/meal_title_top.png"
          >
          <img
            v-if="info.status == 60"
            class="meal-top-img"
            src="../../assets/meal_title_top_cancel.png"
          >
          <div class="card-left">
            <img
              v-if="info.status == 10"
              class="meal-intake-img"
              src="../../assets/meal_intake_wait.png"
            >
            <img
              v-if="info.status == 30"
              class="meal-intake-img"
              src="../../assets/meal_intake_error.png"
            >
            <img
              v-if="info.status == 40"
              class="meal-intake-img"
              src="../../assets/meal_intake.png"
            >
            <img
              v-if="info.status == 60"
              class="meal-intake-img"
              src="../../assets/meal_intake_cancel.png"
            >
            <div class="meal-intake-num">
              <div class="num">- -</div>
              <div>大卡</div>
            </div>
          </div>
          <div class="card-line" />
          <div class="card-right">
            <div class="card-right-top">
              <div class="card-right-left">
                <div>蛋白质</div>
                <div class="num-text dbz">
                  <span class="num">- -</span>
                  <span class="num-unit">克</span>
                </div>
              </div>
              <div class="card-line top-line" />
              <div class="card-right-left zf">
                <div>脂肪</div>
                <div class="num-text zf">
                  <span class="num">- -</span>
                  <span class="num-unit">克</span>
                </div>
              </div>
            </div>
            <div class="card-right-top bottom">
              <div class="card-right-left">
                <div>碳水化合物</div>
                <div class="num-text ts">
                  <span class="num">- -</span>
                  <span class="num-unit">克</span>
                </div>
              </div>
              <div class="card-line" />
              <div class="card-right-left">
                <div>膳食纤维</div>
                <div class="num-text ss">
                  <span class="num">- -</span>
                  <span class="num-unit">克</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-mid">
        <div class="title">
          <!-- <img class="img" src="../../assets/breakfast_bg.png"> -->
          <img
            v-if="info.label == 10"
            class="img"
            src="../../assets/breakfast_bg.png"
          >
          <img
            v-if="info.label == 20"
            class="img"
            src="../../assets/launch_bg.png"
          >
          <img
            v-if="info.label == 30"
            class="img"
            src="../../assets/dinner_bg.png"
          >
          <img
            v-if="info.label == 40"
            class="img"
            src="../../assets/snack_bg.png"
          >
          <div
            :class="[
              'label',
              info.label == 10
                ? 'breakfast'
                : info.label == 20
                  ? 'launch'
                  : info.label == 30
                    ? 'dinner'
                    : info.label == 40
                      ? 'snack'
                      : ''
            ]"
          >
            {{ info.label_format }}
          </div>
          <div class="canteen">{{ info.region && info.region.name }}</div>
        </div>
        <div class="content-detail">
          <div class="content-detail-item">
            <div
              v-for="(item, index) in info.detail"
              :key="index"
              class="detail-item"
            >
              <div class="item-left">
                <img
                  class="img"
                  :src="
                    item.dishes &&
                      item.dishes.cover_img &&
                      item.dishes.cover_img.file_url &&
                      item.dishes.cover_img.file_url.middle
                  "
                >
                <div class="item-left-title">
                  <div>{{ item.dishes && item.dishes.name }}</div>
                  <div class="unit">{{ item.subtotal_weigh }}克</div>
                </div>
              </div>
              <div class="item-right">¥{{ item.subtotal_price_format }}</div>
            </div>
          </div>
          <div class="content-account">
            <div class="content-account-product">
              <div>商品小计</div>
              <div>¥{{ info.total_price_format }}</div>
            </div>
            <!-- <div class="content-account-product account">
              <div>优惠</div>
              <div class="account-price">-¥1.8</div>
            </div> -->
          </div>
          <div class="content-detail-count">
            <span>共 {{ info.detail.length }} 件商品，实付：</span>
            <div class="count-price">
              <span>¥</span>
              <span class="price">{{ info.total_price_format }}</span>
            </div>
          </div>
        </div>
        <div class="content-mid-bg">
          <img class="img" src="../../assets/mid_bottom.png">
        </div>
      </div>
      <div class="content-bottom">
        <div class="content-bottom-detail">
          <div class="title">订单编号：</div>
          <div class="content">{{ info.order_no }}</div>
        </div>
        <div class="content-bottom-detail">
          <div class="title">支付方式：</div>
          <div class="content">饭卡支付</div>
        </div>
        <div class="content-bottom-detail">
          <div class="title">下单时间：</div>
          <div class="content">{{ info.created_at_format }}</div>
        </div>
        <div class="content-bottom-detail">
          <div class="title">支付时间：</div>
          <div class="content">{{ info.pay_at_format }}</div>
        </div>
        <div v-if="info.status == 60" class="content-bottom-detail">
          <div class="title">取消时间：</div>
          <div class="content">{{ info.cancel_at_format }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getInfo } from '@/api/orderWeigh'

export default {
  name: 'WeighOrderDetail',
  data() {
    return {
      id: 0,
      info: false
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const param = {}
      param.id = this.id
      console.log(111, param)
      this.$store.state.isLoading = true
      getInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (!res.data) return
          this.info = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./detail.less";
</style>
