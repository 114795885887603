/**
 * 补贴领取
 */
import { request } from '@/api/request'

// 补贴列表
export function getList(param = {}) {
  return request({
    url: '/customer_h5/user/get_subsidy_list',
    method: 'get',
    params: param
  })
}

// 领取补贴
export function receive(data = {}) {
  return request({
    url: '/customer_h5/user/receive_subsidy',
    method: 'post',
    data
  })
}
