<template>
  <div class="page">
    <div class="header">
      <!-- 月份下拉框部分 start -->
      <van-cell @click="showPicker()">
        <div class="time">
          <div class="top">
            <span class="month">{{ now }}</span>
            <img class="arrow" src="../../assets/record_header_arrow.png">
          </div>
        </div>
      </van-cell>
      <!-- 月份下拉框部分 end -->
    </div>
    <!--金额订单-->
    <div class="container">
      <BaseList ref="list" :get-list-request="getListRequest">
        <template v-slot:default="listData">
          <div v-for="(item,index) in listData.listData" :key="index" class="content">
            <div class="content-bottom">
              <div class="content-bottom-left">
                <div class="first-line">
                  <div v-html="item.status_html_format" />
                  <div class="canteen">{{ item.canteen.name }}</div>
                </div>
                <div>{{ item.order_at_format }}</div>
              </div>
              <div class="content-bottom-right">
                <div>¥ {{ item.total_price_format }}</div>
                <div v-if="item.status==30 && item.evaluation_status==10 && evaluationSetting" class="evaluate" @click="toEvaluateOrder(item.id,item.canteen.name)">待评价</div>
                <div v-if="item.status==30 && item.evaluation_status==20 && evaluationSetting" class="evaluate complete" @click="toEvaluateDetail(item.id)">查看评价</div>
              </div>
            </div>
          </div>
        </template>
      </BaseList>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-datetime-picker v-model="date" type="year-month" :min-date="mindate" :max-date="maxdate" title="选择年月" :formatter="formatter" format="" @cancel="onDialogClose()" @confirm="onDialogSure" />
    </van-popup>
  </div>
</template>

<script>
import { getAmount as getList } from '@/api/meal'
import BaseList from '@/components/BaseList'
export default {
  components: { BaseList },
  data() {
    return {
      // 加载中
      loading: false,
      evaluationSetting: false,
      now: '',
      show: false,
      mindate: false,
      maxdate: false,
      date: '',
      query_month: ''
    }
  },
  created() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    this.mindate = new Date(year - 3, 0)
    this.maxdate = new Date(year, month)
    this.date = now
    this.now = this.changeFormat(now)
    this.evaluationSetting = this.$store.getters.evaluationSetting
  },
  methods: {
    // 时间显示
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    // 打开时间选择
    showPicker() {
      this.show = true
    },
    // 关闭时间选择
    onDialogClose() {
      this.show = false
    },
    // 确认时间选择
    onDialogSure(val) {
      this.date = val
      this.now = this.changeFormat(val)
      this.show = false
      this.$refs.list.onRefresh()
    },
    // 时间格式转化
    timeFormat(time) {
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      return year + '-' + this.formatNumber(month)
    },
    // 时间格式转化 -- 页面显示
    changeFormat(time) {
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      return year + '年' + month + '月'
    },
    formatNumber(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    getListRequest(param) {
      param.query_month = this.timeFormat(this.date).split('-').join('')
      param.month = this.timeFormat(this.date)
      this.query_month = param.query_month
      return getList(param)
    },
    // 跳转选择菜品页面
    toEvaluateOrder(id, canteen) {
      // this.$router.push({ name: 'Evaluate', query: { id: id, orderType: 3 }})
      this.$router.push({ name: 'SelectDishes', query: { id: id, canteen: canteen, query_month: this.query_month }})
    },
    toEvaluateDetail(id) {
      this.$router.push({ name: 'EvaluateDetail', query: { id: id, orderType: 3, query_month: this.query_month }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./cash.less";
</style>
