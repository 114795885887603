<template>
  <div v-if="info" class="container">
    <div class="header">
      <div class="title">{{ info.title }}</div>
    </div>
    <div class="content">
      <div class="info">
        <div class="title" v-html="info.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from '@/api/news'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      info: false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      const param = {}
      param.id = this.$route.query.id
      getInfo(param).then(res => {
        this.$store.state.isLoading = false
        this.info = res.data
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./detail.less";
</style>
