<template>
  <div class="page">
    <div class="bg" />
    <div class="canteen">取餐地点：{{ canteen_name }}</div>

    <div v-if="saveDishItems && amount" class="card">
      <div class="items" :class="unwind ? 'is-unwind' : '' ">
        <div v-for="(value,key) in saveDishItems" :key="key">
          <div class="title">
            {{ value.week }}
            <div class="border" />
          </div>
          <div>
            <div v-for="(subvalue , subkey) in value" :key="subkey">
              <div v-if="subkey != 'week'">
                <div v-for="(item , index) in subvalue" :key="index" class="popup-items">
                  <div class="label" :style="{background:timeLabel[subkey]['bg_color']}">{{ timeLabel[subkey][subkey] }}</div>
                  <div class="desc">
                    <div class="name">
                      <span>{{ item.name }}</span>
                      <span class="price">￥{{ item.price_yuan }}</span>
                    </div>
                    <span class="ad ellipsis" />
                    <span class="amount">x{{ amount[key + ' ' + subkey + ' ' + item.id]['item']['quantity'] }}</span>
                    <!-- <span class="price">￥{{ item.price_yuan }}</span> -->
                    <div v-if="false" class="reserve">
                      <div class="symbol" @click="onReduction(key + ' ' + subkey + ' ' + item.id,item.price,subkey)">-</div>
                      <div class="amount">{{ amount[key + ' ' + subkey + ' ' + item.id]['item']['quantity'] }}</div>
                      <div class="symbol" @click="onAdd(key + ' ' + subkey + ' ' + item.id,item.price)">+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="unwind" @click="onUnwind()">
        <div>{{ unwind ? '收起' : '展开全部' }}</div>
        <div class="arrow" :class="unwind ? 'is-unwind' : ''" />
      </div>

      <div class="real-pay">
        <div class="real-pay-content">
          <span>合计金额：</span>
          <!-- <span>补贴金额：</span> -->
        </div>
        <div class="real-pay-content">
          <span>￥{{ total }}</span>
          <!-- <span>{{}}</span> -->
        </div>
      </div>

      <div class="border" />

      <div class="content">
        <!-- <spanarea wx:if="{{ !isShowdiv}}" focus="{{ onFocus }}" class="input" value="{{user_remark}}" placeholder="请输入订单备注..." placeholder-class="input-placholder" bindblur="onContentInput"></spanarea> -->
        <van-field
          v-model="user_remark"
          class="textarea"
          rows="2"
          type="textarea"
          maxlength="150"
          :autosize="{ minHeight: 260 }"
          placeholder="请输入订单备注..."
          show-word-limit
        />
      </div>
    </div>

    <div class="bottom-bar">
      <div class="bar-submit" @click="onSubmitPay">立即提交</div>
    </div>
  </div>
</template>

<script>
import { addRound } from '@/api/meal'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      unwind: true,
      info: false,
      // 是否加载数据中
      isLoading: false,
      student_user: false,
      saveDishItems: false,
      sum: 0,
      total: 0.00,
      amount: false,
      // 订单用户备注
      user_remark: '',
      onFocus: false,
      isShowView: true,
      // 食堂名称
      canteen_name: '',
      // 有无围餐
      showRound: false,
      // 餐段
      timeLabel: {}
    }
  },
  mounted() {
    this.canteen_name = this.$store.state.canteen_name
    this.saveDishItems = this.$store.state.saveDishItems
    this.total = this.$store.state.total
    this.sum = this.$store.state.sum
    this.amount = this.$store.state.amount
    this.showRound = this.$store.state.showRound
    this.timeLabel = this.$store.state.timeLabel
    if (!this.saveDishItems) {
      this.$router.go(-1)
      return
    }
  },
  methods: {
    onReduction(a, b, c) {
      const key = a
      const price = b
      const label = c
      const amount = this.amount
      if (amount[key] && amount[key]['item']['quantity'] > 1) {
        amount[key]['item']['quantity'] -= 1
        let sum = 0
        for (const i of Object.values(amount)) {
          sum += i['item']['quantity']
        }
        const total = (this.total * 100 - price) / 100
        this.amount = amount
        this.sum = sum
        this.total = total.toFixed(2)
        this.$store.state.amount = amount
        this.$store.state.sum = sum
        this.$store.state.total = total.toFixed(2)
      } else if (amount[key] && amount[key]['item']['quantity'] == 1 && label) {
        Dialog.alert({ message: '确定移除此菜品吗？' }, () => {
          amount[key]['item']['quantity'] -= 1
          let sum = 0
          for (const i of Object.values(amount)) {
            sum += i['item']['quantity']
          }
          const total = (this.total * 100 - price) / 100
          this.amount = amount
          this.sum = sum
          this.total = total.toFixed(2)
          this.$store.state.amount = amount
          this.$store.state.sum = sum
          this.$store.state.total = total.toFixed(2)
          const saveDishItems = this.saveDishItems
          const menu_id = amount[key]['menu_id']
          const dishes_id = amount[key]['item']['dishes_id']
          const dishes_index = saveDishItems[menu_id][label].findIndex(v => v.id == dishes_id)
          saveDishItems[menu_id][label].splice(dishes_index, 1)
          if (saveDishItems[menu_id][label].length == 0) {
            delete saveDishItems[menu_id]
          }
          this.saveDishItems = saveDishItems
          delete amount[key]
          this.$store.state.saveDishItems = this.saveDishItems
          this.$store.state.amount = amount
          console.log('res', saveDishItems, amount)
          if (Object.keys(saveDishItems).length == 0) {
            this.$router.go(-1)
          }
        }, () => {})
      }
    },
    onAdd(c, d) {
      const key = c
      const price = d
      const amount = this.amount
      amount[key]['item']['quantity'] += 1
      let sum = 0
      for (const i of Object.values(amount)) {
        sum += i['item']['quantity']
      }
      const total = (this.total * 100 + price) / 100
      this.amount = amount
      this.sum = sum
      this.total = total.toFixed(2)
      this.$store.state.amount = amount
      this.$store.state.sum = sum
      this.$store.state.total = total.toFixed(2)
    },

    onUnwind() {
      this.unwind = !this.unwind
    },

    // onContentInput(e) {
    //   this.setData({
    //     user_remark: e.detail.value,
    //     isShowView: true,
    //     onFocus: false
    //   });
    // },

    onShowTextarea() {
      this.isShowView = false
      this.onFocus = true
    },

    onSubmitPay() {
      Dialog.confirm({ title: '温馨提示', message: '确定提交此订单吗？' }).then(() => {
        const order = []
        for (const key in this.amount) {
          const flag = order.some(v => v.menu_id == this.amount[key].menu_id)
          const find = order.find(i => i.menu_id == this.amount[key].menu_id)
          const findIndex = order.findIndex(i => i.menu_id == this.amount[key].menu_id)
          if (!flag) {
            if (this.amount[key]['item']['quantity'] != 0) {
              const obj = {}
              obj.item = []
              obj.menu_id = this.amount[key]['menu_id']
              obj.item.push(this.amount[key]['item'])
              order.push(obj)
            }
          } else {
            if (this.amount[key]['item']['quantity'] != 0) {
              find['item'].push(this.amount[key]['item'])
              order.splice(findIndex, 1, find)
            }
          }
        }
        // console.log('参数', order, event.target.dataset.round ? '围餐' : '预定')
        const param = {}
        param.user_remark = this.user_remark
        param.order = order
        addRound(param).then((res) => {
          this.$store.state.canteen_name = false
          this.$store.state.saveDishItems = false
          this.$store.state.total = 0.00
          this.$store.state.sum = 0
          this.$store.state.amount = false
          this.$router.replace({ name: 'Pay_success' })
        })
          .catch((res) => {
            Dialog.alert({ message: res.msg })
          })
      })
        .catch(() => {})
    },

    onPrevent() {}
  }
}
</script>

<style lang="less" scoped>
@import "./pay.less";
</style>
