<template>
  <div v-if="einfo" class="signup_account">
    <div class="header">
      <img class="icon" src="./images/success.png">
      <div class="title" style="font-size: 20px">开户成功</div>
      <div class="title" style="margin-top: 20px;color: #999">电子账户</div>
      <div class="val e_account" style="font-size: 15px;color: #666;">{{ einfo.e_account }}</div>
    </div>
    <div class="content">
      <div class="label">绑定银行卡：</div>
      <div class="item">
        <div style="flex: 1;display: flex;flex-direction: column;">
          <div class="title">
            <span class="bank_name">{{ einfo.card_info && einfo.card_info.bank_name }}</span>
            <span v-if="einfo.is_changing" class="changing">变更中</span>
          </div>
          <div class="val bank_card_no">{{ einfo.card_no_format }}</div>
        </div>
        <div v-if="!einfo.is_changing" class="btn card_change" @click="onChange">更换</div>
      </div>
    </div>
    <div class="footer">
      <div class="btn onAuthorize" @click="onAuthorize">授权扣费</div>
      <div class="btn onCancel" @click="onDelete">注销</div>
    </div>
  </div>
</template>

<script>
import { getInfo, releaseApply } from './api/api'
export default {
  name: 'AbcSignupAccount',
  data() {
    return {
      einfo: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      getInfo().then((res) => {
        this.$store.state.isLoading = false
        this.einfo = res.data
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          this.$router.go(-1)
        })
      })
    },

    onChange() {
      this.$router.push({ name: 'AbcCardChange' })
    },
    onAuthorize() {
      this.$router.push({ name: 'AbcSignupAuthorize' })
    },
    onDelete() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定注销电子账户？'
      }).then(() => {
        this.$store.state.isLoading = true
        releaseApply().then((res) => {
          this.$store.state.isLoading = false
          this.$router.push({ name: 'AbcSignupReleaseCode', query: { token: res.data.token }})
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./signup_account.less";
</style>
