<template>
  <div class="container">
    <div class="content">
      <div class="item">
        <div class="title">验证码</div>
        <input
          v-model="code"
          type="number"
          class="input"
          placeholder="请输入验证码"
        >
        <div
          :class="['btn-code', time > 0 ? 'disable' : '']"
          @click="onCodeSend"
        >
          {{ codeSendBtnTitle }}
        </div>
      </div>

      <div
        :class="['btn-next', code.length == 0 ? 'disable' : '']"
        @click="onNext"
      >
        下一步
      </div>
    </div>
  </div>
</template>

<script>
import { verifyCodeTrust, signContractConfirm } from './api/api'
export default {
  name: 'AbcSignupAuthorizeCode',
  data() {
    return {
      timer: null,
      code: '',
      codeSendBtnTitle: '获取验证码',
      time: 0,
      order_no: ''
    }
  },
  created() {
    this.order_no = this.$route.query.order_no
    this.time = 60
    this.calculate()
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  methods: {
  // 计算发送时间
    calculate() {
      let time = this.time
      time--
      if (this.time <= 0) {
        this.time = 0
        this.codeSendBtnTitle = '获取验证码'
        if (this.timer) {
          clearTimeout(this.timer)
        }
        return
      } else {
        this.time = time
        this.codeSendBtnTitle = time + '秒'
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.calculate()
      }, 1000)
    },

    async onCodeSend() {
      if (this.time > 0) {
        return
      }
      this.$store.state.isLoading = true
      try {
        await verifyCodeTrust({ order_no: this.order_no })
        this.time = 60
        this.calculate()
      } catch (error) {
        this.$dialog.alert({
          message: error.msg || error
        })
      }
      this.$store.state.isLoading = false
    },

    async onNext() {
      if (this.code.length == 0) {
        return
      }
      this.$store.state.isLoading = true
      try {
        await signContractConfirm({ verify_code: this.code, order_no: this.order_no })
        this.$router.replace({ name: 'AbcSignupSuccess' })
      } catch (error) {
        this.$dialog.alert({
          message: error.msg || error
        })
      }
      this.$store.state.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "./signup_authorize_code.less";
</style>
