<template>
  <div class="container">
    <!-- tab栏 start-->
    <div class="tab">
      <div class="tab-content">
        <div
          v-for="(item, index) in tabList"
          :id="'tab-' + index"
          :key="index"
          :class="['item', tabIndex == index ? 'active' : '']"
          @click="onTabClick(index)"
        >
          {{ item.name }}
          <div v-if="tabIndex == index" class="tab-btn" />
        </div>
      </div>
    </div>
    <!-- tab栏 end-->
    <!-- 月份选择 start -->
    <div class="time" @click="showPicker()">
      <div class="top">
        <span class="year">{{ year ? year + "年" : "" }}</span>
        <span class="month">{{ month ? month + "月" : "" }}</span>
        <img class="arrow" src="../../assets/arrow_down.png">
      </div>
    </div>

    <div style="height: 115px;" />
    <!-- 月份选择 end -->

    <BaseList
      ref="list"
      v-slot:default="listData"
      class="content"
      :get-list-request="getListRequest"
    >
      <div class="content">
        <div
          v-for="(item, index) in listData.listData"
          :key="index"
          class="content-item"
          @click="toDetail(item.id)"
        >
          <img
            v-if="item.label == 10"
            class="img"
            src="../../assets/breakfast_bg.png"
          >
          <img
            v-if="item.label == 20"
            class="img"
            src="../../assets/launch_bg.png"
          >
          <img
            v-if="item.label == 30"
            class="img"
            src="../../assets/dinner_bg.png"
          >
          <img
            v-if="item.label == 40"
            class="img"
            src="../../assets/snack_bg.png"
          >
          <div class="item-title">
            <div class="title">
              <div
                :class="[
                  'label',
                  item.label == 10
                    ? 'breakfast'
                    : item.label == 20
                      ? 'launch'
                      : item.label == 30
                        ? 'dinner'
                        : item.label == 40
                          ? 'snack'
                          : ''
                ]"
              >
                {{ item.label_format }}
              </div>
              <div class="canteen">{{ item.region && item.region.name }}</div>
            </div>
            <div
              :class="[
                'title-status',
                item.status == 10
                  ? 'wait'
                  : item.status == 30
                    ? 'error'
                    : item.status == 60
                      ? 'cancel'
                      : ''
              ]"
            >
              {{ item.status_format }}
            </div>
          </div>
          <div class="content-mid">
            <div class="img-list">
              <img
                v-for="(subitem, sbindex) in item.detail"
                :key="sbindex"
                :class="['img-label', sbindex > 3 ? 'no-show' : '']"
                :src="
                  subitem.dishes &&
                    subitem.dishes.cover_img &&
                    subitem.dishes.cover_img.file_url &&
                    subitem.dishes.cover_img.file_url.middle
                "
              >
              <div v-if="item.detail.length > 4" class="img-mask">
                {{ item.detail.length - 3 }}+
              </div>
            </div>
            <div class="price">
              <div class="price-num">
                <span>¥</span>
                <span class="num">{{ item.total_price_format }}</span>
              </div>
              <div class="count">共{{ item.detail.length }}件</div>
            </div>
          </div>
          <div class="content-bottom">
            下单时间：{{ item.created_at_format }}
          </div>
        </div>
      </div>
    </BaseList>

    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="year-month"
        :min-date="start"
        :max-date="end"
        :formatter="formatter"
        @cancel="onDialogClose"
        @confirm="onDialogSure"
      />
    </van-popup>
  </div>
</template>

<script>
import BaseList from '@/components/BaseList.vue'
import { getList } from '@/api/orderWeigh'

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// const getWeekByDate = (dates) => {
//   const show_day = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
//   const date = new Date(dates)
//   date.setDate(date.getDate())
//   const day = date.getDay()
//   return show_day[day]
// }

export default {
  name: 'WeighOrder',
  components: { BaseList },
  data() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    return {
      tabList: [
        {
          status: '',
          name: '全部'
        },
        {
          status: 10,
          name: '待支付'
        },
        {
          status: 40,
          name: '已完成'
        },
        {
          status: 30,
          name: '支付失败'
        },
        {
          status: 60,
          name: '已取消'
        }
      ],
      tabIndex: 0,
      show: false,
      // 日期拾取器默认值
      date: now,
      start: new Date(year - 10, 0),
      end: new Date(year, month),
      // 日期显示年份
      year,
      // 日期显示月份
      month: month + 1,
      // 列表数据
      // mergeListData: {},
      status: 10,
      label: 10,
      imgArr: [
        { src: '1' },
        { src: '1' },
        { src: '1' },
        { src: '1' },
        { src: '1' }
      ]
    }
  },
  methods: {
    // 时间显示
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    onTabClick(index) {
      this.tabIndex = index
      const weighTabIndex = index
      this.$store.dispatch('save/CacheData', { weighTabIndex })
      this.$refs.list.onRefresh()
    },
    // 时间格式转化
    changeFormat(time) {
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      this.year = year
      this.month = month
      return year + '年' + month + '月'
    },

    // 时间格式转化
    timeFormat(time) {
      const year = time.getFullYear()
      let month = time.getMonth() + 1
      month = formatNumber(month)
      return year + '-' + month
    },

    // 打开时间选择
    showPicker() {
      this.show = true
    },
    // 关闭时间选择
    onDialogClose() {
      this.show = false
    },
    // 确认时间选择
    onDialogSure(date) {
      this.changeFormat(date)
      this.show = false
      this.$refs.list.onRefresh()
    },

    getListRequest(param) {
      const weighTabIndex = this.$store.getters.weighTabIndex || 0
      this.tabIndex = weighTabIndex
      const item = this.tabList[this.tabIndex]
      param.month = this.timeFormat(this.date)
      param.status = item.status
      return getList(param)
    },

    // requestResponse(listData) {
    //   // const mergeListData = {}
    //   // listData.forEach((item) => {
    //   //   const passArr = item.pass_at_format.split(' ')
    //   //   const dateArr = passArr[0].split('/')
    //   //   item.v_time = passArr[1]
    //   //   item.v_date = +dateArr[1] + '月' + +dateArr[2] + '日'
    //   //   item.v_week = getWeekByDate(item.pass_at_format)
    //   //   if (!mergeListData[item.v_date]) {
    //   //     mergeListData[item.v_date] = []
    //   //   }
    //   //   mergeListData[item.v_date].push(item)
    //   // })
    //   // this.mergeListData = mergeListData
    // },

    toDetail(id) {
      this.$router.push({ name: 'WeighOrderDetail', query: { id }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
