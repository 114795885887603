import Vue from 'vue'

import {
  Icon,
  Toast,
  Swipe,
  SwipeItem,
  Lazyload,
  Image,
  Loading,
  Popup,
  Field,
  Divider,
  List,
  DatetimePicker,
  Picker,
  Button,
  Switch,
  Dialog,
  ImagePreview,
  Uploader,
  Tab,
  Tabs,
  Tabbar,
  Cell,
  RadioGroup,
  Radio,
  TabbarItem,
  Sidebar,
  SidebarItem,
  Calendar,
  PullRefresh,
  Notify,
  Grid,
  GridItem,
  Checkbox,
  CheckboxGroup,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  Rate,
  Search,
  Sticky,
  Empty
} from 'vant'

Dialog.setDefaultOptions({
  confirmButtonColor: '#0055FF'
})

Vue.use(Icon)
Vue.use(Toast)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Divider)
Vue.use(List)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(ImagePreview)
Vue.use(Uploader)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tabbar)
Vue.use(Cell)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(TabbarItem)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Calendar)
Vue.use(PullRefresh)
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Col)
Vue.use(Row)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Rate)
Vue.use(Search)
Vue.use(Sticky)
Vue.use(Empty)

