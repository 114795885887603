<template>
  <div class="content">
    <div class="content-top">
      <div class="msg" style="z-index:300">{{ imgSrc ? ' ' : '请将脸部置于头像框中' }}</div>
      <div v-if="!imgSrc" style="margin-top: 20px">
        <video
          id="videoCamera"
          class="camera-box"
          :width="videoWidth"
          :height="videoHeight"
          autoplay
        />
        <canvas id="canvasCamera" style="display:none;" :width="videoWidth" :height="videoHeight" />
      </div>
      <div v-else class="camera">
        <img :src="imgSrc" alt class="camera-img">
        <view class="camera-box" />
      </div>
      <img v-if="!imgSrc" class="trun" src="../../assets/face_trun.png">
    </div>
    <div v-if="!imgSrc" class="footer">
      <button type="primary" class="btn" @click="setImage()">拍照</button>
    </div>
    <div v-else class="footer2">
      <div class="btn" style="color: rgba(0,0,0,0.65);" @click="reset()">重新采集</div>
      <div class="btn" style="color: rgba(0,0,0,0.9);" @click="sure()">确认</div>
    </div>
  </div>
</template>

<script>
import { editUser, editCustomer } from '@/api/user'
import { upload } from '@/api/common'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      videoWidth: 307,
      videoHeight: 307,
      imgSrc: '',
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      source: '',
      file: false
    }
  },
  mounted() {
    this.getCompetence()
    this.source = this.$route.query.source
    if (this.source == 'userInfo') {
      this.source = 'userInfo'
    }
  },
  destroyed() {
    this.stopNavigator()
  },
  methods: {
    // 调用权限（打开摄像头功能）
    getCompetence() {
      var _this = this
      this.thisCancas = document.getElementById('canvasCamera')
      this.thisContext = this.thisCancas.getContext('2d')
      console.log('123', this.thisContext)
      this.thisVideo = document.getElementById('videoCamera')
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            )
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: 'scaleX(-1)'
        }
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
          // 旧的浏览器可能没有srcObject
          if ('srcObject' in _this.thisVideo) {
            _this.thisVideo.srcObject = stream
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream)
          }
          _this.thisVideo.onloadedmetadata = function(e) {
            _this.thisVideo.play()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    //  绘制图片（拍照功能）

    setImage() {
      // var _this = this
      // 点击，canvas画图
      this.thisContext.drawImage(
        this.thisVideo,
        0,
        0,
        this.videoWidth,
        this.videoHeight
      )
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL('image/png')
      this.imgSrc = image
      var file = this.dataURLtoFile(image, 'avatar.jpg')
      this.file = file
    },
    // base64转文件
    dataURLtoFile(image, filename) {
      var arr = image.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 关闭摄像头
    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop()
    },
    // 重新采集
    reset() {
      this.stopNavigator()
      this.imgSrc = ''
      // this.getCompetence()
      this.$router.go(0)
    },
    sure() {
      this.$store.state.isLoading = true
      upload(this.file)
        .then(res => {
          const param = {}
          param.customer_user_id = this.$route.query.customer_user_id
          param.headimg = res.data.file_name
          if (this.source == 'userInfo') {
            editUser(param)
              .then(res2 => {
                this.$store.state.isLoading = false
                this.$router.go(-1)
              })
              .catch(res2 => {
                this.$store.state.isLoading = false
                this.$tool.alert(res2.msg)
              })
          } else {
            editCustomer(param)
              .then(res2 => {
                this.$store.state.isLoading = false
                this.$router.go(-2)
              })
              .catch(res2 => {
                this.$store.state.isLoading = false
                this.$tool.alert(res2.msg)
              })
          }
        })
        .catch(res => {
          this.$tool.hideLoading()
          Dialog.alert({ message: res.msg })
        })
    }
  }
}
</script>

<style lang="less" scoped>
// video,canvas,.tx_img{
//   -moz-transform:scaleX(-1);
//   -webkit-transform:scaleX(-1);
//   -o-transform:scaleX(-1);
//   transform:scaleX(-1);
// }
@import "./camera.less";
</style>
