require('babel-polyfill')
require('es6-promise').polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'

// 引用API文件
import api from './api/index.js'
// 挂载组件
import './utils/vant'
import '@/permission'
import Tool from './utils/tool' // 自定义工具方法

window.getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

// 将API方法绑定到全局
Vue.prototype.$api = api
Vue.prototype.$tool = Tool

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
