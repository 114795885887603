/* eslint-disable */
/**
 * 封装request请求，在调用api接口时使用
 */

import axios from 'axios'
import { Dialog } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { generateSign, generalBody } from '@/utils/sign'
import cookie from 'js-cookie'

const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
 
let getSignTimeStamp = () => {
  var rand = "";
  for(var i = 0; i < 3; i++){
    rand += Math.floor(Math.random() * 10);
  }
  return Date.now() + rand
}
let getSignCurrentUrl = (url) => {
 if (!url.startsWith('/')) {
   return url = '/' + url
 }
 return url
}

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000,
  withCredentials: false,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})
// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      // 让每个请求携带自定义token
      config.headers['Authorization'] = getToken()
    }
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params = { ...config.params, time_stamp: getSignTimeStamp(), current_url: config.baseURL + getSignCurrentUrl(config.url) }
      config.params.sign = generateSign(config.params)
    } else if (config.method == 'post') {
      //排除上传接口
      if (config.url != '/customer_h5/file/upload') {
        if (!config.data) {
          config.data = {}
        }
        config.data = { ...config.data, time_stamp: getSignTimeStamp(), current_url: config.baseURL + getSignCurrentUrl(config.url) }
        config.data.sign = generateSign(config.data)
        const data = config.data
        const params = generalBody(data, false, true)
        let form = new FormData()
        if (params) {
          for (const item of params) {
            form.append(item.key, item.val)
          }
        }
        config.data = form
      }
    }
    return config
  },
  error => {
    let data = {};
    data.code = -1;
    data.msg = '请求错误'
    Promise.reject(data)
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
  const code = res.data.code
  if (code == 10107) {
    Dialog.alert({message:res.data.msg}).then(() =>{
      if (window.navigator.userAgent.indexOf('Bankabc/Portal') > -1) {
        let backUrl = getQueryVariable('backUrl')
        if (backUrl) {
          backUrl = decodeURIComponent(backUrl)
          let backName = getQueryVariable('backName')
          let url = backUrl
          if (backName) {
            backName = decodeURIComponent(backName)
            url += '#/' + backName
          }

          window.location.href = url
        } else {
          const backUrl = cookie.get('abc_back_url')
          // console.log(backUrl)
          if (backUrl) {
            window.location.href = backUrl
          } else {
            window.history.go(-1)
          }
        }
      } else {
        window.history.go(-1);
      }
    })
  } else if (code == 10100 || code == 10101 || code == 10102 || code == 10103 || code == 10104) {
    Dialog.alert({message:res.data.msg}).then(() =>{
      if (code == 10100) {
        let redirect_uri = process.env.VUE_APP_REDIRECT_URI
        let backUrl = window.getQueryVariable('backUrl')
        if (backUrl) {
          backUrl = decodeURIComponent(backUrl)
          redirect_uri += '?backUrl=' + encodeURIComponent(backUrl)

          const backName = window.getQueryVariable('backName')
          if (backName) {
            redirect_uri += '&backName=' + backName
          }

          cookie.set('abc_back_url', backUrl + '#/' + backName)
        }

        // 对接G平台 start
        let auth_code = getQueryVariable('authcode')
        if (!auth_code) {
          auth_code = ''
        } else {
          const guide_abc = window.getQueryVariable('guide-abc') || ''
          redirect_uri += (redirect_uri.indexOf('?') > 0 ? '&' : '?') + 'auth_code=' + auth_code + '&guide_abc=' + guide_abc
        }
        // 对接G平台 end

        redirect_uri = encodeURIComponent(redirect_uri)
        // window.alert('跳转：[' + redirect_uri + ']')

        window.location.href = process.env.VUE_APP_BASE_API + '/customer_h5/auth/authorize?redirect_uri=' + redirect_uri + '&auth_code=' + auth_code
        return;
      }
      store.dispatch('user/resetToken').then(() => {
        location.reload()
        // 为了重新实例化vue-router对象 避免bug
      }).catch((err) => {
        location.reload()
      })
    })
    // return res
  } else {
    //loading隐藏
    return res
  }
},
error => {
  let msg = '';
  if (error.message) {
    msg = error.message
  } else {
    msg = error.toString()
  }
  Toast.fail(msg)
  throw error;
  let data = {};
  data.code = -1;
  data.msg = '响应错误:' + msg;
  return Promise.reject(data)
}
)

export default service
