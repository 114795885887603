<template>
  <div class="page">
    <div class="bg" />
    <div class="result">
      <img class="img" src="../../assets/pay_success.png">
      <div class="status">支付成功</div>
      <div class="tip">您可在主页-预定订单中查看订餐信息</div>
      <div class="btn" @click="onCheckReserve()">查看预定订单</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    onCheckReserve() {
      this.$router.replace({ name: 'History' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./pay_success.less";
</style>
