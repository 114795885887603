<template>
  <div class="container">
    <template v-if="listData.length > 0">
      <div v-for="item in listData" :key="item.id" class="card">
        <div class="card-title">
          <div class="title-name">{{ item.equipment && item.equipment.name }}</div>
          <div class="title-status">菜量不足</div>
        </div>
        <div class="card-content">
          <img class="dishes-icon" :src="item.cover_img && item.cover_img.file_url && item.cover_img.file_url.middle" alt="菜品图">
          <div class="dishes-desc">
            <div class="dishes-name">{{ item.name }}</div>
            <div class="dishes-remain">
              <span class="num red">{{ item.warn_weigh }}</span><span class="red"> 克</span><span> / 阈值 {{ item.warn_threshold }}克</span>
            </div>
          </div>
          <div class="close" @click="stopAudio(item.id)">关闭警报</div>
        </div>
      </div>
    </template>

    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import WebSocketClient from '../../utils/WebSocket'
import { getToken } from '@/utils/auth'
import { dishManagementCloseWarn } from '@/api/manage'

export default {
  data() {
    return {
      ws: null,
      audio: null,
      listData: []
    }
  },
  mounted() {
    const ws = new WebSocketClient(process.env.VUE_APP_CANTEEN_WEBSOCKET_API)
    this.ws = ws
    ws.on('_open', () => {
      ws.on('login', this.wsLogin)
      ws.send('login', { token: getToken() })
    })
    ws.connect()
  },
  beforeDestroy() {
    this.ws.close()
    if (this.audio != null) {
      this.audio.pause()
      this.audio = null
    }
  },
  methods: {
    wsLogin(res) {
      if (res.code == 0) {
        this.ws.heartBeat()
        this.ws.on('get_warn_list', this.getWarnList)
        this.ws.send('get_warn_list')
      }
    },

    getWarnList(res) {
      if (res.code == 0) {
        const { data } = res
        if (!data || data.length == 0) {
          this.listData = []
          if (this.audio != null) {
            this.audio.pause()
            this.audio = null
          }
          return
        }
        this.listData = data
        this.initAudio()
      } else {
        this.$toast({ message: res.msg })
      }
    },

    initAudio() {
      if (this.audio != null) return
      // const audio = new Audio(require('../../assets/denglu.ogg'))
      const audio = new Audio(require('../../assets/dishes-warn.mp3'))
      audio.volume = 0.8 // 设置音量（可选）
      audio.loop = true // 设置为循环播放（可选）
      audio.play().then(() => {
        this.audio = audio
      }).catch((error) =>
        this.$dialog.alert({
          message: '无法播放警报：' + error
        })
      )
    },

    stopAudio(id) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定关闭此菜品的警报吗？'
      }).then(async() => {
        this.$store.state.isLoading = true
        try {
          const res = await dishManagementCloseWarn({ ids: [id] })
          this.$toast({ message: res.msg })
        } catch (error) {
          this.$dialog.alert({
            message: error.msg || error
          })
        }
        this.$store.state.isLoading = false
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./dishes-warn");
</style>
