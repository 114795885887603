<template>
  <div>
    <div class="container" style="padding: 20px;">
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="item"
        @click="onClick(index)"
      >
        <div class="title">{{ item.name }}</div>
        <img class="arrow" src="./images/arrow.png">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getChildList } from './api/api'
import { Dialog } from 'vant'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      listData: []
    }
  },
  computed: {
    ...mapGetters(['abc_area_province'])
  },
  created() {
    this.init()
  },
  methods: {
    // 初始化数据
    init() {
      const province = this.abc_area_province
      if (!province) {
        Dialog.alert({
          message: '请先选择省'
        }).then(() => {
          this.$router.go(-1)
        })
        return
      }
      this.loadData()
    },
    // 加载数据
    loadData() {
      const code = this.$route.query.code
      const param = {}
      param.code = code
      this.$store.state.isLoading = true
      getChildList(param)
        .then((res) => {
          this.$store.dispatch('abc/setAreaCity', false)
          this.$store.dispatch('abc/CacheData', { abc_area_district: false })
          this.$store.state.isLoading = false
          this.listData = res.data
        })
        .catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
            this.$router.go(-1)
          })
        })
    },
    onClick(index) {
      const item = this.listData[index]
      this.$store.dispatch('abc/setAreaCity', item)
      this.$router.push({ name: 'AbcAreaDistrict', query: { code: item.code }})
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  height: 45px;
  border-bottom: solid 1px #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.item .title {
  font-size: 14px;
  color: #333;
}
.item .arrow {
  margin-left: 10px;
  width: 8px;
  height: 14px;
}
</style>
