<template>
  <div class="page">
    <div class="bg" />
    <div class="result">
      <img class="img" src="../../assets/pay_success.png">
      <div class="status">添加成功</div>
      <div class="tip">您可在主页-围餐订单中查看订餐信息</div>
      <div class="btn" @click="onCheckReserve()">查看围餐订单</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    onCheckReserve() {
      this.$router.replace({ name: 'RoundList' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./pay_success.less";
</style>
