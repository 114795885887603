/**
 * 用户
 */
import { request } from '@/api/request'

// 登录
export function login(openid) {
  return request({
    url: '/customer_h5/passport/login',
    method: 'post',
    data: { openid: openid }
  })
}

// 绑定手机
export function passportMobile(data) {
  return request({
    url: '/customer_h5/passport/mobile',
    method: 'post',
    data: data
  })
}

/**
 * 获取用户信息
 */
export function getInfo() {
  return request({
    url: '/customer_h5/user/get_info',
    method: 'get'
  })
}

// 获取顾客信息
export function getCustomer(data) {
  return request({
    url: '/customer_h5/user/get_customer_info',
    method: 'post',
    data: data
  })
}

// 编辑用户信息
export function editUser(data) {
  return request({
    url: '/customer_h5/user/edit',
    method: 'post',
    data: data
  })
}

// 编辑顾客信息
export function editCustomer(data) {
  return request({
    url: 'customer_h5/user/edit_customer',
    method: 'post',
    data: data
  })
}

// 编辑用户手机
export function editMobile(data) {
  return request({
    url: '/customer_h5/user/edit_mobile',
    method: 'post',
    data: data
  })
}

// 获取绑定用户列表

export function getBindAll() {
  return request({
    url: '/customer_h5/user/get_bind_all',
    method: 'get'
  })
}

// 设置绑定顾客用户
export function setBind(data) {
  return request({
    url: '/customer_h5/user/set_bind',
    method: 'post',
    data: data
  })
}

// 获取支付二维码
export function getqrcode() {
  return request({
    url: '/customer_h5/user/get_qrcode',
    method: 'get'
  })
}

// 获取支付二维码状态
export function getqrStatus(data) {
  return request({
    url: '/customer_h5/user/get_qrcode_status',
    method: 'post',
    data: data
  })
}

// 获取围餐订单配置
export function getOrder() {
  return request({
    url: '/customer_h5/dishes_order_round/get',
    method: 'get'
  })
}

// 获取围餐订单配置
export function getCompanyModule() {
  return request({
    url: '/customer_h5/company_module/get_list',
    method: 'get'
  })
}
