import { request } from '@/api/request'

// 菜品--列表
export function getDishesList(param = {}) {
  return request({
    url: '/customer_h5/dish_management/get_dishes_list',
    method: 'get',
    params: param
  })
}
// 菜品--详情
export function getInfo(param = {}) {
  return request({
    url: '/customer_h5/dish_management/get_info',
    method: 'get',
    params: param
  })
}

// 菜品--餐段列表
export function getTimeLabel(param = {}) {
  return request({
    url: '/customer_h5/dish_management/get_dishes_time_label',
    method: 'get',
    params: param
  })
}
// 菜品--菜品分类
export function getClassification(param = {}) {
  return request({
    url: '/customer_h5/dish_management/get_classification_list',
    method: 'get',
    params: param
  })
}

// 菜品--添加
export function dishesAdd(data) {
  return request({
    url: '/customer_h5/dish_management/add',
    method: 'post',
    data: data
  })
}
// 菜品--编辑
export function dishesEdit(data) {
  return request({
    url: '/customer_h5/dish_management/edit',
    method: 'post',
    data: data
  })
}
// 菜品--删除
export function cancel(data) {
  return request({
    url: '/customer_h5/dish_management/delete',
    method: 'post',
    data: data
  })
}
// 菜品--上架
export function dishseOnSale(data) {
  return request({
    url: '/customer_h5/dish_management/batch_on_sale',
    method: 'post',
    data: data
  })
}
export function dishesOffSale(data) {
  return request({
    url: '/customer_h5/dish_management/batch_off_sale',
    method: 'post',
    data: data
  })
}

// 选择食堂列表
export function getCanteenList(param = {}) {
  return request({
    url: '/customer_h5/dish_management/get_canteen_list',
    method: 'get',
    params: param
  })
}

// 菜量预警-关闭预警
export function dishManagementCloseWarn(data = {}) {
  return request({
    url: '/customer_h5/dishes_weigh/close_warn',
    method: 'post',
    data
  })
}

// 菜品-销售统计
export function getSaleStatistics(param = {}) {
  return request({
    url: '/customer_h5/sales_statistics/get_statics',
    method: 'get',
    params: param
  })
}
