import { request } from '@/api/request'

// 支付--饭卡充值
export function cardRecharge(data, pay_way) {
  const api = pay_way == 'abc_mobile_campus_card_recharge' ? '/customer_h5/pay_centre/abc_mobile_card_recharge' : '/customer_h5/pay_centre/wxsrc_card_recharge'
  return request({
    url: api,
    method: 'post',
    data: data
  })
}

// 支付-钱包充值【掌银H5场景】
export function h5Charge(pay_id) {
  return request({
    url: '/customer_h5/pay_centre/abc_mobile_card_recharge',
    method: 'post',
    data: { pay_id }
  })
}

// 支付--获取支付方式
export function getPayWay(param = {}) {
  return request({
    url: '/customer_h5/pay_centre/get_pay_way',
    method: 'get',
    params: param
  })
}

// 支付--获取支持电子账户的支付方式
export function getPayEWay(param = {}) {
  return request({
    url: '/customer_h5/pay_centre/get_e_account_pay_way',
    method: 'get',
    params: param
  })
}

// 支付--电子账户管理
export function getAccount(data) {
  return request({
    url: '/customer_h5/pay_centre/manage',
    method: 'post',
    data: data
  })
}

