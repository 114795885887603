<template>
  <div>
    <div v-if="listData.length>0" class="page">
      <div class="city">
        <span class="title">{{ canteen }}</span>
      </div>
      <div class="reserve">
        <!-- 左侧边栏start -->
        <div class="tabs">
          <div class="tab active">
            <span class="week">{{ info.date_format }}</span>
            <span class="day">周{{ info.date_week_format }}</span>
            <div class="active" />
          </div>
          <div style="height: 50px;" />
        </div>
        <!-- 左侧边栏end -->
        <div class="content">
          <div class="tab-bar">
            <div class="item active">
              <div>{{ info.label?info.label.name:'' }}</div>
              <div class="active" />
            </div>
          </div>
          <!-- 菜品分类start -->
          <div class="category">
            <div id="header-first" />
            <div
              v-for="(item, index) in categoryList"
              :key="index"
              class="item"
              :class="categoryIndex == index ? 'active' : ''"
              @click="onCategoryClick(index)"
            >
              {{ item.name }}
            </div>
            <div id="header-last" class="right" />
          </div>
          <!-- 菜品分类end -->
          <div v-if="categoryList[categoryIndex].id == -1">
            <div v-for="(item, index) in listData" :key="index" class="card">
              <div class="card-dishes-img">
                <img class="dishes-img" :src="item.cover_img.file_url.middle">
              </div>
              <div class="desc">
                <span class="desc-name">{{ item.name }}</span>
                <div class="ad ellipsis">{{ item.ad_words }}</div>
              </div>
              <div class="select-dishes" @click="selectDishes(item.id,item.category_id)">
                <div v-if="item.checked" class="select-dishes-hl" />
              </div>
            </div>
          </div>
          <div v-else-if="content && content[categoryList[categoryIndex].id]">
            <div v-for="(item, index) in content[categoryList[categoryIndex].id]" :key="index" class="card">
              <div class="card-dishes-img">
                <img class="dishes-img" :src="item.cover_img.file_url.middle">
              </div>
              <div class="desc">
                <span class="desc-name">{{ item.name }}</span>
                <div class="ad ellipsis">{{ item.ad_words }}</div>
              </div>
              <div class="select-dishes" @click="selectDishes(item.id,item.category_id)">
                <div v-if="item.checked" class="select-dishes-hl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="total">
          <div class="total-price">已选 {{ sum }} </div>
          <div class="to-account" @click="selectAll">
            <div class="select-all">
              <div v-if="allChecked" class="select-all-hl" />
            </div>
            <span>全选</span>
          </div>
        </div>
        <div :class="['dot',sum > 0 ? 'hasSelected' : ''] " @click="onSubmit">
          <div>选好了</div>
        </div>
      </div>
    </div>
    <div v-else class="list-info">
      <img src="../../assets/common_nodata_icon.png" class="icon-nodata">
      <div class="list-info-nodata">暂无可评价的菜品</div>
    </div>
  </div>
</template>
<script>
import { amountGetOrderDishes } from '@/api/meal'
import { Dialog } from 'vant'
export default {
  name: 'SelectDishes',
  components: { },
  data() {
    return {
      // 菜品分类
      categoryList: [],
      categoryIndex: 0,
      allChecked: false,
      sum: 0,
      listData: [],
      info: {},
      content: {},
      canteen: ''
    }
  },
  created() {
    this.canteen = this.$route.query.canteen
    this.getSelectDishes()
  },
  methods: {
    getSelectDishes() {
      const param = {}
      param.order_id = this.$route.query.id
      param.query_month = this.$route.query.query_month
      this.$store.state.isLoading = true
      amountGetOrderDishes(param)
        .then(res => {
          this.$store.state.isLoading = false
          const content = {}
          const categoryList = [{ name: '全部', id: -1 }]
          const listData = res.data ? res.data.dishes : []
          if (listData.length > 0) {
            listData.forEach((v) => {
              v.checked = false
              if (
                Object.keys(content).length > 0 &&
              Object.keys(content).some((j) => j == v.category_id)
              ) {
                content[v.category_id].push(v)
              } else {
                content[v.category_id] = [v]
              }
            })
            listData.forEach((item) => {
              const flag = categoryList.some((v) => v.id == item.category_id)
              if (!flag) {
                const data = {}
                data.name = item.category.name
                data.id = item.category_id
                categoryList.push(data)
              }
            })
          }
          this.info = res.data
          this.categoryList = categoryList
          this.content = content
          this.listData = listData
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg || res }).then(() => {})
        })
    },
    onCategoryClick(index) {
      this.categoryIndex = index
      this.$forceUpdate()
    },
    selectDishes(id, category_id) {
      // 选中全部
      if (this.categoryIndex == 0) {
        let sum = 0
        const items = this.listData
        const index = items.findIndex(v => v.id == id)
        items[index].checked = !items[index].checked
        const content = this.content
        for (const item in content) {
          for (const key of content[item]) {
            if (key.id == id) {
              content[item].checked = items[index].checked
            }
          }
        }
        const flag = items.some(v => v.checked == false)
        if (flag) {
          this.allChecked = false
        } else {
          this.allChecked = true
        }
        const selectItems = items.filter(v => v.checked == true)
        sum += selectItems.length
        this.listData = items
        this.content = content
        this.sum = sum
      // 选中遍历的分类
      } else {
        let sum = 0
        const items = this.content[category_id]
        const index = items.findIndex(v => v.id == id)
        items[index].checked = !items[index].checked
        const content = this.content
        const listData = this.listData
        content[category_id] = items
        const find = listData.find(v => v.id == id)
        const findIndex = listData.findIndex(v => v.id == id)
        find.checked = items[index].checked
        listData[findIndex] = find
        const flag = listData.some(v => v.checked == false)
        if (flag) {
          this.allChecked = false
        } else {
          this.allChecked = true
        }
        const selectItems = listData.filter(v => v.checked == true)
        sum += selectItems.length
        this.listData = listData
        this.content = content
        this.sum = sum
      }
      this.$forceUpdate()
    },
    // 全选
    selectAll() {
      this.allChecked = !this.allChecked
      const items = this.listData
      const content = this.content
      let sum = 0
      if (this.allChecked) {
        items.forEach((v) => {
          v.checked = true
        })
        for (const item in content) {
          content[item].checked = true
        }
      } else {
        items.forEach((v) => {
          v.checked = false
          for (const item in content) {
            content[item].checked = false
          }
        })
      }
      const selectItems = items.filter((v) => v.checked == true)
      sum += selectItems.length
      this.listData = items
      this.content = content
      this.sum = sum
      this.$forceUpdate()
    },
    onSubmit() {
      let flag = false
      let items = false
      flag = this.listData.some((v) => v.checked == true)
      if (!flag) {
        Dialog.alert({ message: '请选择需要评价的菜品' })
        return
      }
      items = this.listData.filter((v) => v.checked == true)
      console.log('选择的菜品', items)
      const selectDishes = []
      items.forEach(v => {
        v.checked = false
        v.evaluate = ''
        v.starNum = [0]
        v.starText = ['']
        v.currentWord = 0
        selectDishes.push(v)
      })
      const selectObj = { canteen: this.$route.query.canteen, order_id: this.$route.query.id, selectDishes: selectDishes }
      console.log(selectObj)
      this.$store.state.selectDishes = selectObj
      console.log(1111, this.$store.state.selectDishes)
      this.$router.push({ name: 'CashEvaluate', query: { query_month: this.$route.query.query_month }})
    }
  }
}
</script>
<style lang="less" scoped>
@import "./select-dishes.less";
</style>
