const getters = {
  token: state => state.user.token,
  customer_user_id: state => state.user.customer_user_id,
  payobj: state => state.user.payobj,
  is_open_reserve_dispatch: state => state.user.is_open_reserve_dispatch,
  is_open_reserve_dispatch_package_charges: state => state.user.is_open_reserve_dispatch_package_charges,
  reserve_dispatch_package_minimum_charges: state => state.user.reserve_dispatch_package_minimum_charges,
  reserve_dispatch_package_base_charges: state => state.user.reserve_dispatch_package_base_charges,
  province: state => state.save.province,
  city: state => state.save.city,
  area: state => state.save.area,
  abc_access_token: state => state.abc.abc_access_token,
  abc_param: state => state.abc.abc_param,
  abc_pay_way: state => state.abc.abc_pay_way,
  abc_signup_card_no: state => state.abc.abc_signup_card_no,
  abc_signup_mobile: state => state.abc.abc_signup_mobile,
  abc_area_province: state => state.abc.abc_area_province,
  abc_area_city: state => state.abc.abc_area_city,
  abc_area_district: state => state.abc.abc_area_district,
  abc_signup_card_address: state => state.abc.abc_signup_card_address,
  abc_idcard_front_filename: state => state.abc.abc_idcard_front_filename,
  abc_idcard_front_result: state => state.abc.abc_idcard_front_result,
  abc_idcard_back_filename: state => state.abc.abc_idcard_back_filename,
  abc_idcard_back_result: state => state.abc.abc_idcard_back_result,
  evaluationSetting: state => state.save.evaluationSetting,
  historyTabIndex: state => state.save.historyTabIndex,
  historyIcon: state => state.save.historyIcon,
  roundTabIndex: state => state.save.roundTabIndex,
  roundIcon: state => state.save.roundIcon,
  weighTabIndex: state => state.save.weighTabIndex
}
export default getters
