// 意见反馈
import { request } from '@/api/request'

// 列表
export function getList(param = {}) {
  return request({
    url: '/customer_h5/feedback/get_list',
    method: 'get',
    params: param
  })
}

// 详情
export function getInfo(param = {}) {
  return request({
    url: 'customer_h5/feedback/get_info',
    method: 'get',
    params: param
  })
}

// 取消
export function cancel(data = {}) {
  return request({
    url: '/customer_h5/feedback/cancel',
    method: 'post',
    data
  })
}
