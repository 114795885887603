<template>
  <!-- 饭卡充值 -->
  <div class="page">
    <div class="container">
      <!-- 头部 -->
      <div class="header">
        <div class="content">
          <img class="bg" src="../../assets/user_info_bg.png">
          <div v-if="info.bind_user_id > 0" class="identity">
            <div class="identify-content">
              <img class="identity-bg" src="../../assets/card_header_bg.png">
              <img class="identity-icon" src="../../assets/card_header_icon.png">
              <div class="identify-name">{{ info.bind_user_identity }}</div>
            </div>
          </div>
          <div v-if="info">
            <div class="center">
              <div class="user">
                <div class="amount">
                  <div class="tip">余额（元）</div>
                  <div class="money">{{ info.balance }}</div>
                </div>
                <div class="subsidy">
                  补贴余额：{{ info.subsidy_balance }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="isLoadingInfo && !info" class="loading">
            <van-loading size="24px">加载中...</van-loading>
          </div>
          <div v-if="!isLoadingInfo && !info" class="loading">
            加载失败，请<van-button color="#7232dd" plain hairline type="info" size="small" @click="loadData()">重试</van-button>
          </div>
        </div>
      </div>

      <div class="notice">
        <div v-if="isLoadingPayWay && !payWayList" class="loading">
          <van-loading size="24px">加载中...</van-loading>
        </div>
        <div v-if="!isLoadingPayWay && !payWayList" class="loading">
          加载失败，请<van-button color="#7232dd" plain hairline type="info" size="small" @click="loadPayWayData()">重试</van-button>
        </div>
        <!-- <div v-if="payWayList && payWayList.length == 1 && payWayList[0].pay_way == 'abc_bridge_app_prepay'" class="notice-content">
          <div>请打开农行掌银APP-缴费中心-服务费</div>
          <div>1、搜索“{{ info.school_name }}称”</div>
          <div>2、请入学号“{{ info.student_code }}”</div>
          <div>3、完成充值</div>
        </div> -->
      </div>
      <!-- 头部 end -->

      <div v-if="payWayList && !(payWayList.length == 1 && payWayList[0].pay_way == 'abc_bridge_app_prepay')">
        <div class="label">选择充值金额</div>
        <div class="grade">
          <div v-for="(item,index) in grades" :key="index" class="item" :class="index == gradeIndex ? 'active' : ''" @click="onGradeSelect(item,index)">
            <span class="title">￥{{ item }}</span>
          </div>
        </div>
        <div class="label-custom">自定义充值金额</div>
        <div class="custom">
          <div class="info">
            <!-- <text class="tip">￥</text> -->
            <input v-model="customGrade" class="input" type="digit" placeholder="请输入充值金额" placeholder-class="placeholder" @blur="onGradeCustomBlur()" @focus="onGradeCustomChange()">
          </div>
        </div>
      </div>

      <div v-if="false" class="menu">
        <div class="item">
          <div class="tip">账单</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
        <div class="item">
          <div class="tip">设置</div>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
      </div>

      <div v-if="payWayList && !(payWayList.length == 1 && payWayList[0].pay_way == 'abc_bridge_app_prepay')" class="footer">
        <div class="btn" @click="onShowPayWay()">立即充值</div>
      </div>
    </div>

    <div v-if="showPayWay" @click="onHidePayWay()">
      <div class="modal">
        <div class="modal-content" @click.stop>
          <div class="label">支付方式</div>
          <div class="info">
            <div v-for="(item,index) in payWayList" :key="index" class="item" :data-index="index" @click="onPayWaySelect(index)">
              <div class="title">{{ item.pay_name }}</div>

              <img v-if="payWaySelectIndex == index" class="radio" src="../../assets/payment_select_hl.png">
              <img v-if="payWaySelectIndex != index" class="radio" src="../../assets/payment_select.png">
            </div>
          </div>
          <div>
            <div class="btn" @click="onSubmitPay()">确定支付</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { getInfo } from '@/api/card'
import { cardRecharge, getPayWay, h5Charge } from '@/api/pay'

export default {
  data() {
    return {
      info: false, // 内容
      isLoadingInfo: true, // 是否在加载中
      grades: ['100.00', '200.00', '300.00'],
      gradeIndex: 0,
      customGrade: '',
      isLoadingPayWay: true, // 是否在加载支付方式
      payWayList: false, // 支付方式
      payWaySelectIndex: 0,
      showPayWay: false
    }
  },
  mounted() {
    this.loadData()
    this.loadPayWayData()
  },
  methods: {
  /**
   * 推荐充值选择
   */
    onGradeSelect(item, e) {
      const index = e
      this.gradeIndex = index
      this.customGrade = ''
    },
    onGradeCustomChange(e) {
      let value = this.customGrade
      // 删除
      const isBack = value.length < this.customGrade.length
      if (isBack) {
        const gradeIndex = value.length == 0 ? 0 : -1
        this.gradeIndex = gradeIndex
        this.customGrade = value
        return value
      }
      // 是否有小数点
      const findDecimal = value.indexOf('.'); console.log('findDecimal:' + findDecimal)
      const lastChat = value.charAt(value.length - 1)
      if (findDecimal !== -1 && (value.length - 1) != findDecimal && lastChat == '.') {
        return this.customGrade
      }
      if (findDecimal !== -1 && value.length - findDecimal > 3) {
        return this.customGrade
      }
      if (findDecimal !== -1 && findDecimal === 0) {
        value = '0' + value
      }
      this.gradeIndex = -1
      this.customGrade = value
      return value
    },
    onGradeCustomBlur(e) {
      const value = this.customGrade
      const arr = value.split('.')
      let integer = arr[0]
      let decimal = arr.length > 1 ? arr[1] : '00' // 小数
      if (integer.length == 0) {
        integer = '0'
      }
      integer = parseInt(integer) + ''
      if (decimal.length < 2) {
        for (let i = decimal.length; i <= 2; i++) {
          decimal += '0'
        }
      }
      if (decimal.length > 2) {
        decimal = decimal.substr(0, 2)
      }
      let customGrade = integer + '.' + decimal
      if (customGrade == '0.00') {
        customGrade = ''
      }
      this.customGrade = customGrade
      this.gradeIndex = this.customGrade.length == 0 ? 0 : -1
    },
    /**
   * 支付方式
   */
    onPayWaySelect(e) {
      const index = e
      this.payWaySelectIndex = index
    },
    onShowPayWay() {
      if (this.customGrade == '' && this.gradeIndex == -1) {
        Dialog.alert({ message: '请选择或输入充值金额' })
        return
      }
      if (!this.payWayList || this.payWayList.length == 0) {
        Dialog.alert({ message: '暂无可用的支付方式”' })
        return
      }
      if (this.payWayList.length == 1) {
        this.payWaySelectIndex = 0
        this.onSubmitPay()
        return
      }
      this.showPayWay = true
    },
    onHidePayWay() {
      this.showPayWay = false
    },

    // 确定支付
    onSubmitPay() {
      if (this.payWayList && this.payWayList[this.payWaySelectIndex].pay_way == 'abc_bridge_app_prepay') {
        this.handleAppPrepay(this.payWayList[this.payWaySelectIndex].id)
        return
      }
      if (this.info.is_bind_model == 1 && this.info.bind_user_id && this.info.bind_user_id != this.$route.query.user_id) {
        this.$tool.alert()
        Dialog.alert({ message: '抱歉，充值功能已被：' + this.info.bind_user_identity + ' 绑定，您不能进行充值!' })
        return
      }
      if (this.customGrade == '' && this.gradeIndex == -1) {
        Dialog.alert({ message: '请选择或输入充值金额' })
        return
      }
      const payway = this.payWayList[this.payWaySelectIndex]
      const amount = this.gradeIndex == -1 ? this.customGrade : this.grades[this.gradeIndex]
      const param = {}
      param.pay_id = payway.id
      param.amount = amount
      const payobj = {}
      payobj.card_pay_way = payway.pay_way
      payobj.card_recharge_amount = amount
      payobj.card_recharge_card = this.info
      this.$store.dispatch('user/setcharge', payobj)
      this.$store.state.isLoading = true
      const ua = window.navigator.userAgent.toLowerCase()
      const pay_scene = ua.match(/Bankabc/i) == 'bankabc' ? 'abc_mobile_campus_card_recharge' : 'weixin_jsapi_campus_card_recharge'
      cardRecharge(param, pay_scene).then((res) => {
        this.$store.state.isLoading = false
        this.gradeIndex = 0
        this.customGrade = ''
        this.showPayWay = false
        this.$tool.setStorage('pay_id', param.pay_id)
        // window.location.href = 'https://centre.stillideals.com/pay_h5/index/index?appid=' + res.data.appid + '&merchant_id=' + res.data.merchant_id + '&setting_number=' + res.data.setting_number + '&prepay_id=' + res.data.prepay_id + '&success_path=http%3a%2f%2fcanteenweb.stillideals.com%2frecharge_success'
        this.$router.push({ name: 'AbcIndex', query: {
          appid: res.data.appid,
          merchant_id: res.data.merchant_id,
          setting_number: res.data.setting_number,
          prepay_id: res.data.prepay_id,
          success_path: '/recharge_success'
        }})
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: '支付失败:' + res.msg })
      })
    },
    // 加载数据
    loadData() {
      this.$store.state.isLoading = true
      this.isLoadingInfo = true
      getInfo().then((res) => {
        this.$store.state.isLoading = false
        this.isLoadingInfo = false
        this.info = res.data
      }).catch((res) => {
        this.$store.state.isLoading = false
        this.isLoadingInfo = false
        Dialog.alert({
          message: res.msg
        }).then(() => {
          this.$router.go(-1)
        })
      })
    },
    loadPayWayData() {
      this.isLoadingPayWay = true
      const ua = window.navigator.userAgent.toLowerCase()
      const param = {}
      param.pay_scene = ua.match(/Bankabc/i) == 'bankabc' ? 'abc_mobile_campus_card_recharge' : 'weixin_jsapi_campus_card_recharge'
      this.$store.state.isLoading = true
      getPayWay(param).then((res) => {
        this.$store.state.isLoading = false
        this.isLoadingPayWay = false
        this.payWayList = res.data
        if (this.payWayList && this.payWayList.length == 1 && this.payWayList[0].pay_way == 'abc_bridge_app_prepay') {
          this.handleAppPrepay(this.payWayList[0].id)
          return
        }
      }).catch((res) => {
        this.$store.state.isLoading = false
        this.isLoadingPayWay = false
        Dialog.alert({
          message: res.msg
        }).then(() => {
          this.$router.go(-1)
        })
      })
    },
    handleAppPrepay(pay_id) {
      this.$store.state.isLoading = true
      h5Charge(pay_id).then((res) => {
        this.$store.state.isLoading = false
        const url = res.data.go_url
        // window.location.href = url
        this.$tool.goUrl(url)
      }).catch((res) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({ message: '支付失败:' + res.msg })
      })
    },
    onCatch() {

    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
