<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <!-- 金额订单选择菜品start -->
        <div class="select-dishes">
          <div class="text">要评价的菜品</div>
          <div class="change" @click="changeDishes">重选</div>
        </div>
        <!-- 金额订单选择菜品end -->
        <div
          v-for="(sub_item, sub_index) in listData"
          :key="sub_index"
          class="content-mid"
        >
          <!-- <div class="content-mid-line" /> -->
          <div class="content-mid-top">
            <div class="content-mid-left">
              <img class="content-mid-img" :src="sub_item.cover_img?sub_item.cover_img.file_url?sub_item.cover_img.file_url.middle:'':''">
              <div class="dishes-detail">
                <div class="dishes-detail-name">{{ sub_item.name }}</div>
                <div class="dishes-detail-ad ellipsis">
                  {{ sub_item.ad_words }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-mid-bottom">
            <div class="star">
              <div class="star-left-text">评分</div>
              <div v-for="(item, index) in starsBox" :key="'a' + index">
                <img
                  v-if="sub_item.starNum[0] >= item"
                  class="img"
                  src="../../assets/star_full.png"
                  @click="changePic(item, sub_item)"
                >
                <img
                  v-else
                  class="img"
                  src="../../assets/star.png"
                  @click="changePic(item, sub_item)"
                >
              </div>
              <div class="star-right-text">{{ sub_item.starText[0] }}</div>
            </div>
            <div class="evaluate-text">
              <van-field
                v-model="sub_item.evaluate"
                class="textarea"
                type="textarea"
                maxlength="30"
                placeholder="选填,用一句话简单描述你对该菜品的评价"
                show-word-limit
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" @click="submitEvaluate">提交</div>
  </div>
</template>

<script>
import { amountEvaluate } from '@/api/meal'
import { Dialog } from 'vant'
export default {
  name: 'CashEvaluate',
  components: {},
  data() {
    return {
      listData: [],
      // 加载中
      loading: false,
      id: 0,
      starsBox: [1, 2, 3, 4, 5],
      orderId: 0,
      canteen: ''
    }
  },
  created() {
    console.log(1111, this.$store.state.selectDishes)
    this.orderId = this.$store.state.selectDishes.order_id
    this.listData = this.$store.state.selectDishes.selectDishes
    this.canteen = this.$store.state.selectDishes.canteen
  },
  methods: {
    submitAmount(param) {
      amountEvaluate(param)
        .then(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: '评价成功' })
          this.$router.push({ name: 'Cash' })
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    // 点击星星评价
    changePic(num, sub_item) {
      const index = 0
      if (num == 1) {
        sub_item.starNum[index] = 1
        sub_item.starText[index] = '非常差'
      } else if (num == 2) {
        sub_item.starNum[index] = 2
        sub_item.starText[index] = '较差'
      } else if (num == 3) {
        sub_item.starNum[index] = 3
        sub_item.starText[index] = '一般'
      } else if (num == 4) {
        sub_item.starNum[index] = 4
        sub_item.starText[index] = '比较满意'
      } else if (num == 5) {
        sub_item.starNum[index] = 5
        sub_item.starText[index] = '非常满意'
      }
      this.$forceUpdate()
    },
    changeDishes() {
      const orderId = this.orderId
      const canteen = this.canteen
      this.$router.push({
        name: 'SelectDishes',
        query: { id: orderId, canteen: canteen,
          query_month: this.$route.query.query_month
        }
      })
    },
    submitEvaluate() {
      const param = {}
      param.evaluation = []
      param.order_id = this.orderId
      param.query_month = this.$route.query.query_month
      const detail = this.listData
      detail.forEach(v => {
        const obj = {}
        obj.dishes_id = v.id
        obj.comment = v.evaluate
        obj.score = v.starNum[0]
        param.evaluation.push(obj)
      })
      const flag = param.evaluation.some((v) => v.score == 0)
      if (flag) {
        Dialog.alert({ message: '请输入菜品评分' })
        return
      }
      this.submitAmount(param)
    }
  }
}
</script>

  <style lang="less" scoped>
  @import "./cash-evaluate.less";
  </style>

