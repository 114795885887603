var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-content"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:['item', _vm.tabIndex == index ? 'active' : ''],attrs:{"id":'tab-' + index},on:{"click":function($event){return _vm.onTabClick(index)}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.tabIndex == index)?_c('div',{staticClass:"tab-btn"}):_vm._e()])}),0)]),_c('div',{staticClass:"time",on:{"click":function($event){return _vm.showPicker()}}},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"year"},[_vm._v(_vm._s(_vm.year ? _vm.year + "年" : ""))]),_c('span',{staticClass:"month"},[_vm._v(_vm._s(_vm.month ? _vm.month + "月" : ""))]),_c('img',{staticClass:"arrow",attrs:{"src":require("../../assets/arrow_down.png")}})])]),_c('div',{staticStyle:{"height":"115px"}}),_c('BaseList',{ref:"list",staticClass:"content",attrs:{"get-list-request":_vm.getListRequest},scopedSlots:_vm._u([{key:"default",fn:function(listData){return [_c('div',{staticClass:"content"},_vm._l((listData.listData),function(item,index){return _c('div',{key:index,staticClass:"content-item",on:{"click":function($event){return _vm.toDetail(item.id)}}},[(item.label == 10)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/breakfast_bg.png")}}):_vm._e(),(item.label == 20)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/launch_bg.png")}}):_vm._e(),(item.label == 30)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/dinner_bg.png")}}):_vm._e(),(item.label == 40)?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/snack_bg.png")}}):_vm._e(),_c('div',{staticClass:"item-title"},[_c('div',{staticClass:"title"},[_c('div',{class:[
                'label',
                item.label == 10
                  ? 'breakfast'
                  : item.label == 20
                    ? 'launch'
                    : item.label == 30
                      ? 'dinner'
                      : item.label == 40
                        ? 'snack'
                        : ''
              ]},[_vm._v(" "+_vm._s(item.label_format)+" ")]),_c('div',{staticClass:"canteen"},[_vm._v(_vm._s(item.region && item.region.name))])]),_c('div',{class:[
              'title-status',
              item.status == 10
                ? 'wait'
                : item.status == 30
                  ? 'error'
                  : item.status == 60
                    ? 'cancel'
                    : ''
            ]},[_vm._v(" "+_vm._s(item.status_format)+" ")])]),_c('div',{staticClass:"content-mid"},[_c('div',{staticClass:"img-list"},[_vm._l((item.detail),function(subitem,sbindex){return _c('img',{key:sbindex,class:['img-label', sbindex > 3 ? 'no-show' : ''],attrs:{"src":subitem.dishes &&
                  subitem.dishes.cover_img &&
                  subitem.dishes.cover_img.file_url &&
                  subitem.dishes.cover_img.file_url.middle}})}),(item.detail.length > 4)?_c('div',{staticClass:"img-mask"},[_vm._v(" "+_vm._s(item.detail.length - 3)+"+ ")]):_vm._e()],2),_c('div',{staticClass:"price"},[_c('div',{staticClass:"price-num"},[_c('span',[_vm._v("¥")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(item.total_price_format))])]),_c('div',{staticClass:"count"},[_vm._v("共"+_vm._s(item.detail.length)+"件")])])]),_c('div',{staticClass:"content-bottom"},[_vm._v(" 下单时间："+_vm._s(item.created_at_format)+" ")])])}),0)]}}])}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-datetime-picker',{attrs:{"type":"year-month","min-date":_vm.start,"max-date":_vm.end,"formatter":_vm.formatter},on:{"cancel":_vm.onDialogClose,"confirm":_vm.onDialogSure},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }