const state = {
  globalData: {},
  province: false,
  city: false,
  area: false,
  historyTabIndex: 0,
  historyIcon: true,
  roundTabIndex: 0,
  roundIcon: true,
  weighTabIndex: 0
}

const mutations = {
  SAVE_ITEMS: (state, save) => {
    state.globalData = save
  },
  SAVE_PROVINCE: (state, save) => {
    state.province = save
  },
  SAVE_CITY: (state, save) => {
    state.city = save
  },
  SAVE_AREA: (state, save) => {
    state.area = save
  },
  SAVE_EVALUATION: (state, save) => {
    state.evaluationSetting = save
  },
  SAVE_SELECT_DISHES: (state, save) => {
    state.selectDishes = save
  },
  CACHE_DATA: (state, save = {}) => {
    for (const key in save) {
      if (Object.hasOwnProperty.call(save, key)) {
        const element = save[key]
        state[key] = element
      }
    }
  }
}

const actions = {
  saveDishItems({ commit }, save) {
    commit('SAVE_ITEMS', save)
  },
  saveEvaluation({ commit }, save) {
    commit('SAVE_EVALUATION', save)
  },
  saveSelectDishes({ commit }, save) {
    commit('SAVE_SELECT_DISHES', save)
  },
  Province({ commit }, save) {
    commit('SAVE_PROVINCE', save)
  },
  City({ commit }, save) {
    commit('SAVE_CITY', save)
  },
  Area({ commit }, save) {
    commit('SAVE_AREA', save)
  },
  // 清空省市区
  resetProvince({ commit }) {
    return new Promise(resolve => {
      commit('SAVE_PROVINCE', '')
      resolve()
    })
  },
  resetCity({ commit }) {
    return new Promise(resolve => {
      commit('SAVE_PROVINCE', '')
      resolve()
    })
  },
  resetArea({ commit }) {
    return new Promise(resolve => {
      commit('SAVE_PROVINCE', '')
      resolve()
    })
  },
  // 缓存数据
  CacheData({ commit }, save) {
    return new Promise(resolve => {
      commit('CACHE_DATA', save)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

