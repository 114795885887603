/**
 * 通用接口
 */
import { request } from '@/api/request'

// 文件上传
export function upload(file, filename = 'file.png', type) {
  const form = new FormData()
  // 通过append向form对象添加数据
  form.append('file', file, filename)
  if (type) {
    form.append('type', type)
  }
  return request({
    url: '/customer_h5/file/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: form
  })
}

// 验证码
export function passportCaptcha() {
  return request({
    url: '/admin/passport/captcha',
    method: 'get'
  })
}

// 发送短信
export function sendmsg(data) {
  return request({
    url: '/customer_h5/sms/create',
    method: 'post',
    data: data
  })
}

// 意见反馈
export function handle(data) {
  return request({
    url: '/customer_h5/feedback/create',
    method: 'post',
    data: data
  })
}

// 获取省市区街道数据
export function getPlace(param = {}) {
  return request({
    url: '/customer_h5/area/get_child_list',
    method: 'get',
    params: param
  })
}

// 获取单位配置
export function getSettings(param = {}) {
  return request({
    url: '/customer_h5/company/get_settings',
    method: 'get',
    params: param
  })
}

// 获取免密登陆token
export function getH5Token(data = {}) {
  return request({
    url: '/customer_h5/smart_park/get_h5_token',
    method: 'post',
    data
  })
}
