<template>
  <div class="page">
    <div class="container">
      <!-- 点餐订单 -->
      <BaseList ref="list" class="content" :get-list-request="getListRequest">
        <template v-slot:default="listData">
          <div v-for="(item,index) in listData.listData" :key="index" class="content">
            <div v-for="(sub_item,sub_index) in item.detail" :key="sub_index" class="content-mid">
              <div class="content-mid-line" />
              <div class="content-mid-top">
                <div class="content-mid-left">
                  <img class="content-mid-img" :src="sub_item.image_path">
                  <div class="dishes-detail">
                    <div class="dishes-detail-name">{{ sub_item.name }}</div>
                    <div class="dishes-detail-ad ellipsis">{{ sub_item.dishes.ad_words }}</div>
                    <div class="dishes-detail-quantity">x{{ sub_item.quantity }}</div>
                  </div>
                </div>
                <div class="dishes-money">¥ {{ sub_item.subtotal_price_format }}</div>
              </div>
            </div>
            <div class="content-bottom">
              <div class="content-bottom-left">
                <div v-html="item.status_html_format" />
                <div>{{ item.order_no }}</div>
                <div>{{ item.canteen.name }}</div>
                <div>{{ item.created_at_format }}</div>
              </div>
              <div class="content-bottom-right">
                <div>¥ {{ item.total_price_format }}</div>
                <div v-if="item.status==30 && item.evaluation_status==10 && evaluationSetting" class="evaluate" @click="toEvaluateOrder(item.id)">待评价</div>
                <div v-if="item.status==30 && item.evaluation_status==20 && evaluationSetting" class="evaluate complete" @click="toEvaluateDetail(item.id)">查看评价</div>
              </div>
            </div>
          </div>
        </template>
      </BaseList>
    </div>
  </div>
</template>

<script>
import { getScene as getList } from '@/api/meal'
import BaseList from '@/components/BaseList'
export default {
  components: { BaseList },
  data() {
    return {
      // 加载中
      loading: false,
      evaluationSetting: false
    }
  },
  created() {
    this.evaluationSetting = this.$store.getters.evaluationSetting
  },
  methods: {
    getListRequest(param) {
      return getList(param)
    },
    // 跳转评价页面
    toEvaluateOrder(id) {
      this.$router.push({ name: 'Evaluate', query: { id: id, orderType: 2 }})
      // this.$router.push({ name: 'EvaluateDetail', query: { id: id, orderType: 1 }})
    },
    toEvaluateDetail(id) {
      this.$router.push({ name: 'EvaluateDetail', query: { id: id, orderType: 2 }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./scene.less";
</style>
