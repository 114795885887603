// 电子账户
import { request } from '@/api/request'

// 校园卡-提现
export function cardOnlineWithdraw(data) {
  return request({
    url: '/customer_h5/card/online_withdraw',
    method: 'post',
    data
  })
}
