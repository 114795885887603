<template>
  <div class="container">
    <!-- 月份选择 start -->
    <div class="time">
      <div class="top" @click="showPicker">
        <span class="year">{{ year ? year + "年" : "" }}</span>
        <span class="month">{{ month ? month + "月" : "" }}</span>
        <span class="day">{{ day ? day + "日" : "" }}</span>
        <img class="arrow" src="../../assets/record_header_arrow.png">
      </div>
      <div class="type" @click="onShowTypePicker">
        <span class="title">{{ typeList[typeIndex].name }}</span>
        <img class="arrow" src="../../assets/record_header_arrow.png">
      </div>
    </div>
    <div style="height: 45px;" />
    <div class="content">
      <div class="header">
        <div class="header-top">
          <div class="header-left">
            <div class="text">消费金额（元）</div>
            <div class="num">
              <span class="bg-text">{{ headerData.real_price }}</span>
              <div class="num-bg" />
            </div>
          </div>
          <div class="header-left right">
            <div class="text">消费单数（笔）</div>
            <div class="num">{{ headerData.total_num }}</div>
          </div>
        </div>
        <div class="line" />
        <div class="btm">
          <span class="l">单笔平均消费（元）</span>
          <span class="r">{{ headerData.average }}</span>
        </div>
        <img
          class="header-bg-img"
          src="../../assets/sale_statistics_header_bg.png"
        >
      </div>
      <div class="content-statistics">
        <div class="chart-content">
          <div class="chart-title" />
          <div
            id="main"
            class="chart"
            :style="{ width: '100%', height: '300px' }"
          />
        </div>
        <div class="mid-title">
          <div class="left-line" />
          <div class="title-text">数据统计</div>
          <div class="left-line" />
        </div>
        <div class="table-content">
          <div class="table-title">
            <div class="text1">餐段</div>
            <div class="text2">总消费（元）</div>
            <div class="text2">补贴消费（元）</div>
          </div>
          <div v-for="(item,index) in statistical_list" :key="index" class="item">
            <div class="text1">{{ item.label }}</div>
            <div class="text2">{{ item.real_price }}</div>
            <div class="text2">{{ item.preferential_price }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        :min-date="start"
        :max-date="end"
        :formatter="formatter"
        @cancel="onDialogClose"
        @confirm="onDialogSure"
      />
    </van-popup>
    <!-- 选项 -->
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker show-toolbar :columns="typeList" :default-index="typeIndex" value-key="name" @cancel="onTypePickerCancel" @confirm="onTypePickerConfirm" />
    </van-popup>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { Dialog } from 'vant'
import { getSaleStatistics } from '@/api/manage'
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export default {
  name: 'SaleStatistics',
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    return {
      show: false,
      date: now,
      start: new Date(year - 3, month, 1),
      end: new Date(year, month, day),
      year,
      // 日期显示月份
      month: month + 1,
      day: day,
      chartData: false,
      allData: [], // 总消费（真实金额）
      payData: [], // 金额消费（ 实付金额）
      discountData: [], // 补贴金额（优惠金额）
      xAxis: [], // 横轴
      statistical_list: [], // 数据统计
      headerData: {}, // 头部统计
      showTypePicker: false,
      typeIndex: 0,
      typeList: [{
        id: '140',
        name: '称重订单'
      },
      {
        id: '30',
        name: '金额订单'
      },
      {
        id: '40',
        name: '预订订单'
      }],
      type: '',
      datetime: ''
    }
  },
  created() {
    this.datetime = this.timeFormat(this.date)
    this.type = this.typeList[this.typeIndex].id
    this.getData()
  },
  mounted() {

  },
  methods: {
    // 获取数据
    getData() {
      this.$store.state.isLoading = true
      const param = {}
      param.datetime = this.datetime
      param.type = this.type
      getSaleStatistics(param).then((res) => {
        this.$store.state.isLoading = false
        this.payData = res.data.statistical_chart[1]
        this.allData = res.data.statistical_chart[0]
        this.discountData = res.data.statistical_chart[2]
        this.xAxis = res.data.statistical_chart[3]
        this.statistical_list = res.data.statistical_list
        this.headerData = res.data.header
        this.myEcharts()
      })
        .catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg ? res.msg : res }).then(() => {})
        })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    showPicker() {
      this.show = true
    },
    // 时间格式转化
    changeFormat(time) {
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      this.year = year
      this.month = month
      this.day = day
      return year + '年' + month + '月' + day + '日'
    },
    // 时间格式转化
    timeFormat(time) {
      const year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()
      month = formatNumber(month)
      day = formatNumber(day)
      return year + '-' + month + '-' + day
    },
    // 关闭时间选择
    onDialogClose() {
      this.show = false
    },
    // 确认时间选择
    onDialogSure(date) {
      this.changeFormat(date)
      this.show = false
      this.datetime = this.timeFormat(this.date)
      this.getData()
    },
    myEcharts() {
      var myChart = echarts.init(document.getElementById('main'))
      // 配置图表
      var option
      const grid = {
        left: 0,
        right: 0,
        top: 60,
        bottom: 0,
        containLabel: true
      }
      const series = [
        {
          name: '总消费',
          type: 'custom',
          renderItem: function(params, api) {
            return ''
          },
          encode: {
            x: 0,
            y: 2
          },
          data: this.allData,
          itemStyle: {
            color: '#333'
          }
        },
        {
          name: '补贴消费',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: this.discountData,
          itemStyle: {
            // 使用渐变色
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#FC4E4E' }, // 渐变起始颜色
              { offset: 1, color: '#FF9191' } // 渐变结束颜色
            ])
          }
        },
        {
          name: '金额消费',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: this.payData,
          itemStyle: {
            // 使用渐变色
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#FFCD17' }, // 渐变起始颜色
              { offset: 1, color: '#FFE78E' } // 渐变结束颜色
            ])
          }
        }
      ]
      option = {
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            var result = params[0].name + '<br>' // 自定义tooltip的标题部分，这里使用了第一个系列的名称
            for (var i = 0; i < params.length; i++) {
              var marker = params[i].marker
              var seriesName = params[i].seriesName // 获取系列名称
              var dataValue = params[i].data // 获取数据值
              result += marker + seriesName + ': ' + dataValue + '元' + '<br/>' // 拼接系列名称和数据值
            }
            return result // 返回自定义的tooltip内容
          }
        },
        legend: {
          left: 'left',
          data: ['总消费金额', '金额消费', '补贴消费'],
          selected: {
            '金额消费': true,
            '补贴消费': true,
            '总消费金额': false // 隐藏图例项
          },
          itemWidth: 10, // 设置图例项的宽度
          itemHeight: 10
        },
        grid,
        yAxis: {
          type: 'value'
        },
        xAxis: {
          type: 'category',
          data: this.xAxis
        },
        series
      }

      option && myChart.setOption(option)
    },
    // 显示类型选项
    onShowTypePicker() {
      this.showTypePicker = true
    },
    // 取消类型选项
    onTypePickerCancel() {
      this.showTypePicker = false
    },
    // 类型选项选择
    onTypePickerConfirm(value, index) {
      this.typeIndex = index
      this.type = value.id
      this.showTypePicker = false
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
@import "./sale-statistics.less";
</style>
