<template>
  <div class="container">
    <div class="create-repair-area">
      <div class="create-repair-btn" @click="onCreateRepair">
        <span>问题反馈</span>
      </div>
    </div>

    <div class="tab-bar">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', index == tabIndex ? 'active' : '']"
        @click="onTabsClick(index)"
      >
        <div>{{ item.title }}</div>
        <div :class="[index == tabIndex ? 'active' : '']" />
      </div>
    </div>

    <BaseList ref="list" class="content" :get-list-request="getListRequest">
      <template v-slot:default="listData">
        <div
          v-for="(item, index) in listData.listData"
          :key="index"
          class="card initShadow"
        >
          <div class="card-title">
            <span>{{ item.title }}</span>
          </div>
          <div class="card-content">
            <div class="card-content-main">{{ item.content }}</div>
            <div class="card-content-pics">
              <van-image
                v-for="(subitem, subindex) in item.images"
                :key="subindex"
                width="69px"
                height="69px"
                fit="cover"
                :src="subitem.file_url && subitem.file_url.middle"
                class="image"
                @click="predivImage(subindex, item.images)"
              />
            </div>
          </div>
          <div class="repair-time">
            <span>反馈时间</span>
            <span>{{ item.created_at_format }}</span>
          </div>
          <div :class="['card-footer',tabList[tabIndex].status == 20?'card-footer-flex':'']">
            <div v-if="tabList[tabIndex].status == 20" class="card-footer-state" v-html="item.read_state_format_html" />
            <van-button
              v-if="tabList[tabIndex].status == 10"
              plain
              round
              type="info"
              class="card-footer-b-btn"
              @click="onCancel(item.id, tabList[tabIndex].status)"
            >取消反馈</van-button>
            <van-button
              v-if="tabList[tabIndex].status == 20"
              plain
              round
              type="warning"
              class="card-footer-o-btn"
              @click="onCheckDetail(item.id)"
            >查看详情</van-button>
          </div>
        </div>
      </template>
    </BaseList>
  </div>
</template>

<script>
import BaseList from '@/components/BaseList'
import { getList, cancel } from '@/api/feedback'

export default {
  name: 'FeedbackList',
  components: {
    BaseList
  },
  data() {
    return {
      tabList: [
        { status: 10, title: '待处理' },
        { status: 20, title: '已处理' }
      ],
      tabIndex: 0
    }
  },
  methods: {
    getListRequest(param) {
      param.status = this.tabList[this.tabIndex]['status']
      return getList(param)
    },

    onTabsClick(index) {
      this.tabIndex = index
      this.$refs.list.onRefresh()
    },

    predivImage(index, urls) {
      const imgList = urls.map(v => v.file_url && v.file_url.middle || '')
      this.$ImagePreview({
        images: imgList,
        startPosition: index
      })
    },

    onCancel(id, status) {
      this.$dialog.confirm({ title: '温馨提示', message: '取消反馈后反馈信息不可查， 确认取消吗？' }).then(async() => {
        this.$store.state.isLoading = true
        try {
          await cancel({ id })
          this.$refs.list.onRefresh()
          this.$toast({ message: '取消反馈成功' })
        } catch (error) {
          this.$dialog.alert({ message: error.msg || error })
        }
        this.$store.state.isLoading = false
      }).catch(() => {})
    },

    onCheckDetail(id) {
      this.$router.push({ name: 'FeedbackDetail', query: { id }})
    },

    onCreateRepair() {
      this.$router.push({ name: 'Feedback' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./list.less";
</style>
