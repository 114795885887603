<template>
  <div>
    <div v-if="isABC && native" class="content-top">
      <div v-if="router_name && router_name != 'Home' && router_name != 'GuideABC'" class="back" @click="Backstep()">
        <img src="./assets/back.png" class="back_icon">
      </div>
      <div v-if="router_name && router_name == 'Home' && back_url" class="back" style="font-size: 14px;color: #01AAED;padding-left:10px;display: flex;flex-direction: row;align-items: center;" @click="Backstep2()">
        <img src="./assets/back.png" class="back_icon" style="margin-left:0;">返回食堂列表
      </div>
      <div class="nav-title">
        {{ route_title }}
      </div>
    </div>

    <div v-if="router_name == 'Home'" class="content-top custom">
      <div style="color: #FFCE0F;" @click="handler">
        <img src="./assets/arrow.png" class="back_icon" style="margin-left:0;">
        返回
      </div>
      <div>智慧食堂列表</div>
    </div>

    <loading v-show="isLoading" />
    <div id="app">
      <router-view />
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import loading from './components/loading'
import cookie from 'js-cookie'

const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
export default {
  name: 'App',
  data() {
    return {
      isABC: false,
      router_name: 'Home',
      route_title: '智慧食堂',
      back_url: false,
      native: true
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  created() {
    const ua = window.navigator.userAgent.toLowerCase()
    this.isABC = ua.match(/Bankabc/i) == 'bankabc'
    if (window.navigator.userAgent.indexOf('Bankabc/Portal') > -1) {
      console.log('back', this.back_url)
      this.native = false
      // 只有在新容器里才能调用AlipayJSBridge相关方法
      document.addEventListener('back', this.handler, false)
    } else {
      this.native = true
    // alert('当前页面在旧容器');
    }
    let backUrl = getQueryVariable('backUrl')
    if (backUrl) {
      backUrl = decodeURIComponent(backUrl)
    }
    this.back_url = backUrl
    console.log('back_url', backUrl, getQueryVariable('backName'))
  },
  mounted() {

  },
  computed: {
    ...mapState(['isLoading'])
  },
  components: {
    loading
  },
  watch: {
    $route(to, from) {
      this.router_name = this.$route.name
      this.route_title = this.$route.meta.title
      this.$forceUpdate()
    }

  },
  methods: {
    handler(e) {
      e && e.preventDefault && e.preventDefault()
      if (this.router_name && this.router_name == 'Recharge_success') {
        window.location.href = process.env.VUE_APP_REDIRECT_URI
      } else if (this.router_name && this.router_name != 'Home') {
        if (this.router_name == 'GuideABC') {
          return
        }
        this.$router.go(-1)
      } else if (this.router_name && this.router_name == 'Home') {
        if (this.back_url) {
          const backUrl = decodeURIComponent(this.back_url)
          let backName = getQueryVariable('backName')
          let url = backUrl
          if (backName) {
            backName = decodeURIComponent(backName)
            url += '#/' + backName
          }
          console.log('back_url_6.0', url, 'backName', backName)
          // window.alert('返回：[' + url + ']')
          window.location.href = url
        } else {
          // window.alert('返回：' + (cookie.get('abc_back_url') || 'go-1'))
          const backUrl = cookie.get('abc_back_url')
          if (backUrl) {
            window.location.href = backUrl
          } else {
            window.history.go(-1)
          }
        }
      }
    },

    Backstep() {
      if (this.router_name && this.router_name == 'Recharge_success') {
        window.location.href = process.env.VUE_APP_REDIRECT_URI
      } else if (this.router_name && this.router_name != 'Home') {
        this.$router.go(-1)
      }
    },
    Backstep2() {
      console.log('Backstep2', this.back_url)
      if (this.router_name && this.router_name == 'Home') {
        if (this.back_url) {
          const backUrl = decodeURIComponent(this.back_url)
          let backName = getQueryVariable('backName')
          let url = backUrl
          if (backName) {
            backName = decodeURIComponent(backName)
            url += '#/' + backName
          }
          // window.alert('返回：[' + url + ']')
          window.location.href = url
        } else {
          const backUrl = cookie.get('abc_back_url')
          if (backUrl) {
            window.location.href = backUrl
          } else {
            window.history.go(-1)
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
   ::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
.loading {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  padding-top: 5.333333rem;
  z-index: 10;
  transform: translateZ(10px);
}
.content-top{
  display: flex;
  background: #EFEFEF;
  height: 44px;
  align-items: center;
  justify-content: center;

  &.custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    font-size: 12px;
    color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    border-radius:6px;
    padding: 0 10px 0 15px;
    margin: 5px 5px 0;

    .back_icon {
      width: 6px;
      height: 12px;
      margin-right: 6px;
      transform:rotate(180deg);
      vertical-align: middle;
    }
  }
}
.nav-title{
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.back{
  z-index: 10;
  transform: translateZ(10px);
  position: absolute;
  top: 0;
  left: 0;
  height: 44px;
  line-height: 37px;
}
.back_icon{
  margin-left: 15px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
</style>
