<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    console.log('created', this.$route)
  }
}
</script>
