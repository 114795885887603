/**
 * token相关文件（获取、保存、移除）
 * 使用js-cookie插件，保存token字段到cookie
 */

import Cookies from 'js-cookie'

const TokenKey = 'vue_rent_token'
const Customer_user_id = 'vue_id'
const PayObj = {
  card_recharge_card: { name: 'vue_name' },
  card_recharge_amount: 'vue_amount',
  card_pay_way: 'vue_payway'
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCusid() {
  return Cookies.get(Customer_user_id)
}

export function setCusid(customer_user_id) {
  return Cookies.set(Customer_user_id, customer_user_id)
}

export function setPayObj(payobj) {
  return Cookies.set(PayObj, payobj)
}

export function getPayObj() {
  return Cookies.get(PayObj)
}
