<template>
  <div class="page">
    <!--充值结果页面-->
    <div class="container">
      <!-- 背景图 -->
      <div class="top">
        <img class="icon" src="../../assets/pay_success.png">
      </div>
      <div class="msg">
        <div class="item">{{ pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ? '转入' : '您已成功充值' }}{{ amount }}元</div>
        <div class="item">至账号为:{{ card.name }} ID：{{ card.number }}的饭卡中</div>
        <div class="item">{{ pay_way == 'wxapp_abc_eaccount_trust_binding_pay' ? '转入提交成功，具体到账时间以银行为准' : '实际到账时间可能略有延迟' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amount: '',
      card: false,
      pay_way: ''
    }
  },
  mounted() {
    const payobj = JSON.parse(this.$store.getters.payobj)
    this.amount = payobj.card_recharge_amount
    this.card = payobj.card_recharge_card
    this.pay_way = payobj.card_pay_way
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
@import "./recharge_success.less";
</style>
