import { login } from '@/api/user'
import { getToken, setToken, removeToken, getCusid, setCusid, getPayObj, setPayObj } from '@/utils/auth'
import { getOpenid } from '@/utils/index'
import cookie from 'js-cookie'
const state = {
  token: getToken(),
  customer_user_id: getCusid(),
  payobj: getPayObj(),
  is_open_reserve_dispatch: false,
  is_open_reserve_dispatch_package_charges: false,
  reserve_dispatch_package_minimum_charges: false,
  reserve_dispatch_package_base_charges: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_CUSID: (state, customer_user_id) => {
    state.customer_user_id = customer_user_id
  },
  SET_RESERVEDISPATCH: (state, data) => {
    state.is_open_reserve_dispatch = data.is_open_reserve_dispatch == 1
    state.is_open_reserve_dispatch_package_charges = data.is_open_reserve_dispatch_package_charges == 1
    state.reserve_dispatch_package_minimum_charges = data.reserve_dispatch_package_minimum_charges
    state.reserve_dispatch_package_base_charges = data.reserve_dispatch_package_base_charges > 0 ? data.reserve_dispatch_package_base_charges : 0
  },
  SET_PAYOBJ: (state, payobj) => {
    state.payobj = payobj
  }
}

const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

const actions = {
  // 用户登录
  async login({ commit }) {
    const token = getToken()
    if (token) {
      return Promise.resolve({ code: 0 })
    }
    const openid = getOpenid('openid') || cookie.get('openid')
    if (!openid || openid == null) {
      let redirect_uri = process.env.VUE_APP_REDIRECT_URI
      let backUrl = getQueryVariable('backUrl')
      if (backUrl) {
        backUrl = decodeURIComponent(backUrl)
        redirect_uri += '?backUrl=' + encodeURIComponent(backUrl)

        const backName = getQueryVariable('backName')
        if (backName) {
          redirect_uri += '&backName=' + backName
        }

        cookie.set('abc_back_url', backUrl + '#/' + backName)
      }

      // 对接G平台 start
      let auth_code = getQueryVariable('authcode')
      if (!auth_code) {
        auth_code = ''
      } else {
        redirect_uri += (redirect_uri.indexOf('?') > 0 ? '&' : '?') + 'auth_code=' + auth_code
      }
      // 对接G平台 end

      redirect_uri = encodeURIComponent(redirect_uri)
      // window.alert('跳转：[' + redirect_uri + ']')

      window.location.href = process.env.VUE_APP_BASE_API + '/customer_h5/auth/authorize?redirect_uri=' + redirect_uri + '&auth_code=' + auth_code
      return
    }
    return new Promise((resolve, reject) => {
      // 登录请求
      login(openid).then(res => {
        // 自动跳转
        // if (getQueryVariable('authcode')) {
        //   window.location.href = process.env.VUE_APP_REDIRECT_URI + '/guide-abc'
        //   resolve()
        // }
        const { data } = res
        commit('SET_TOKEN', data.access_token)
        commit('SET_CUSID', data.customer_user_id)
        commit('SET_RESERVEDISPATCH', data)
        setToken(data.access_token)
        setCusid(data.customer_user_id)
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },

  // 改变customer_user_id
  changecusid({ commit }, customer_user_id) {
    return new Promise(resolve => {
      commit('SET_CUSID', customer_user_id)
    })
  },

  // 把充值的信息存起来
  setcharge({ commit }, payobj) {
    return new Promise(resolve => {
      commit('SET_PAYOBJ', payobj)
      setPayObj(payobj)
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
