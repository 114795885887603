import { request } from '@/api/request'

// 列表
export function getList(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_weigh/get_list',
    method: 'get',
    params: param
  })
}

// 详情
export function getInfo(param = {}) {
  return request({
    url: '/customer_h5/dishes_order_weigh/get_info',
    method: 'get',
    params: param
  })
}
