<template>
  <div class="container">
    <div class="label">转出方式</div>
    <div class="header">
      <div class="name">{{ info.card_info && info.card_info.bank_name }}</div>
      <div class="account">{{ info.card_no_format }}</div>
    </div>
    <div class="label">转出金额</div>
    <div class="content">
      <div class="amount">
        <div class="tip">￥</div>
        <input v-model="amount" type="digit" class="input" @input="onInput" @blur="onBlur">
      </div>
    </div>
    <div class="footer">
      <!-- <div :class="['btn', amount.length == 0 ? 'disabled' : '']" @click="onSubmit">转出</div> -->
      <div :class="['btn', amount.length == 0 ? 'disabled' : '']" @click="handleSubmit">转出</div>
    </div>
  </div>
</template>

<script>
import { getInfo, trustBindingWithdraw, bridgeBindingWithdraw, transferOut } from './api/api'
import { cardOnlineWithdraw } from '@/api/abc'
export default {
  name: 'AbcAccountOut',
  data() {
    return {
      info: false,
      amount: '',
      pay_way: '' // 支付方式
    }
  },
  created() {
    this.pay_way = this.$route.query.pay_way
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.state.isLoading = true
      getInfo().then((res) => {
        this.$store.state.isLoading = false
        this.info = res.data
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        }).then(() => {
          this.$router.go(-1)
        })
      })
    },

    onInput() {
      let value = this.amount
      // 是否有小数点
      const findDecimal = value.indexOf('.')
      const lastChat = value.charAt(value.length - 1)
      if (findDecimal !== -1 && (value.length - 1) != findDecimal && lastChat == '.') {
        this.amount = value.slice(0, -1)
        return
      }
      if (findDecimal !== -1 && value.length - findDecimal > 3) {
        this.amount = value.slice(0, -1)
        return
      }
      if (findDecimal !== -1 && findDecimal === 0) {
        value = '0' + value
      }
      this.amount = value
    },

    onBlur() {
      const value = this.amount
      const arr = value.split('.')
      let integer = arr[0]
      let decimal = arr.length > 1 ? arr[1] : '00' // 小数
      if (integer.length == 0) {
        integer = '0'
      }
      integer = parseInt(integer) + ''
      if (decimal.length < 2) {
        for (let i = decimal.length; i <= 2; i++) {
          decimal += '0'
        }
      }
      if (decimal.length > 2) {
        decimal = decimal.substr(0, 2)
      }
      let amount = integer + '.' + decimal
      if (amount == '0.00') {
        amount = ''
      }
      this.amount = amount
    },

    async onSubmit() {
      if (this.amount.length == 0) {
        return
      }
      if (this.pay_way == 'wxapp_abc_eaccount_trust_binding_pay' || this.pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') {
        this.$store.state.isLoading = true
        try {
          if (this.pay_way == 'wxapp_abc_eaccount_trust_binding_pay') {
            await trustBindingWithdraw({ amount: this.amount })
          } else if (this.pay_way == 'wxapp_abc_eaccount_bridge_binding_pay') {
            await bridgeBindingWithdraw({ amount: this.amount })
          }
          this.$router.replace({ name: 'AbcAccountInout', query: { amount: this.amount }})
        } catch (error) {
          this.$dialog.alert({
            message: error.msg
          })
        }
        this.$store.state.isLoading = false
      } else if (this.pay_way == 'wxapp_abc_eaccount_trust_recharge_pay' || this.pay_way == 'wxapp_abc_eaccount_bridge_recharge_pay') {
        this.$store.state.isLoading = true
        try {
          await transferOut({ amount: this.amount })
          this.$router.replace({ name: 'AbcAccountInout', query: { amount: this.amount }})
        } catch (error) {
          this.$dialog.alert({
            message: error.msg
          })
        }
        this.$store.state.isLoading = false
      }
    },

    async handleSubmit() {
      if (this.amount.length == 0) {
        return
      }
      const pay_id = this.$tool.getStorage('pay_id')
      if (!pay_id) {
        this.$dialog.alert({
          message: '无支付方式，请重新进入'
        }).then(() => {
          this.$router.replace({ name: 'User' })
        })
        return
      }
      this.$store.state.isLoading = true
      try {
        await cardOnlineWithdraw({ amount: this.amount, pay_id })
        this.$router.replace({ name: 'AbcAccountInout', query: { amount: this.amount }})
      } catch (error) {
        this.$dialog.alert({
          message: error.msg
        })
      }
      this.$store.state.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "./account_out.less";
</style>
