<template>
  <div>
    <div class="container">
      <div v-for="(item, index) in listData" :key="index" class="item" @click="onClick(index)">
        <div class="title">{{ item.name }}</div>
        <img class="arrow" src="../../assets/arrow.png">
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { getPlace } from '@/api/common'
import { Dialog } from 'vant'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      listData: []
    }
  },
  computed: {
    ...mapGetters(['city'])
  },
  created() {
    this.init()
  },
  methods: {
    // 初始化数据
    init() {
      const city = this.city
      console.log('市', city)
      if (!city) {
        Dialog.alert({
          message: '请先选择直辖市'
        }).then(() => {
          this.$router.push({ name: 'City' })
        })
        return
      }
      this.loadData()
    },
    // 加载数据
    loadData() {
      const code = this.$route.query.code
      const param = {}
      param.code = code
      this.$store.state.isLoading = true
      try {
        getPlace(param).then(res => {
          this.$store.state.isLoading = false
          this.listData = res.data
        }).catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
          })
        })
      } catch (res) {
        console.log(res)
      }
    },
    onClick(index) {
      const item = this.listData[index]
      store.dispatch('save/Area', item)
      this.$router.push({ name: 'Apply' })
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  height: 45px;
  border-bottom: solid 1px #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.item .title {
  font-size: 14px;
  color: #333;
}
.item .arrow {
  margin-left: 10px;
  width: 8px;
  height: 14px;
}
</style>
