<template>
  <div class="conteainer">
    <div v-if="info">
      <div v-for="(item,index) in info" :key="index" class="item">
        <div class="top">
          <div>{{ item.company.name }}</div>
          <div v-if="customer_user_id == item.id" class="selected">当前使用</div>
        </div>
        <div class="center">
          <img class="avatar" :src="item.headimg.small">
          <div class="message">
            <div class="name">{{ item.name }}</div>
            <div class="classname">{{ item.department.name }}</div>
            <div class="number">{{ item.number }}</div>
          </div>
        </div>
        <div class="bottom" @click="faceOn(item.id)">
          <div v-if="user_id != 93" class="btn-face">
            <van-uploader :after-read="afterRead">
              设置头像
            </van-uploader>
          </div>
          <div class="btn-select" :data-id="item.id" :style="customer_user_id == item.id ? 'border-color: #e3e3e3;color:#cacaca' : ''" @click="onSelect(item.id)">登入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setBind, getBindAll, editCustomer } from '@/api/user'
import { upload } from '@/api/common'

import { Dialog } from 'vant'

export default {
  data() {
    return {
      info: false,
      customer_user_id: 0,
      user_id: 0,
      else_id: 0
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color: #ffffff;height:100%')
  },
  mounted() {
    this.customer_user_id = this.$store.getters.customer_user_id
    this.loadData()
  },
  methods: {
    // 获取绑定用户列表
    loadData() {
      this.$store.state.isLoading = true
      try {
        getBindAll().then(res => {
          this.$store.state.isLoading = false
          this.info = res.data
        }).catch(res => {
          this.$store.state.isLoading = false
        })
      } catch (res) {
        this.$store.state.isLoading = false
        console.log(res)
      }
    },
    faceOn(id) {
      this.else_id = id
    },
    // 头像
    afterRead(file) {
      const param = {}
      this.$store.state.isLoading = true
      upload(file.file, 'file.png', 10).then(res => {
        this.$store.state.isLoading = false
        const file_name = res.data.file_name
        param.headimg = file_name
        param.customer_user_id = this.else_id
        editCustomer(param).then(res => {
          this.loadData()
        }).catch((res) => {
          Dialog.alert({ message: res.msg })
        })
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    },

    // 登入【切换顾客】
    onSelect(id) {
      // == console.log(event);
      var customer_user_id = id
      this.$store.dispatch('user/changecusid', customer_user_id)
      if (this.customer_user_id == customer_user_id) {
        return
      }
      const param = {}
      param.customer_user_id = id
      // 设置绑定顾客用户
      setBind(param).then(res => {
        this.$router.go(-1)
        // // 切换顾客清除订单缓存
        this.$store.state.saveDishItems = false
        this.$store.state.total = 0.00
        this.$store.state.sum = 0
        this.$store.state.amount = false
      }).catch((res) => {
        Dialog.alert({ message: res.msg })
      // 请求失败
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./accounts.less";
</style>
