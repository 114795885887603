<template>
  <div class="page">
    <!--账单页-->
    <div class="header">
      <!-- 类型标签栏选择 -->
      <div class="tab-bar">
        <div v-for="(item,index) in tabList" :key="index">
          <div class="item" :class="index == tabIndex ? 'active' : ''" @click="onTabsClick(index)">
            <div class="title">{{ item.title }}</div>
            <div class="line" />
          </div>
        </div>
      </div>
      <!-- 月份下拉框部分 start -->
      <van-cell @click="showPicker()">
        <div class="time">
          <div class="top">
            <span class="month">{{ now }}</span>
            <img class="arrow" src="../../assets/record_header_arrow.png">
          </div>
          <div class="msg">
            <div class="item">合计：{{ total_amount !== false ? total_amount : '' }}</div>
          </div>
        </div>
      </van-cell>
      <!-- 月份下拉框部分 end -->
    </div>
    <!-- 内容 -->
    <div class="content list">
      <BaseList ref="list" class="content" :get-list-request="getListRequest" @requestResponse="requestResponse">
        <template v-slot:default="listData">
          <div v-for="(item,index) in listData.listData" :key="index" class="item" @click="goDetail(item)">
            <div v-if="item.type_mark == 'recharge' " class="type">充值</div>
            <div v-else-if="item.type_mark == 'disburse' " class="type disburse">消费</div>
            <div v-else-if="item.type_mark == 'withdraw' " class="type withdraw">提现</div>
            <div v-else-if="item.type_mark == 'subsidy' " class="type give">补贴</div>
            <div class="content">
              <div class="info">
                <div class="info-tip">
                  <div class="title">{{ item.type_format }}</div>
                  <div v-if="item.account_type == 30" class="subsidy">补贴</div>
                </div>
                <div class="time ellipsis">{{ item.remark }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
              <div class="amount">
                <div class="integral" :class="item.rev_exp_type != -1 ? 'desc' : ''">{{ item.amount }}</div>
                <span v-if="item.type_mark != 'subsidy'" class="time">余额：{{ item.balance_yuan }}</span>
                <span v-else class="time">补贴余额：{{ item.balance_yuan }}</span>
              </div>
            </div>
            <img class="arrow" src="../../assets/arrow.png">
          </div>
        </template>
      </BaseList>
    </div>

    <!-- 选项 -->
    <van-popup :show="showTypePicker" position="bottom" @close="onTypePickerClose()">
      <van-picker show-toolbar :columns="typeList" :default-index="typeIndex" @cancel="onTypePickerCancel()" @confirm="onTypePickerConfirm" />
    </van-popup>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker v-model="date" type="year-month" :min-date="mindate" :max-date="maxdate" title="选择年月" :formatter="formatter" format="" @cancel="onDialogClose()" @confirm="onDialogSure" />
    </van-popup>
  </div>
</template>

<script>
import { getBill as getList } from '@/api/card'
import BaseList from '@/components/BaseList'

export default {
  components: { BaseList },
  data() {
    return {
      tabList: [
        { status: 'recharge', title: '充值' },
        { status: 'disburse', title: '消费' },
        { status: 'withdraw', title: '提现' },
        { status: 'subsidy', title: '补贴' }
      ],
      tabIndex: 0,
      loading: false,
      showTypePicker: false,
      // 所有下拉选项
      type: '',
      typeList: ['全部', '充值', '消费'],
      typeListArr: ['', 'recharge', 'purchase'],
      typeIndex: 0,
      // 打开时间选择
      show: false,
      // 日期显示年份
      year: 0,
      // 日期显示月份
      month: 0,
      date: '',
      now: '',
      mindate: false,
      maxdate: false,
      // 合计
      total_amount: '0.00',
      query_month: ''
    }
  },
  mounted() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    this.year = year
    this.month = month
    this.mindate = new Date(year - 3, 0)
    this.maxdate = new Date(year, month)
    this.date = now
    this.timeFormat(new Date())
    this.changeFormat(new Date())
  },
  methods: {
    // 时间显示
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    // 时间格式转化
    timeFormat(time) { // 时间格式化 2019-09-08
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      return year + '-' + this.formatNumber(month)
    },
    // 时间格式转化--页面显示
    changeFormat(time) { // 时间格式化 2019-09-08
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      return year + '年' + month + '月'
    },
    formatNumber(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    // 打开时间选择
    showPicker() {
      this.show = true
    },
    // 关闭时间选择
    onDialogClose() {
      this.show = false
    },
    // 确认时间选择
    onDialogSure(e) {
      const date = e
      this.date = date
      this.show = false
      this.$refs.list.onRefresh()
    },
    // 显示类型选项
    onShowTypePicker() {
      this.showTypePicker = true
    },
    // 点击遮罩层时触发
    onClose() {
      this.showTypePicker = false
    },
    onTypePickerConfirm(event) {
      var index = event
      this.showTypePicker = false
      this.typeIndex = index
      this.$refs.list.onRefresh()
    },
    // 点击下拉框取消按钮时触发
    onCancel() {
      this.showTypePicker = false
    },

    onTabsClick(e) {
      const index = e
      this.tabIndex = index
      this.loading = true
      this.type = this.tabList[index].status
      this.$refs.list.onRefresh()
    },
    getListRequest(param) {
      this.now = this.changeFormat(this.date)
      param.month = this.timeFormat(this.date)
      param.type = this.type ? this.type : 'recharge'
      param.query_month = param.month.split('-').join('')
      this.query_month = param.query_month
      return getList(param)
    },
    // 查看详情
    goDetail(item) {
      const id = item.id
      const query_month = this.query_month
      this.$router.push({ name: 'BillDetail', query: { id, query_month }})
    },
    requestResponse(listData, listParam) {
      this.total_amount = listParam.total_amount || ''
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
