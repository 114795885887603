class WebSocketClient {
  constructor(url, protocols = []) {
    this.url = url
    this.protocols = protocols
    this.socket = null
    this.callbacks = {}
    this.lockReconnect = false // 阻止断线重连
    this.timer = null // 断线重连定时器
    this.timerHeartBeat = null // 心跳
  }

  connect() {
    const socket = new WebSocket(this.url, this.protocols)

    socket.onopen = event => {
      if (this.callbacks['_open']) {
        this.callbacks['_open'].forEach(callback =>
          callback(event)
        )
      }
    }

    socket.onmessage = event => {
      console.log('onmessage', event)
      const message = JSON.parse(event.data)
      if (this.callbacks[message.method]) {
        this.callbacks[message.method].forEach(callback =>
          callback(message)
        )
      }
    }

    socket.onerror = event => {
      this.reconnect()
    }

    socket.onclose = event => {
      this.reconnect()
    }

    this.socket = socket
  }

  reconnect() {
    if (this.lockReconnect) return
    this.lockReconnect = true
    this.timer && clearTimeout(this.timer)
    this.timer = null
    this.timerHeartBeat && clearInterval(this.timerHeartBeat)
    this.timerHeartBeat = null
    this.timer = setTimeout(() => {
      const socket = this.socket
      socket && socket.close()
      this.socket = null
      this.connect()
      this.lockReconnect = false
    }, 5000)
  }

  // 请在登录成功后调起 心跳
  heartBeat(method = 'hello', data = {}) {
    this.timerHeartBeat && clearInterval(this.timerHeartBeat)
    this.timerHeartBeat = null
    if (!this.callbacks[method]) {
      this.on(method, res => {
        if (res.code != 0) {
          this.heartBeatErr(method, data)
        }
      })
    }
    this.timerHeartBeat = setInterval(() => {
      this.send(method, data)
    }, 50000)
  }
  heartBeatErr(method, data) {
    this.send(method, data)
  }

  // isOnly 为 false 时可为同一个 method 添加多个 callback
  on(method, callback, isOnly = true) {
    if (!this.callbacks[method]) {
      this.callbacks[method] = []
    }
    if (isOnly) {
      const index = this.callbacks[method].indexOf(callback)
      if (index == -1) {
        this.callbacks[method].push(callback)
      }
    } else {
      this.callbacks[method].push(callback)
    }
    console.log('callbacks', method, this.callbacks)
  }

  off(method, callback) {
    if (!this.callbacks[method]) {
      return
    }
    const index = this.callbacks[method].indexOf(callback)
    if (index !== -1) {
      this.callbacks[method].splice(index, 1)
    }
  }

  send(method, data) {
    const message = JSON.stringify({ method, data })
    this.socket.send(message)
  }

  // 请在离开页面时关闭 WebSocket
  close() {
    this.lockReconnect = true
    this.socket.close()
    this.socket = null
    this.timer && clearTimeout(this.timer)
    this.timer = null
    this.timerHeartBeat && clearInterval(this.timerHeartBeat)
    this.timerHeartBeat = null
    this.callbacks = {}
  }
}

export default WebSocketClient
