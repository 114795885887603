import { request } from '@/api/request'

// 饭卡--饭卡信息
export function getInfo() {
  return request({
    url: '/customer_h5/card/get_info',
    method: 'get'
  })
}

// 饭卡--关闭自动充值
export function closeRecharge(data) {
  return request({
    url: '/customer_h5/card/auto_recharge_close',
    method: 'post',
    data: data
  })
}

// 饭卡--开启自动充值
export function openRecharge(data) {
  return request({
    url: '/customer_h5/card/auto_recharge_open',
    method: 'post',
    data: data
  })
}

// 饭卡--饭卡设置
export function cardSet(data) {
  return request({
    url: '/customer_h5/card/setting',
    method: 'post',
    data: data
  })
}

// 饭卡--饭卡账单列表
export function getBill(param = {}) {
  return request({
    url: '/customer_h5/card/get_bill_list',
    method: 'get',
    params: param
  })
}

// 饭卡--饭卡账单详情
export function billInfo(param = {}) {
  return request({
    url: '/customer_h5/card/get_bill_info',
    method: 'get',
    params: param
  })
}

// 饭卡--饭卡挂失
export function cardLock(data) {
  return request({
    url: '/customer_h5/card/card_lock',
    method: 'post',
    data: data
  })
}

// 饭卡--饭卡补卡
export function cardApply(data) {
  return request({
    url: '/customer_h5/card/card_apply',
    method: 'post',
    data: data
  })
}
