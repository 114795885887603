<template>
  <div class="page">
    <div class="check-status">
      <div class="check-status-title">审批状态</div>
      <van-radio-group v-model="check_status" class="check-status-radio">
        <van-radio name="0" checked-color="#07c160">拒绝</van-radio>
        <div class="border" />
        <van-radio name="1" checked-color="#07c160">通过</van-radio>
      </van-radio-group>
    </div>
    <div class="border" />
    <div class="content">
      <div class="remark-title">审批备注</div>
      <van-field
        v-model="check_remark"
        class="textarea"
        rows="2"
        type="textarea"
        maxlength="150"
        :autosize="{ minHeight: 260 }"
        placeholder="请输入审批备注..."
        show-word-limit
      />
    </div>
    <div class="footer">
      <div class="btn" @click="submitForm()">提交审批</div>
    </div>
  </div>
</template>

<script>
import { orderCheck } from '@/api/meal'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      check_remark: '',
      check_status: ''
    }
  },
  methods: {
    submitForm() {
      const param = {}
      param.id = this.$route.query.id
      param.check_remark = this.check_remark
      param.check_status = this.check_status
      this.$store.state.isLoading = true
      orderCheck(param).then(res => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: '提交审批成功' }).then(() => {
          this.$router.replace({ name: 'RoundCheck' })
        })
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./checksend.less";
</style>
