<template>
  <div class="container">
    <div class="notice">*本行暂支持中行、工行、交行、农行储蓄卡</div>
    <div class="item">
      <div class="title">银行卡号</div>
      <div class="info">
        <input
          v-model="card_no"
          type="number"
          class="input"
          placeholder="请输入银行卡号"
          @change="onChangeCardno"
        >
        <div class="camera">
          <van-uploader :after-read="onCardCamera">
            <img src="./images/camera.png" class="icon">
          </van-uploader>
        </div>
      </div>
    </div>
    <div
      v-if="isShowTip"
      class="notice"
      style="padding-top: 5px;padding-bottom: 5px;background: rgba(247,247,247,1);"
    >
      <img
        src="./images/tip.png"
        style="width: 15px;height: 15px; margin-right: 2px;"
      >此时间段不支持非农行卡开户，请早上7:30后再试
    </div>
    <div class="line" />
    <div class="item">
      <div class="title">开户行省市</div>
      <div class="info" @click="onAreaClick">
        <input
          v-model="card_address"
          type="type"
          class="input"
          placeholder="请输入银行卡开户省份城市"
          readonly
        >
        <img class="arrow" src="./images/arrow.png">
      </div>
    </div>
    <div class="item">
      <div class="title">手机号码</div>
      <div class="info">
        <input
          v-model="mobile"
          type="number"
          class="input"
          placeholder="请输入银行预留手机号码"
          @change="onChangeMobile"
        >
      </div>
    </div>
    <div class="agreement">
      <img v-if="isAgree" class="agreement-icon" src="./images/success.png" @click="isAgree = !isAgree">
      <img v-else class="agreement-icon" src="./images/register_unselect.png" @click="isAgree = !isAgree">
      <div class="agreement-desc">我已阅读并同意《<span @click="showDialog">中国农业银行股份有限公司隐私政策（个人版）</span>》</div>
    </div>
    <div class="footer">
      <div class="btn" @click="onNext">下一步</div>
    </div>
  </div>
</template>

<script>
import { upload, bankCardOcr } from './api/api'
export default {
  name: 'AbcSignupCard',
  data() {
    return {
      card_no: '', // 绑定银行卡号
      card_address: '', // 银行卡开户地址，让用户选择省市即可，提交为省市名称
      mobile: '', // 手机号码
      isShowTip: false,
      isAgree: false
    }
  },
  created() {
    // 计算时间
    const startTime = new Date(new Date().toLocaleDateString()).getTime()
    const nowTime = new Date().getTime()
    const timestamp = (nowTime - startTime) / 1000
    const todayStart = (7 * 60 + 30) * 60
    const todayEnd = (19 * 60 + 30) * 60
    this.isShowTip = timestamp < todayStart || timestamp > todayEnd

    const getters = this.$store.getters
    const province = getters.abc_area_province
    const city = getters.abc_area_city
    const district = getters.abc_area_district
    const address = province && city ? province.name + city.name + (district && district.name || '') : ''
    this.card_no = getters.abc_signup_card_no
    this.mobile = getters.abc_signup_mobile
    this.card_address = address
    this.$store.dispatch('abc/setSignupCardAddress', address)
  },
  methods: {
    onChangeCardno() {
      this.$store.dispatch('abc/setSignupCardNo', this.card_no)
    },

    onCardCamera(file) {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
        message: '上传中…'
      })
      upload(file.file).then(res => {
        toast.message = `识别中…`
        bankCardOcr({ file_name: res.data.file_name }).then(res2 => {
          this.$toast.clear()
          this.$store.dispatch('abc/setSignupCardNo', res2.data.card_no)
          this.card_no = res2.data.card_no
        }).catch((res2) => {
          this.$toast.clear()
          this.$dialog.alert({ message: '识别失败：' + res2.msg })
        })
      }).catch((res) => {
        this.$toast.clear()
        this.$dialog.alert({ message: res.msg })
      })
    },

    onAreaClick() {
      this.$router.push({ name: 'AbcAreaProvince' })
    },

    onChangeMobile() {
      this.$store.dispatch('abc/setSignupMobile', this.mobile)
    },
    // 显示弹框
    showDialog() {
      // this.showModalStatus = true
      window.location.href = 'https://view.officeapps.live.com/op/view.aspx?src=' + 'https://centre-1318279478.cos.ap-guangzhou.myqcloud.com/article/%E4%B8%AD%E5%9B%BD%E5%86%9C%E4%B8%9A%E9%93%B6%E8%A1%8C%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%EF%BC%88%E4%B8%AA%E4%BA%BA%E7%89%88%EF%BC%8920220126.docx'
    },
    onNext() {
      if (!this.isAgree) {
        this.$dialog.alert({ message: '请阅读并同意《中国农业银行股份有限公司隐私政策（个人版）》' })
        return
      }
      if (this.card_no == '') {
        this.$dialog.alert({ message: '请输入银行卡号' })
        return
      } else if (this.card_address == '') {
        this.$dialog.alert({ message: '请选择开户行省市' })
        return
      } else if (this.mobile == '') {
        this.$dialog.alert({ message: '请输入手机号码' })
        return
      } else if (!/^1\d{10}$/.test(this.mobile)) {
        this.$dialog.alert({ message: '请输入正确的手机号码' })
        return
      }
      // this.$router.replace('/abcSignupIdcard')
      this.$router.push('/abcSignupIdcard')
    }
  }
}
</script>

<style lang="less" scoped>
@import "./signup_card.less";
</style>
