<template>
  <div v-if="info" class="container">
    <div class="main-content">
      <div class="title">反馈内容：</div>
      <div>
        <div class="main-body-content main-body-prefix">
          {{ info.title }}
        </div>
        <div class="main-body-content">
          {{ info.content }}
        </div>
      </div>
      <div v-if="info.images && info.images.length > 0" class="repair-pics-area">
        <div class="repair-pics">
          <van-image
            v-for="(item, index) in info.images"
            :key="index"
            width="69px"
            height="69px"
            fit="cover"
            :src="item.file_url && item.file_url.middle"
            class="pics"
            @click="previewImage(index, info.images)"
          />
        </div>
      </div>
      <div class="repair-note">
        <span>反馈时间</span>
        <span>{{ info.created_at_format }}</span>
      </div>
    </div>

    <div class="divider" />

    <div class="main-content">
      <div class="title">反馈处理：</div>
      <div>
        <div class="main-body-content main-body-prefix">
          {{ info.user && info.user.name }}您好：
        </div>
        <div class="main-body-content">
          {{ info.remark }}
        </div>
      </div>
      <div v-if="info.handle_images && info.handle_images.length > 0" class="repair-pics-area">
        <div class="repair-pics">
          <van-image
            v-for="(item, index) in info.handle_images"
            :key="index"
            width="69px"
            height="69px"
            fit="cover"
            :src="item.file_url && item.file_url.middle"
            class="pics"
            @click="previewImage(index, info.handle_images)"
          />
        </div>
      </div>
      <div class="repair-note">
        <span>处理时间</span>
        <span>{{ info.dispose_at_format }}</span>
      </div>
    </div>

    <div class="divider" />
  </div>
</template>

<script>
import { getInfo } from '@/api/feedback'

export default {
  name: 'FeedbackDetail',
  data() {
    return {
      id: 0,
      info: false
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const param = {}
      param.id = this.id
      this.$store.state.isLoading = true
      getInfo(param)
        .then(res => {
          this.$store.state.isLoading = false
          if (!res.data) return
          this.info = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    },

    previewImage(index, urls) {
      const imgList = urls.map(v => v.file_url && v.file_url.middle || '')
      this.$ImagePreview({
        images: imgList,
        startPosition: index
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./detail.less";
</style>
