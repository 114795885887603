<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <div class="title">请确认{{ user.name }}本人操作</div>
        <div class="msg">请保持正脸在取景框中根据屏幕指示完成识别</div>
        <img class="img" src="../../assets/face_icon.png">
        <div class="bottom">
          <div class="item" style="margin-left:0;">
            <img class="icon" src="../../assets/face_phone.png">
            <div class="tip">正对手机</div>
          </div>
          <div class="item">
            <img class="icon" src="../../assets/face_light.png">
            <div class="tip">光线充足</div>
          </div>
          <div class="item">
            <img class="icon" src="../../assets/face_smile.png">
            <div class="tip">脸无遮挡</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button type="button" class="btn" @click="onStart()">立即采集</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomer } from '@/api/user'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      user: false
    }
  },
  mounted() {
    this.loadCustomerData()
  },
  methods: {
    // 获取顾客用户信息
    loadCustomerData() {
      const param = {}
      param.customer_user_id = this.$route.query.customer_user_id
      this.$store.state.isLoading = true
      getCustomer(param).then(res => {
        this.$store.state.isLoading = false
        this.user = res.data
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    },
    onStart() {
      this.$router.push({ name: 'Camera', query: { customer_user_id: this.user.id }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
