<template>
  <div v-if="visible" class="mask">
    <div class="main">
      <!-- <div class="title">
				标题
			</div> -->
      <div class="content">
        <div>
          <div v-html="content" />
        </div>
        <div class="confirm" @click="onConfirm">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProtocolModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      default_content: ''
    }
  },
  methods: {
    onConfirm() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);

  .main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80%;
    overflow: auto;
    background: #fff;
    border-radius: 16px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
    }

    .content {
      max-height: calc(100vh - 200rpx);
      overflow-y: scroll;
      padding: 10px 20px;
      .confirm {
        margin: 15px auto 0;
        width: 60%;
        height: 43px;
        line-height: 43px;
        text-align: center;
        border-radius: 21px;
        background: #2dd8cb;
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: regular;
        font-size: 16px;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
