import service from '@/utils/request'

export function request({ ...params }) {
  return new Promise((resolve, reject) => {
    service({
      ...params
    }).then(res => {
      if (!res || !res.data) {
        return
      }
      const code = res.data.code
      if (code === 10100 || code === 10101 || code === 10102 || code === 10103 || code === 10104 || code === 10107) {
        return
      }
      if (res.data.code > 0) {
        reject(res.data)
      } else {
        resolve(res.data)
      }
    }).catch((res) => {
      if (!res || !res.data) {
        return
      }
      const code = res.data.code
      if (code === 10100 || code === 10101 || code === 10102 || code === 10103 || code === 10104 || code === 10107) {
        return
      }
      reject(res)
    })
  })
}

// 导出请求
export function requestExport({ ...params }) {
  return new Promise((resolve, reject) => {
    service({
      ...params
    }).then(res => {
      resolve(res)
    }).catch((res) => {
      this.$alert('下载文件失败：' + res.msg, '温馨提示')
      // reject(res)
    })
  })
}
