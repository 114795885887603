/**
 * 封装的一些 功能 函数
 */
// 解析Url上的Openid
import cookie from 'js-cookie'

// 解析URL中的Openid

export function getOpenid(openid) {
  var reg = new RegExp('(^|&)' + openid + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    cookie.set('openid', decodeURI(r[2]))
    return decodeURI(r[2])
  } else {
    return null
  }
}

