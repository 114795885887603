<template>
  <div class="container">
    <div class="notice">*本行暂支持中行、工行、交行、农行储蓄卡</div>
    <div class="item">
      <div class="title">银行卡号</div>
      <div class="info">
        <input
          v-model="card_no"
          type="number"
          class="input"
          placeholder="请输入银行卡号"
          @change="onChangeCardno"
        >
        <div class="camera">
          <van-uploader :after-read="onCardCamera">
            <img src="./images/camera.png" class="icon">
          </van-uploader>
        </div>
      </div>
    </div>
    <div class="line" />
    <div class="item">
      <div class="title">开户行省市</div>
      <div class="info" @click="onAreaClick">
        <input
          v-model="card_address"
          type="type"
          class="input"
          placeholder="请输入银行卡开户省份城市"
          readonly
        >
        <img class="arrow" src="./images/arrow.png">
      </div>
    </div>
    <div class="item">
      <div class="title">手机号码</div>
      <div class="info">
        <input
          v-model="mobile"
          type="number"
          class="input"
          placeholder="请输入银行预留手机号码"
          @change="onChangeMobile"
        >
      </div>
    </div>

    <div class="footer">
      <div class="btn" @click="onNext">下一步</div>
    </div>
  </div>
</template>

<script>
import { upload, bankCardOcr, changeCardApply } from './api/api'
export default {
  name: 'AbcCardChange',
  data() {
    return {
      card_no: '', // 绑定银行卡号
      card_address: '', // 银行卡开户地址，让用户选择省市即可，提交为省市名称
      mobile: '' // 手机号码
    }
  },
  created() {
    const getters = this.$store.getters
    const province = getters.abc_area_province
    const city = getters.abc_area_city
    const district = getters.abc_area_district
    const address = province && city ? province.name + city.name + (district && district.name || '') : ''
    this.card_no = getters.abc_signup_card_no
    this.mobile = getters.abc_signup_mobile
    this.card_address = address
    this.$store.dispatch('abc/setSignupCardAddress', address)
  },
  methods: {
    onChangeCardno() {
      this.$store.dispatch('abc/setSignupCardNo', this.card_no)
    },

    onCardCamera(file) {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
        message: '上传中…'
      })
      upload(file.file).then(res => {
        toast.message = `识别中…`
        bankCardOcr({ file_name: res.data.file_name }).then(res2 => {
          this.$toast.clear()
          this.$store.dispatch('abc/setSignupCardNo', res2.data.card_no)
          this.card_no = res2.data.card_no
        }).catch((res2) => {
          this.$toast.clear()
          this.$dialog.alert({ message: '识别失败：' + res2.msg })
        })
      }).catch((res) => {
        this.$toast.clear()
        this.$dialog.alert({ message: res.msg })
      })
    },

    onAreaClick() {
      this.$router.push({ name: 'AbcAreaProvince' })
    },

    onChangeMobile() {
      this.$store.dispatch('abc/setSignupMobile', this.mobile)
    },

    onNext() {
      if (this.card_no == '') {
        this.$dialog.alert({ message: '请输入银行卡号' })
        return
      } else if (this.card_address == '') {
        this.$dialog.alert({ message: '请选择开户行省市' })
        return
      } else if (this.mobile == '') {
        this.$dialog.alert({ message: '请输入手机号码' })
        return
      } else if (!/^1\d{10}$/.test(this.mobile)) {
        this.$dialog.alert({ message: '请输入正确的手机号码' })
        return
      }
      const param = {}
      param.mobile = this.mobile
      param.card_no = this.card_no
      param.card_address = this.card_address
      this.$store.state.isLoading = true
      changeCardApply(param).then((res) => {
        this.$store.state.isLoading = false
        this.$router.push({ name: 'AbcCardChangeConfirmCode', query: { token: res.data.token }})
      }).catch((error) => {
        this.$store.state.isLoading = false
        this.$dialog.alert({
          message: error.msg
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./card_change.less";
</style>
