<template>
  <div class="page">
    <div class="container">
      <BaseList ref="list" class="content" :get-list-request="getListRequest">
        <template v-slot:default="listData">
          <div
            v-for="(item, index) in listData.listData"
            :key="index"
            :class="['item', item.is_receive != 20 ? 'disabled' : '']"
            @click="handleSubsidyReceive(item)"
          >
            <div class="title">
              <div class="name">
                <img
                  v-if="item.is_receive == 20"
                  class="icon"
                  src="../../assets/subsidy_receive.png"
                >
                <img
                  v-else
                  class="icon"
                  src="../../assets/subsidy_disabled.png"
                >
                补贴领取通知
              </div>
              <span class="time">{{ item.card && item.card.created_at_format }}</span>
            </div>
            <div class="line" />
            <div class="detail">
              <span>您有一笔新的补贴即将到账，<span
                :class="item.is_receive == 20 ? 'active' : ''"
              >点击进行领取</span>
                ！</span>
            </div>
          </div>
        </template>
      </BaseList>
    </div>
  </div>
</template>

<script>
import BaseList from '@/components/BaseList'
import { getList, receive } from '@/api/subsidy/receive'

export default {
  components: { BaseList },
  data() {
    return {
      // 加载中
      loading: false
    }
  },
  methods: {
    getListRequest(param) {
      return getList(param)
    },

    handleSubsidyReceive(item) {
      if (item.is_receive != 20) return
      this.$dialog.confirm({
        title: '温馨提示',
        message: `您将领取补贴金额${item.amount_yuan}元，点击领取后将自动加入饭卡金额。`
      }).then(() => {
        this.$store.state.isLoading = true
        receive({ id: item.id }).then((res) => {
          this.$toast(res.msg)
          this.$store.state.isLoading = false
          this.$refs.list.onRefresh()
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg || error
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./receive.less";
</style>
