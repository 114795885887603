<template>
  <!-- 头部 -->
  <div class="conteainer">
    <div class="index_bg" />
    <!-- <div class="header">
      <div v-if="user" class="user" @click="onUser()">
        <img class="avatar" :src=" defaultImage ? defaultImage : user.headimg.small " @error="onError">
        <div class="info">
          <div class="name">{{ user.nickname }}</div>
          <div class="number">{{ user.mobile }}</div>
        </div>
      </div>
    </div> -->

    <!-- 食堂/顾客信息 -->
    <div v-if="customerData" class="section">
      <div class="title">
        <div class="name">{{ customerData.company.name }}</div>
        <div class="change" @click="changeRole()">
          切换
          <img class="arrow" src="../assets/arrow.png">
        </div>
      </div>

      <div class="section_card">
        <img class="user_card_bg" src="../assets/user_card_bg.jpg">
        <div class="user">
          <!-- <van-uploader :after-read="afterRead"> -->
          <img
            class="avatar"
            :src="defaultCustomerImage ? defaultCustomerImage : customerData.headimg.small"
            @click="onProfile"
          >
          <!-- </van-uploader> -->
          <div class="info">
            <div>
              <div class="name">{{ customerData.name }}</div>
              <div class="number">{{ customerData.department.name }}</div>
            </div>
            <div v-if="customerData.card == ''" class="logout">
              <div class="logout-cash">无饭卡</div>
            </div>
            <div v-if="customerData.card.balance" style="display: flex;">
              <div class="logout">
                <div v-if="customerData.card.balance_show_type == 1 || customerData.card.balance_show_type == 3">
                  ￥{{ customerData.card.online_offline_balance }}
                </div>
                <div v-if="customerData.card.balance_show_type == 1 || customerData.card.balance_show_type == 3" class="residue">
                  余额
                </div>

                <div v-if="customerData.card.balance_show_type == 2">
                  ￥{{ customerData.card.online_balance }}
                </div>
                <div v-if="customerData.card.balance_show_type == 2" class="residue">
                  线上
                </div>
                <div v-if="customerData.card.balance_show_type == 4">
                  ￥{{ customerData.card.online_balance }}
                </div>
                <div v-if="customerData.card.balance_show_type == 4" class="residue">
                  线上
                </div>

                <div v-if="customerData.card.balance_show_type == 5">
                  ￥{{ customerData.card.all_balance }}
                </div>
                <div v-if="customerData.card.balance_show_type == 5" class="residue">
                  余额
                </div>
              </div>
              <div v-if="customerData.card.balance_show_type == 2 || customerData.card.balance_show_type == 4" class="logout" style="margin-left: 12rpx;">
                <div>
                  ￥{{ customerData.card.offline_balance }}
                </div>
                <div class="residue">
                  线下
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="customerData.card == ''" class="desc" />
        <div v-else class="desc">
          <div class="lock-content">
            NO.{{ customerData.card.number }}
            <div v-if="customerData.card.status != 10" class="show-tips">
              <span v-if="customerData.card.status != 10" class="card-status">·已{{ customerData.card.status_format }}</span>
              <img v-if="customerData.card.status != 10" class="show-lock" src="../assets/showlock.png" @click="showTip()">
              <div v-if="showtips && customerData.card.status != 10" class="div1">实体饭卡已锁定，如需解锁请与客服联系 </div>
            </div>
          </div>
          <span v-if="customerData.card.balance_show_type == 1 || customerData.card.balance_show_type == 2" class="desc-back">补贴：￥{{ customerData.card.subsidy_balance }}</span>
        </div>
        <div v-if="customerData.card != ''" class="footer">
          <img class="code" src="../assets/qrcode_lim.png" @click="onQrcode()">
          <!-- <van-icon class="code" size="80rpx" name="scan" bind:click="onQrcode" /> -->
          <div class="card">
            <img v-if="customerData.card.status == 10" class="lock-icon" src="../assets/cardlock.png" @click="lockCard()">
            <div v-if="customerData.card.status == 10" class="card-lock" @click="lockCard()">挂失</div>
            <div v-if="modules.recharge" class="recharge" @click="toCharge()">充值</div>
          </div>
        </div>
        <div v-else class="footer" />
      </div>
    </div>

    <div v-if="user" class="message" @click="handleSubsidyReceive">
      <div class="message-icon">
        <img src="../assets/message.png">
        <span>消息</span>
      </div>
      <div class="message-line" />
      <div class="message-tip">
        <span v-if="(user.not_subsidy_count > 0)">你有{{ user.not_subsidy_count }}笔新的补贴即将到账！</span>
        <span v-else class="zero">暂无消息</span>
      </div>
      <div v-if="(user.not_subsidy_count > 0)" class="message-dot" />
    </div>

    <!-- 菜单选项1 -->
    <div class="order">
      <div v-if="modules.reserve" class="item" @click="()=> { this.$router.push({name:'Reserve'})}">
        <div class="icon_img">
          <img class="icon" src="../assets/user_meal.png">
        </div>
        <div :class="['title', {single: !showRound}]">订餐</div>
      </div>
      <div v-if="showRound" class="item" @click="()=> { this.$router.push({name:'Round'})}">
        <div class="icon_img">
          <img class="icon" src="../assets/user_meal.png">
        </div>
        <div :class="['title', {single: !modules.reserve}]">围餐</div>
      </div>
    </div>
    <div class="label">
      <!-- <div class="title">常用功能</div> -->
    </div>
    <div class="menu">
      <div v-if="modules.reserve" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'History'})}">
          <img class="icon" src="../assets/user_reserve.png">
        </div>
        <div class="title">预定订单</div>
      </div>
      <div v-if="modules.order" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'Scene'})}">
          <img class="icon" src="../assets/user_current.png">
        </div>
        <div class="title">点餐订单</div>
      </div>
      <div v-if="modules.amount" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'Cash'})}">
          <img class="icon" src="../assets/user_amount.png">
        </div>
        <div class="title">金额订单</div>
      </div>
      <div v-if="showRound" class="item" url="../meal/history">
        <div class="icon_img" @click="()=> { this.$router.push({name:'RoundList'})}">
          <img class="icon" src="../assets/user_reserve.png">
        </div>
        <div class="title">围餐订单</div>
      </div>
      <div v-if="modules.weigh" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({ name:'WeighOrder' })}">
          <img class="icon" src="../assets/index_weigh_order.png">
        </div>
        <div class="title">称重订单</div>
      </div>
      <div v-if="showRound && showCheck" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'RoundCheck'})}">
          <img class="icon" src="../assets/user_current.png">
        </div>
        <div class="title">围餐审批</div>
      </div>
      <div class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'Bill'})}">
          <img class="icon" src="../assets/user_record.png">
        </div>
        <div class="title">消费记录</div>
      </div>
      <div v-if="modules.recharge" class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'Auto'})}">
          <img class="icon" src="../assets/user_setting.png">
        </div>
        <div class="title">充值设置</div>
      </div>
      <div v-if="user && user.is_support_e_account" class="item" @click="loadPayWayData()">
        <div class="icon_img">
          <img class="icon" src="../assets/user_account.png">
        </div>
        <div class="title">电子账户</div>
      </div>
      <div class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'GuideList'})}">
          <img class="icon" src="../assets/user_guide.png">
        </div>
        <div class="title">使用指南</div>
      </div>
      <div class="item">
        <div class="icon_img" @click="()=> { this.goFeedback()}">
          <img class="icon" src="../assets/user_feedback.png">
        </div>
        <div class="title">问题反馈</div>
      </div>
      <div v-if="modules.smart_entrance_record" class="item" @click="onToSmartPark">
        <div class="icon_img">
          <img class="icon" src="../assets/smart_park.png">
        </div>
        <div class="title">出入记录</div>
      </div>
      <div v-if="modules.DishesWeigh" class="item" @click="onToDishesWarn">
        <div class="icon_img">
          <img class="icon" src="../assets/index_dish_notice.png">
        </div>
        <div class="title">菜量预警</div>
      </div>
      <div v-if="modules.DishManagement" class="item" @click="onToDishesManage">
        <div class="icon_img">
          <img class="icon" src="../assets/index_dishes.png">
        </div>
        <div class="title">菜品管理</div>
      </div>
      <div v-if="modules.SalesStatistics" class="item" @click="onToSaleStatistics">
        <div class="icon_img">
          <img class="icon" src="../assets/index_sale.png">
        </div>
        <div class="title">销售统计</div>
      </div>
      <!-- <div class="item">
        <div class="icon_img" @click="onGoExternal">
          <img class="icon" src="../assets/user_feedback.png">
        </div>
        <div class="title">外部跳转</div>
      </div> -->
      <!-- <div class="item">
        <div class="icon_img" @click="()=> { this.$router.push({name:'Apply'})}">
          <img class="icon" src="../assets/user_account.png">
        </div>
        <div class="title">饭卡挂失</div>
      </div> -->
    </div>

    <!-- 支付方式 -->
    <div v-if="showPayWay" @click="onHidePayWay()">
      <div class="modal">
        <div class="modal-content" @click.stop>
          <div class="label">支付方式</div>
          <div class="info">
            <div
              v-for="(item,index) in payWayList"
              :key="index"
              class="item"
              @click="onPayWaySelect(index)"
            >
              <div class="title">{{ item.pay_name }}</div>
              <img
                v-if="payWaySelectIndex == index"
                class="radio"
                src="../assets/payment_select_hl.png"
              >
              <img v-else class="radio" src="../assets/payment_select.png">
            </div>
          </div>
          <div>
            <div class="btn" @click="loadAccountData()">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <span>本服务由 广东顺德中电信息技术有限公司 提供</span>
      <span>客服电话“400-1088-323”</span>
    </div>
    <van-popup v-model="showTips" position="bottom" :style="{ height: '30%' }" class="tips" :close-on-click-overlay="false">
      <div class="tips-title">声明</div>
      <div class="tips-content">本服务由广东顺德中电信息技术有限公司提供，相关责任由该公司承担。我行将在法律法规和合同约定范围内合规开展经营活动，维护客户信息安全，保护消费者合法权益。</div>
      <div class="tips-btn" @click="closeTips">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
// import { getInfo, getCustomer, editCustomer } from '@/api/user'
import { getInfo, getCustomer, getOrder, editCustomer, getCompanyModule } from '@/api/user'
import { cardLock } from '@/api/card'
import { upload, getSettings, getH5Token } from '@/api/common'
import { getPayEWay, getAccount } from '@/api/pay'
import ErrorImg from '@/assets/default_headimg_middle.png'
import { Dialog, Toast } from 'vant'
import cookie from 'js-cookie'
// import { qing } from '../../vue.config'
import store from '@/store'

export default {
  name: 'Home',
  data() {
    return {
      window: window,
      user: false,
      info: false,
      isLoadingUser: true,
      defaultImage: false,
      defaultCustomerImage: '',
      // 是否在加载支付方式
      isLoadingPayWay: true,
      // 支付方式
      payWayList: false,
      payWaySelectIndex: 0,
      showPayWay: false,
      // 顾客用户数据
      customerData: false,
      customer_user_id: '',
      user_id: '',
      // 是否显示围餐
      showRound: false,
      showCheck: false,
      // 显示挂失信息
      showtips: false,
      modules: {},
      showTips: false,
      // 订餐配置
      evaluationSetting: false
    }
  },
  mounted() {
    const openid = cookie.get('openid')
    if (!openid || openid == null) {
      this.showTips = true
    } else {
      this.showTips = false
      this.login()
    }
  },
  methods: {
    login() {
      this.$store.dispatch('user/login').then(res => {
        if (!res || res.code != 0) return
        this.loadData()
        this.getRound()
        this.getModule()
        this.loadDishesSettings()
      }).catch(res => {
        this.$store.state.isLoading = false
        if (!res) return
        Dialog.alert({ message: res.msg || res }).then(() => {
          if (res.code == 10105) {
            this.$router.push('/phone')
          } else {
            this.login()
          }
        })
      })
    },
    // 订餐配置--获取是否开启评价功能
    async loadDishesSettings() {
      try {
        const res = await getSettings()
        this.evaluationSetting = res.data && res.data.order_dishes_evaluation == 1
        store.dispatch('save/saveEvaluation', this.evaluationSetting)
      } catch (error) {
        Dialog.alert({ message: error.msg || error })
      }
    },
    goFeedback() {
      this.$router.push({ name: 'FeedbackList' })
    },
    async getModule() {
      this.$store.state.isLoading = true
      try {
        const res = await getCompanyModule()
        if (!res.data || res.data.length == 0) return
        const modules = {}
        for (const item of res.data) {
          modules[item.name] = item.value == 1
        }
        this.modules = modules
      } catch (error) {
        this.$store.state.isLoading = false
      }
    },
    // 围餐控制
    getRound() {
      this.$store.state.isLoading = true
      getOrder()
        .then(res => {
          this.$store.state.isLoading = false
          this.$store.state.showRound = res.data.is_open == 1
          this.$store.state.showCheck = res.data.is_checker == 1
          this.showRound = this.$store.state.showRound
          this.showCheck = this.$store.state.showCheck
        })
        .catch(res => {
          this.$store.state.isLoading = false
        })
    },
    // 获取用户信息
    loadData() {
      this.$store.state.isLoading = true
      getInfo()
        .then(res => {
          this.$store.state.isLoading = false
          this.user = res.data
          this.loadCustomerData()
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },

    onError(e) {
      this.defaultImage = ErrorImg
      e.target.alt = '加载失败'
      e.target.onerror = null
    },

    onCustomer() {
      this.$router.push({
        name: 'Face',
        query: {
          customer_user_id: this.$store.getters.customer_user_id,
          user_id: this.user_id
        }
      })
    },

    // 展开挂失提示
    showTip() {
      if (this.customerData.card.status == 20 || this.customerData.card.status == 30) {
        this.showtips = !this.showtips
      } else {
        this.showtips = false
      }
    },
    // 提交挂失
    lockCard() {
      Dialog.confirm({ title: '挂失提示', message: '持失将锁定饭卡，锁定后实体饭卡将无法使用，如需解锁需与客服联系。确认挂失吗？' }).then(() => {
        this.$store.state.isLoading = true
        cardLock().then(res => {
          this.$store.state.isLoading = false
          this.loadCustomerData()
          Toast(res.msg)
        }).catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg })
        })
      }).catch(() => {
      })
    },
    // 获取顾客用户信息
    loadCustomerData() {
      const param = {}
      param.customer_user_id = this.$store.getters.customer_user_id
      this.$store.state.isLoading = true
      getCustomer(param)
        .then(res => {
          this.$store.state.isLoading = false
          const card = res.data.card
          if (card.number && (card.balance_show_type == 1 || card.balance_show_type == 3)) {
            card.online_offline_balance = (Number(card.online_balance) + Number(card.offline_balance)).toFixed(2)
          } else if (card.number && card.balance_show_type == 5) {
            card.all_balance = (Number(card.online_balance) + Number(card.offline_balance) + Number(card.subsidy_balance)).toFixed(2)
          }
          res.data.card = card
          this.customerData = res.data
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {})
        })
    },
    onProfile() {
      this.$router.push({ name: 'Profile', query: { name: this.customerData.name }})
    },
    // 头像
    afterRead(file) {
      const param = {}
      this.$store.state.isLoading = true
      upload(file.file).then(res => {
        this.$store.state.isLoading = false
        const file_name = res.data.file_name
        param.headimg = file_name
        param.customer_user_id = this.$store.getters.customer_user_id
        editCustomer(param).then(res => {
          this.loadCustomerData()
        }).catch((res) => {
          Dialog.alert({ message: res.msg })
        })
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg }).then(() => {
        })
      })
    },

    onQrcode() {
      this.$router.push({ name: 'Qrcode' })
    },

    onUser() {
      this.$router.push({ name: 'UserInfo' })
    },

    // 切换顾客
    changeRole() {
      this.$router.push({
        name: 'Accounts'
      })
    },
    // 充值
    toCharge() {
      if (window.getQueryVariable('authcode')) {
        this.$router.push({ name: 'GuideABC' })
        return
      }
      this.$router.push({ name: 'Topup' })
    },
    // 获取支付方式
    loadPayWayData() {
      this.$store.state.isLoading = true
      getPayEWay()
        .then(res => {
          if (!res || !res.data || res.data.length == 0) {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg })
          }
          this.payWayList = res.data
          this.showPayWay = res.data && res.data.length > 1
          if (!this.showPayWay) {
            this.loadAccountData()
          } else {
            this.$store.state.isLoading = false
          }
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg })
        })
    },

    // 获取电子账户
    loadAccountData() {
      this.$store.state.isLoading = true
      const payway = this.payWayList[this.payWaySelectIndex]
      const param = {}
      param.pay_id = payway.id
      getAccount(param)
        .then(res => {
          this.$store.state.isLoading = false
          this.showPayWay = false
          this.$tool.setStorage('pay_id', param.pay_id)
          // const url = 'https://centre.stillideals.com/pay_h5/account/index?appid=' + res.data.appid + '&merchant_id=' + res.data.merchant_id + '&setting_number=' + res.data.setting_number + '&prepay_id=' + res.data.prepay_id
          // window.location.href = url
          this.$router.push({ name: 'AbcAccount', query: {
            appid: res.data.appid,
            merchant_id: res.data.merchant_id,
            setting_number: res.data.setting_number,
            prepay_id: res.data.prepay_id
          }})
        })
        .catch(res => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg })
        })
    },

    onPayWaySelect(e) {
      const index = e
      this.payWaySelectIndex = index
    },

    onHidePayWay() {
      this.showPayWay = false
    },

    onShareAppMessage() {},

    // 关闭提示
    closeTips() {
      this.showTips = false
      this.login()
    },

    onGoExternal() {
      window.qing.call('localFunction', {
        'name': 'openExtBrowser',
        'param': {
          'url': 'https://go.abchina.com/k/LdD'
        },
        'success': function(result) {
          alert('结果：' + JSON.stringify(result))
        }
      })
    },

    async onToSmartPark() {
      try {
        const res = await getH5Token()
        const access_token = encodeURIComponent(res.data.access_token)
        const source_type = 10
        window.location.href = process.env.VUE_APP_SMART_PARK_URI + '?access_token=' + access_token + '&source_type=' + source_type
      } catch (error) {
        this.$toast(error.msg || error)
      }
    },

    handleSubsidyReceive() {
      this.$router.push({ name: 'SubsidyReceive' })
    },

    onToDishesWarn() {
      this.$router.push({ name: 'DishesWarn' })
    },

    onToDishesManage() {
      this.$router.push({ name: 'DishesManage' })
    },

    onToSaleStatistics() {
      this.$router.push({ name: 'SaleStatistics' })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "home.less";
</style>
