<template>
  <div class="page">
    <!--消费记录 / 账单页-->

    <!-- 账单金额 start -->
    <div v-if="info">
      <div class="bill">
        <div class="title">{{ info.type_format }}</div>
        <div class="amount">{{ info.rev_exp_type == 1 ?'+' :'' }}{{ info.amount }}</div>
        <div class="balance">{{ info.type_mark == 'subsidy'?'补贴':'' }}余额：{{ info.balance_yuan }}</div>
        <div v-if="info.account_type == 30" class="subsidy">补贴</div>
      </div>
      <!-- 账单金额 end -->

      <!-- 账单详细数据 start -->
      <div class="content">
        <div class="item">
          <span class="title">交易时间</span>
          <span class="msg">{{ info.time }}</span>
        </div>
        <div class="item">
          <span class="title">结算时间</span>
          <span class="msg">{{ info.settled_at_format }}</span>
        </div>
        <div class="item">
          <span class="title">交易单号</span>
          <span class="msg">{{ info.order_no }}</span>
        </div>
        <div class="item">
          <span class="title">摘要</span>
          <span class="msg">{{ info.remark }}</span>
        </div>
      </div>
    </div>
    <!-- 账单详细数据 end -->
  </div>
</template>

<script>
import { billInfo } from '@/api/card'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      info: false, // 内容
      isLoading: true, // 是否加载中
      id: 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const param = {}
      param.id = this.$route.query.id
      param.query_month = this.$route.query.query_month
      this.$store.state.isLoading = true
      try {
        billInfo(param).then(res => {
          this.$store.state.isLoading = false
          this.info = res.data
        }).catch((res) => {
          this.$store.state.isLoading = false
          Dialog.alert({ message: res.msg }).then(() => {
          })
        })
      } catch (res) {
        console.log(res)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./detail.less";
</style>
