<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="immediateCheck"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <slot :listData="listData" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  props: {
    // 获取列表请求
    getListRequest: {
      type: Function,
      default: (param) => {}
    },
    // 首次是否加载
    immediateCheck: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      listParam: {},
      listData: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageModel: {
        page: 1,
        limit: 10
      },
      count: ''
    }
  },
  methods: {
    async onLoad() {
      if (this.refreshing) {
        this.listData = []
        this.pageModel.page = 1
        this.refreshing = false
      }
      if (this.listData.length === this.count) {
        this.loading = false
        this.finished = true
        return
      }
      const param = {}
      const pageModel = this.pageModel
      for (const key in pageModel) {
        param[key] = pageModel[key]
      }
      try {
        const res = await this.getListRequest(param)
        const list = []
        this.listParam = res.data
        this.count = res.data.count
        // 有分页的情况
        if (res.data && res.data.rows) {
          if (res.data.rows == '' && res.data.page == 1) {
            // console.log('进来了0')
            this.loading = false
            this.finished = true
            this.listData = []
          }
          for (let i = 0, len = res.data.rows.length; i < len; i++) {
            const item = res.data.rows[i]
            list.push(item)
          }
          if (this.listData.length < res.data.count) {
            if (res.data.page == 1 && list.length < this.pageModel.limit) {
              // console.log('进来了1')
              this.listData = list
              this.finished = true
            } else {
              // console.log('进来了2')
              this.listData = this.listData.concat(list)
              this.pageModel.page = this.pageModel.page + 1
            }
            this.loading = false
          } else {
            this.finished = true
          }
        } else { // 没有分页的情况
          // console.log('进来了5')
          for (let i = 0, len = res.data.length; i < len; i++) {
            const item = res.data[i]
            list.push(item)
          }
          this.listData = list
          this.loading = false
          this.finished = true
        }
        // 把处理的listData,listParam数据传向父组件
        this.$emit('requestResponse', this.listData, this.listParam)
        this.$store.state.isLoading = false
      } catch (res) {
        this.$dialog.alert({
          message: res.msg
        })
      }
    },
    onRefresh() {
      // 清空列表数据
      this.listData = []
      this.pageModel.page = 1
      this.count = ''
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    }
  }
}
</script>

<style scoped lang="less">
</style>
