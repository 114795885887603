<template>
  <div v-if="info" class="page">
    <div v-if="info.cover_img" class="card-bg">
      <!-- <img class="img" src="../../assets/card_header_bg.png" alt=""> -->
      <img
        v-if="info.cover_img.file_url"
        class="img"
        :src="info.cover_img.file_url.middle"
        mode=""
      >
    </div>
    <div class="card">
      <div class="title">
        {{ info.name }}
        <div class="tag" v-html="info.is_on_sale_format_html" />
      </div>
      <div class="desc">
        {{ info.ad_words }}
      </div>
      <div class="price-box">
        <div class="card-price">
          <span class="symbol">￥</span>
          <span class="price">{{ info.price_yuan }}</span>
          <span class="unit"> /
            {{ info.weigh }}克
            {{ info.price_type == 20?'/份':'' }}
          </span>
        </div>
        <div class="stock" v-html="info.price_type_html_format" />
      </div>
      <div class="info">
        <div class="info-title">分类</div>
        <div class="info-msg">{{ info.category?info.category.name:'' }}</div>
      </div>
      <!-- <div class="info">
        <div class="info-title">所属单位</div>
        <div class="info-msg">{{ info.category?info.category.name:'' }}</div>
      </div> -->
      <div class="info">
        <div class="info-title">供应食堂</div>
        <div class="info-msg">{{ canteen_name }}</div>
      </div>
      <div class="info">
        <div class="info-title">供应餐段</div>
        <div class="info-msg">
          <div v-for="(item,i) in info.labels" :key="i" class="tab-style" :style="'color:'+item.color+';border-color:'+item.border_color">
            {{ item.name }}
          </div>

        </div>
      </div>
      <div class="info">
        <div class="info-title">菜量阈值</div>
        <div class="info-msg">{{ info.warn_threshold }}克</div>
      </div>
      <div class="border" />
      <div class="info">
        <div class="info-title">创建时间</div>
        <div class="info-msg">{{ info.created_at_format }}</div>
      </div>
      <div class="info">
        <div class="info-title">最近修改</div>
        <div class="info-msg">{{ info.updated_at_format }}</div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="cancel" @click="onDel()">删除</div>
      <div class="affirm" @click="onUpdata()">编辑</div>
    </div>
  </div>
</template>

<script>
import { getInfo, cancel } from '@/api/manage'
export default {
  data() {
    return {
      unwind: true,
      info: false,
      canteen_name: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const param = {}
      param.id = this.$route.query.id
      this.$store.state.isLoading = true
      getInfo(param)
        .then(res => {
          if (!res.data) return
          this.info = res.data
          this.canteen_name = (this.info.canteen.map((v) => v.name)).join('、')
          this.$store.state.isLoading = false
        })
        .catch(res => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg })
        })
    },
    onUpdata() {
      this.$router.push({ name: 'DishesEdit', query: { id: this.info.id }})
    },
    onDel() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确定删除【' + this.info.name + '】菜品？'
      }).then(() => {
        this.$store.state.isLoading = true
        cancel({ ids: [this.info.id] }).then((res) => {
          this.$store.state.isLoading = false
          this.$toast('删除成功')
          this.$router.go(-1)
        }).catch((error) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({
            message: error.msg
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./manage-detail.less";
</style>
