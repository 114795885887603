<template>
  <div class="page">
    <!--围餐订单-->
    <div class="header">
      <div class="item" :class="tabIndex == 1 ? 'active' : ''" @click="onTabClick(1)">
        <div class="title">待审批</div>
        <div class="line" />
      </div>
      <div class="item" :class="tabIndex == 2 ? 'active' : ''" @click="onTabClick(2)">
        <div class="title">已审批</div>
        <div class="line" />
      </div>
    </div>

    <div class="container">
      <BaseList ref="list" class="content" :get-list-request="getListRequest">
        <template v-slot:default="listData">
          <div v-for="(item,index) in listData .listData" :key="index" class="content">
            <div class="content-top" :style="{background : item.label.bg_color}">
              <div class="content-top-left" :style="{color:item.label.color}">{{ item.label.name }}</div>
              <div class="content-top-right">
                <div style="font-size: 12px;" :style="{color:item.label.color}">{{ item.date_format }}</div>
                <div style="margin-top:5px;font-size: 10px" :style="{color:item.label.color}">周{{ item.date_week_format }}</div>
              </div>
            </div>
            <div v-for="(sub_item,sub_index) in item.detail" :key="sub_index" class="content-mid">
              <div class="content-mid-line" />
              <div class="content-mid-top">
                <div class="content-mid-left">
                  <img class="content-mid-img" :src="sub_item.image_path">
                  <div class="dishes-detail">
                    <div class="dishes-detail-name">{{ sub_item.name }}</div>
                    <div class="dishes-detail-ad ellipsis">{{ sub_item.dishes.ad_words }}</div>
                    <!-- <div wx:if="{{sub_item.dishes.ad_words==''}}" class="dishes-detail-ad">大厨很忙没有介绍...</div> -->
                    <div class="dishes-detail-quantity">x{{ sub_item.quantity }}</div>
                  </div>
                </div>
                <div class="dishes-money">¥ {{ sub_item.subtotal_price_format }}</div>
              </div>
            </div>
            <div class="content-bottom">
              <div class="content-bottom-first">
                <div v-html="item.status_html_format" />
                <div class="content-bottom-first-total">¥ {{ item.total_price_format }}</div>
              </div>
              <div>{{ item.order_no }}</div>
              <div v-if=" item.user_remark" class="showremark" @click="Putaway(index)">
                <div :class="currentIndex == index && away ? 'open' :('close' ? 'clip' : '')">订单备注：{{ item.user_remark }}
                </div>
                <div v-if="GetBLen(item.user_remark) > 33" class="remark-detail">{{ currentIndex == index && away ? ' 收起' : '查看' }}</div>
              </div>
              <div v-if=" item.check_remark" class="showremark" @click="Checkaway(index)">
                <div :class="checkIndex == index && checkaway ? 'open' : ('close' ? 'clip' : '')">审批备注：{{ item.check_remark }}
                </div>
                <div v-if="GetBLen(item.check_remark) > 33" class="remark-detail">{{ checkIndex == index && checkaway ? '收起' : '查看' }}</div>
              </div>
              <div>{{ item.company.name }}</div>
              <div class="content-bottom-third">
                <div>{{ item.created_at_format }}</div>
                <button v-if="tabIndex == 1 && item.is_allow_cancel != 0" @click="showDialogBtn(item.id)">审批</button>
              </div>
            </div>
          </div>
        </template>
      </BaseList>
    </div>
  </div>
</template>

<script>
import { getRoundCheck as getList } from '@/api/meal'
import BaseList from '@/components/BaseList'

export default {
  components: { BaseList },
  data() {
    return {
      tabIndex: 1,
      typeList: [],
      typeIndex: 0,
      away: true,
      checkaway: true,
      currentIndex: -1,
      checkIndex: -1,
      status: 10
    }
  },

  methods: {
    onTabClick(event) {
      const index = event
      this.tabIndex = index
      if (this.tabIndex == 1) {
        this.status = 10
      } else {
        this.status = 20
      }
      this.$refs.list.onRefresh()
    },
    getListRequest(param) {
      param.status = this.status
      return getList(param)
    },
    onUnwind() {
      this.unwind = !this.unwind
    },
    // 订单备注
    Putaway(index) {
      if (index != this.currentIndex) {
        this.currentIndex = index
        this.away = true
      } else {
        this.currentIndex = -1
        this.away = false
      }
    },
    // 审批备注
    Checkaway(index) {
      if (index != this.checkIndex) {
        this.checkIndex = index
        this.checkaway = true
      } else {
        this.checkIndex = -1
        this.checkaway = false
      }
    },
    // 使用正则替换所有中文字符,然后再计算
    GetBLen: function(str) {
      if (str == null) return 0
      if (typeof str != 'string') {
        str += ''
      }
      return str.replace(/[^\x00-\xff]/g, 'ab').length
    },
    /**
   * 审批
   */
    showDialogBtn(id) {
      this.$router.push({ name: 'CheckSend', query: { id: id }})
    }
  }
}
</script>

<style lang="less" scoped>
@import "./roundlist.less";
</style>
