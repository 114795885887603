<template>
  <div class="container">
    <!-- 头部 -->
    <div v-if="false" class="header">
      <div v-if="isLoadingUser && !user" class="loading">
        <van-loading size="24px">加载中...</van-loading>
      </div>
      <div v-if="!isLoadingUser && !user" class="loading">
        加载失败，请重试<van-button plain hairline type="info" size="small" @click="loadAllData()">重试</van-button>
      </div>
      <div v-if="user" class="content">
        <img class="bg" src="../../assets/recharge_head_bg.png">
        <div v-if="user.bind_user_id > 0" class="identity">
          <div class="identify-content">
            <img class="identity-bg" src="../../assets/card_header_bg.png">
            <img class="identity-icon" src="../../assets/card_header_icon.png">
            <div class="identify-name">{{ user.bind_user_identity }}
            </div>
          </div>
        </div>
        <!--<div class="top">No:{{Teacher.no}}</div>-->
        <div class="center">
          <div class="user">
            <div class="avatar">
              <img class="icon" :src="user.headimg.small">
            </div>
            <div class="info">
              <div class="name">{{ user.name }}</div>
              <div class="tip">{{ user.class_name }}</div>
            </div>
            <div class="amount">
              <div class="tip">余额（元）</div>
              <div class="money">{{ user.balance }}</div>
            </div>
          </div>
        </div>
        <div class="bottom">ID：{{ user.number }}</div>
      </div>
    </div>
    <!-- 头部 end -->

    <!-- 设置项 -->
    <div class="menu">
      <div class="item">
        <div class="title">自动充值</div>
        <div class="right">
          <van-switch v-model="is_auto" color="#ffce0f" @change="onAutoButton" />
        </div>
      </div>
      <div class="item" @click="onMinItemClick()">
        <div class="title">饭卡最低金额</div>
        <div class="right">
          <span class="msg">{{ minValue }}</span>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
      </div>
      <div class="item" @click="onAutoItemClick()">
        <div class="title">自动充值金额</div>
        <div class="right">
          <span class="msg">{{ autoValue }}</span>
          <img class="arrow" src="../../assets/arrow.png">
        </div>
      </div>
    </div>
    <div class="label">
      温馨提示:开启自动充值功能后，当饭卡余额低于所设定金额时，系统将自动进行充值
    </div>
    <!-- <div class="footer" wx:if="{{info.is_bind_model == 1 && info.bind_user_id > 0 && info.bind_user_id == login_user_id }}">
		<div class="btn" bindtap="onCheckout">转出</div>
	</div> -->
    <van-popup v-model="showPayItemPicker" position="bottom" custom-style="height: 400rpx;" @close="onPayClose">
      <van-picker show-toolbar title="选择支付方式" :columns="payItems" @cancel="onPayClose" @confirm="onPayItemConfirm" />
    </van-popup>
    <van-popup v-model="showMinItemPicker" position="bottom" custom-style="height: 400rpx;" @close="onClose">
      <van-picker show-toolbar title="饭卡最低金额" :columns="minItems" @cancel="onClose" @confirm="onMinItemConfirm" />
    </van-popup>
    <van-popup v-model="showAutoItemPicker" position="bottom" custom-style="height: 400rpx;" @close="onClose">
      <van-picker show-toolbar title="自动充值金额" :columns="autoItems" @cancel="onClose" @confirm="onAutoItemConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { closeRecharge, openRecharge, cardSet, getInfo } from '@/api/card'
import { getPayWay } from '@/api/pay'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      user: false,
      isLoadingUser: true,
      showMinItemPicker: false,
      showAutoItemPicker: false,
      showPayItemPicker: false,
      minItems: ['10.00', '20.00', '30.00', '50.00', '100.00'],
      autoItems: ['50.00', '100.00', '200.00', '300.00', '400.00', '500.00'],
      payItems: [],
      payList: [],
      minValue: '',
      autoValue: '',
      is_auto: '',
      old_data: false
    }
  },
  mounted() {
    this.loadData()
    this.loadPayData()
  },
  methods: {
  // 自动充值开关控制
    onAutoButton: function(event) {
      this.old_data = {
        is_auto: this.is_auto,
        minValue: this.minValue,
        autoValue: this.autoValue
      }
      if (event == false) {
        Dialog.alert({ message: '确定取消自动充值吗？' }).then(() => {
          this.$store.state.isLoading = true
          closeRecharge().then((res) => {
            this.$store.state.isLoading = false
            this.is_auto = false
          }).catch((res) => {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg })
            this.is_auto = true
          })
        })
      } else {
        if (!this.payItems || this.payItems.length == 0) {
          const param = {}
          param.pay_scene = 'weixin_mini_campus_card_recharge'
          this.$store.state.isLoading = true
          getPayWay(param).then((res) => {
            this.$store.state.isLoading = false
            const payItems = []
            res.data.forEach((item) => {
              payItems.push(item.pay_name)
            })
            this.payItems = payItems
            this.payList = res.data
            this.showPayItemPicker = true
          }).catch((res) => {
            this.$store.state.isLoading = false
            Dialog.alert({ message: res.msg })
            this.is_auto = false
          })
        } else {
          this.showPayItemPicker = true
        }
      // this.onSubmit({ is_auto: 1 });
      }
    },
    // 最低金额
    onMinItemClick(event) {
      this.showMinItemPicker = true
    },
    // 最低金额选择
    onMinItemConfirm(event) {
      this.old_data = {
        is_auto: this.is_auto,
        minValue: this.minValue,
        autoValue: this.autoValue
      }
      console.log('111111', this.old_data)
      this.showMinItemPicker = false
      this.onSubmit({ min_amount: event })
    },
    // 自动充值
    onAutoItemClick(event) {
      this.showAutoItemPicker = true
    },
    // 自动充值选择
    onAutoItemConfirm(event) {
      console.log(event)
      this.old_data = {
        is_auto: this.is_auto,
        minValue: this.minValue,
        autoValue: this.autoValue
      }
      this.showAutoItemPicker = false
      this.onSubmit({ recharge_amount: event })
    },
    onPayItemConfirm(event, e) {
      const index = e
      this.showPayItemPicker = false
      const item = this.payList[index]
      const param = {}
      param.pay_id = item.id
      this.$store.state.isLoading = true
      openRecharge(param).then((res) => {
        this.$store.state.isLoading = false
        this.is_auto = true
      }).catch((res) => {
        this.$store.state.isLoading = false
        Dialog.alert({ message: res.msg })
        this.is_auto = false
      })
    },
    // 关闭
    onClose() {
      this.showMinItemPicker = false
      this.showAutoItemPicker = false
      this.showPayItemPicker = false
    },
    onPayClose() {
      this.showPayItemPicker = false
      this.is_auto = false
    },
    onSubmit(param) {
    // if (!param.min_amount) {
    //   param.min_amount = this.data.minValue;
    // }
    // if (!param.recharge_amount) {
    //   param.recharge_amount = this.data.autoValue;
    // }
      this.$store.state.isLoading = true
      cardSet(param).then((res) => {
        this.$store.state.isLoading = false
        this.minValue = param.min_amount
        console.log('最低金额', this.minValue)
        this.autoValue = param.recharge_amount
        this.loadData()
      }).catch((res) => {
        this.$store.state.isLoading = false
        if (this.old_data) {
          this.minValue = this.old_data.minValue
          this.autoValue = this.old_data.autoValue
        }
        Dialog.alert({ message: res.msg })
        this.loadData()
      })
    },
    // 加载用户数据
    loadData() {
      this.isLoadingUser = true
      getInfo().then((res) => {
      // 请求成功
        this.isLoadingUser = false
        this.user = res.data
        if (res.data.is_auto == '1') {
          this.is_auto = true
        } else {
          this.is_auto = false
        }
        this.minValue = res.data.min_recharge
        this.autoValue = res.data.recharge_amount
      }).catch((res) => {
      // 请求失败
        this.isLoadingUser = false
        Dialog.alert({ message: res.msg }).then(() => {
          this.$router.go(-1)
        })
      })
    },
    loadPayData() {
      const param = {}
      param.pay_scene = 'weixin_mini_campus_card_recharge'
      this.$store.state.isLoading = true
      getPayWay(param).then((res) => {
        this.$store.state.isLoading = false
        const payItems = []
        res.data.forEach((item) => {
          payItems.push(item.pay_name)
        })
        this.payItems = payItems
        this.payList = res.data
      }).catch((res) => {
        Dialog.alert({ message: res.msg }).then(() => {
          this.$router.go(-1)
        })
      })
    },
    // 转出
    onCheckout() {

    }
  }
}
</script>

<style lang="less" scoped>
@import "./auto.less";
</style>
